'use strict'

import api from '@/api/api';
import userService from '../../services/user.service'

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS', 
      GETTING_INFO2      = 'GET_INFO2',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2', 
      GETTING_INFO3      = 'GET_INFO3',
      GET_INFO_SUCCESS3  = 'GET_INFO_SUCCESS3', 
      GETTING_INFO4      = 'GET_INFO4',
      GET_INFO_SUCCESS4  = 'GET_INFO_SUCCESS4', 
      GETTING_INFO5      = 'GET_INFO5',
      GET_INFO_SUCCESS5  = 'GET_INFO_SUCCESS5', 
      GETTING_INFO6      = 'GET_INFO6',
      GET_INFO_SUCCESS6  = 'GET_INFO_SUCCESS6';

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        loading2: false,
        data2: [],
        loading3: false,
        data3: [],
        loading4: false,
        data4: [],
        loading5: false,
        data5: [],
        loading6: false,
        data6: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        loading2(state) {
            return state.loading2;
        },
        data2(state) {
            return state.data2;
        },
        loading3(state) {
            return state.loading3;
        },
        data3(state) {
            return state.data3;
        },
        loading4(state) {
            return state.loading4;
        },
        data4(state) {
            return state.data4;
        },
        loading5(state) {
            return state.loading5;
        },
        data5(state) {
            return state.data5;
        },
        loading6(state) {
            return state.loading6;
        },
        data6(state) {
            return state.data6;
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [GETTING_INFO2](state) {
            state.loading2 = true;
            state.data2 = [];
        },
        [GET_INFO_SUCCESS2](state, change) {
            state.loading2 = false;
            state.data2 = change;
        },
        [GETTING_INFO3](state) {
            state.loading3 = true;
            state.data3 = [];
        },
        [GET_INFO_SUCCESS3](state, change) {
            state.loading3 = false;
            state.data3 = change;
        },
        [GETTING_INFO4](state) {
            state.loading4 = true;
            state.data4 = [];
        },
        [GET_INFO_SUCCESS4](state, change) {
            state.loading4 = false;
            state.data4 = change;
        },
        [GETTING_INFO5](state) {
            state.loading5 = true;
            state.data5 = [];
        },
        [GET_INFO_SUCCESS5](state, change) {
            state.loading5 = false;
            state.data5 = change;
        },
        [GETTING_INFO6](state) {
            state.loading6 = true;
            state.data6 = [];
        },
        [GET_INFO_SUCCESS6](state, change) {
            state.loading6 = false;
            state.data6 = change;
        },
    },
    actions:{
        async findAll({ commit }, payload) {
            commit(GETTING_INFO);
            try {               
                //let normalizedOpt = payload.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, ""); 
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findAll(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findById({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByNamePre({ commit }, payload) {
            commit(GETTING_INFO6);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;
                let response = await api.findByName(payload, headers);
                commit(GET_INFO_SUCCESS6, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByType({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByType(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },
        async findByTeam({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByTeam(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },
        async findByTeam2({ commit }, payload) {
            commit(GETTING_INFO2);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByTeam(payload, headers);
                commit(GET_INFO_SUCCESS2, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },
        async findByTeam3({ commit }, payload) {
            commit(GETTING_INFO3);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByTeam(payload, headers);
                commit(GET_INFO_SUCCESS3, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },
        async getImageFile({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getImageFile(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
   
    },
}