import axios from "axios";

 const rootApi = 'https://clubes.apilmx.com/v1'
export default {
 
  login(user, headers){
    return axios.post('/login', user, headers);
  },

  enviarMailContacto(data, headers){
    return axios.post('/j43-wdrDE4s_wffs2/contacto', data, headers);
  },

  enviarMailPrensa(data, headers){
    return axios.post('/j43-wdrDE4s_wffs2/prensa', data, headers);
  },


  search(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },
  searchPedidos(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },
  getAllInfo(option, headers){
    return axios.get(`/${option}`, {headers});
  },
  getByEmail(data, headers){
  
    //console.log(data)
    return axios.get(`/${data.option}/${data.email}` ,{headers});
  },
  getAllInfo2(option, headers){
  
    return axios.get(`/${option}`, {headers});
  },
  getInfoById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },

  getInfoByFila(payload, headers){
    return axios.get(`/sponsor_footer/fila/${payload.name}`, {headers});
  },
  getInfoByUser(payload, headers){
    return axios.get(`/${payload.option}/${payload.id_usuer}`, {headers});
  },
  getImage(payload, headers){
    return axios.get(`/get-${payload.option}-image/${payload.image}`, {headers});
  },




 

  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data: payload,
        headers: {
          Authorization: token 
        } 
      });
  },
  
  deleteCupon(payload){
    return axios.delete(`/l3p-gcFel9c7_4d3fj/del_cupon`, {
        data: {
          cupon:payload.cupon,
          _tkncarrito:payload._tkncarrito
        }
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update`, payload.item, {headers} );
  },
  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/user/upload/image/${payload.id}`, payload.data, {headers});
  },

  uploadImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/upload/image/`, payload.data, {headers});
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/create`, payload.item, {headers});
  },


  createPurchase(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/create_purchase`, payload , {headers});
  },

  createPurchase2(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/create_purchase_op`, payload , {headers});
  },
  
  resendCode(user, headers){
    return axios.post('/send/code', user, headers);
  },

  addSuscriptor(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post('/subs/user/suscribe', payload, {headers});
  },


 //Metodos carrito de compras
 
 setCart(){
  return axios.post('/jl3-wds224sa_Ae4s2/set_cart');
},

getCart(payload){

  return axios.post('/e7r-fwekF32D_fg43d/get_cart', payload);
},

AddItemToCart(payload){
  return axios.put('/qw8-fe3g92k_xMdw4/add_cart', payload);
},

deleteItemCar(payload){
  return axios.delete('/3fe-gHsl2c7_vd5fz/del_item', {data:payload});
},


updateCart(payload){
  return axios.put('/23o-gdw62fs_pS1k2sf/upt_item', payload);
},

validateCart(payload){
  return axios.post('/245-wer42p6f_sdj3Dg3/valid_cart', payload);
},


addCupon(payload){
  return axios.put('/gh2-sT7t9xk_v1dr4/add_cupon', payload);
},
//FIN METODOS ERIK


//metodos CHITO


  // se pueden simplificar.
  getCalendar(payload, headers){
    return axios.get(`/${payload.option}`, {headers});
  },
  getCalendarById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  getCalendarSearch(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  getCalendarImage(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },

  
  findAll(payload, headers){
    return axios.get(`/${payload.option}`, {headers});
  },
  findById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByLiga(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByName(payload, headers){
    return axios.get(`/${payload.option}/${payload.section}`, {headers});
  },
  findByName(payload, headers){
    return axios.get(`/${payload.option}/${payload.name}`, {headers});
  },
  findByTitle(payload, headers){

    return axios.get(`/${payload.option}/${payload.title}`, {headers});
  },
  findByType(payload, headers){

    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByTeam(payload, headers){

    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByCategory(payload, headers){

    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  /* CAMBIAR NOMBRE YA EXISTE EL METODO SEARCH
  search(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  */
  getImageFile(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByGallery(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
  findByCategoryName(payload, headers){
    return axios.get(`/${payload.option}/${payload.name}`, {headers});
  },




  
 

// FIN METODOS CHITO


//API 

/**
 * idTorneo = 1 -> Apertura
 * idTorneo = 2 -> Clausura
 * Division = 2 -> Liga expansion 
 * idTemporada = 72 -> Temporada Actual 2021-2022 
 * 
 */

  getTablaGeneral(){
     return axios.get('https://clubes.apilmx.com/v1/tablaGeneral?idDivision=2&idTemporada=72&idTorneo=1')
  } ,

  getTablaGeneralDimanica(payload){
    return axios.get(`${rootApi}/tablaGeneral?idDivision=${payload.idDivision}&idTemporada=${payload.idTemporada}&idTorneo=${payload.idTorneo}`)
  },


  //Tabla goleo individual
  /*

      return axios.get(`${rootApi}/tablaGeneral?idDivision=2&idTemporada=${payload.idTemporada}&idTorneo=${payload.idTorneo}`)

    Parametros 
    idTemporada
    idTorneo
    idDivision
    Top

  */
    getTablaGoleo(payload){
      return axios.get(`${rootApi}/tablaGoleo?idTorneo=${payload.idTorneo}&idTemporada=${payload.idTemporada}&top=${payload.top}&idDivision=${payload.idDivision}`)
    },


    /*
    idTorneo
    idDivision
   
    */
    getJornadas(payload){
      return axios.get(`${rootApi}/${payload.idTorneo}/${payload.idDivision}/jornadas`)
    },


 /*
    idTorneo
    idDivision
     idJornada
    */
    getPartidos(payload){
      return axios.get(`${rootApi}/${payload.idTorneo}/${payload.idDivision}/${payload.idJornada}/partidos`)
    },



/*Partidos por club
    ● idTorneo
    ● idDivision
    ● idJornada
    ● idClub = 10721 - ID del Club Cimarrones de Sonora
*/
    getPartidosClub(payload){
      return axios.get(`${rootApi}/club/partidosClub?idTemporada=${payload.idTemporada}&idTorneo=${payload.idTorneo}&idDivision=${payload.idDivision}&idClub=${payload.idClub}`)
    },


    /*informe Arbitral 
    Listado de Alineaciones en un partido de jugadores titulares, suplentes y cuerpo técnico.
Posterior al partido, los cambios, tarjetas, goles.

    ● idPartido

    */
    getAlineaciones(payload){
      console.log(payload)
      return axios.get(`${rootApi}/alineaciones/${payload.idPartido}`)
    },

/*
    idTorneo
    idDivision
   
    */
    getPartidosSJ(payload){
      return axios.get(`${rootApi}/${payload.idTorneo}/${payload.idDivision}/partidos`)
    },


};