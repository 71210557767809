<template>
  <div id="app" class="color-bg-4">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Cimarrones FC",
      titleTemplate: "%s | Cimarrones FC",
      meta: [
        { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content: "Página web Cimarrones FC",
        },
      ],
    };
  },
};
</script>

<style>
/** Estilos generales */

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap");

:root {
  --color-1: #01203a;
  --color-2: #ce1526;
  --color-3: #909090;
  --color-4: #ffffff;
  --color-5: #585858;
  --color-6: #303030;
  --color-7: #968220;
  --color-8: #032a4b;
  --color-9: #ff0000;
  --color-10: #000000;
  --color-11: #19913d;

  --Roboto: "Roboto", sans-serif;
  --Lato: "Lato", sans-serif;
}

@font-face {
  font-family: "EA Font";
  src: url("./assets/EASPORTS15.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Rajdhani";
  src: url("./assets/font/Rajdhani-Variable.ttf");
  font-display: swap;
}
html {
  scroll-behavior: smooth;
}

/**  */

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

.color-3 {
  color: var(--color-3);
}

.color-4 {
  color: var(--color-4);
}

.color-5 {
  color: var(--color-5);
}
.color-6 {
  color: var(--color-6);
}

.color-7 {
  color: var(--color-7);
}

.color-8 {
  color: var(--color-8);
}

.color-bg-1 {
  background-color: var(--color-1);
}

.color-bg-2 {
  background-color: var(--color-2);
}

.color-bg-3 {
  background-color: var(--color-3);
}

.color-bg-4 {
  background-color: var(--color-4);
}

.color-bg-5 {
  background-color: var(--color-5);
}

.color-bg-6 {
  background-color: var(--color-6);
}

.color-bg-7 {
  background-color: var(--color-7);
}

.color-bg-8 {
  background-color: var(--color-8);
}

.color-bg-10 {
  background-color: var(--color-10);
}
/** fin colores */

.data {
  color: var(--color-5);
}

body {
  margin: 0px !important;
  height: 100vh;
}
/* arreglar esto afecta mucho
a:hover {
    border-color: transparent!important;
    text-decoration: none;
    color: transparent!important;
}*/
a,
button,
li {
  outline: none;
}
svg {
  color: var(--color-3);
}
#app {
  font-family: var(--Roboto);
  font-display: optional;
  height: 100%;
}

.imgx{
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
}
.tacnt{
  text-align: center;
}
#cnt-mdl-checkout{
     background: var(--color-4);
    height: 40vw;
    padding: 1vw;
    width: 23%;
    margin-left: 36%;
    margin-top: 8%;
        overflow: auto;
        padding-bottom: 3vw;
}

.modal_view_checkout  {
  z-index: 900;
  background-color: rgb(164 164 164 / 32%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  
}



img.close_modal{
  margin-left: 95%;
  width: 1vw;
  height: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
}

.dflx{
  display: flex;
}
/**HOME */
.tab__slider {
  display: none;
}
#headerHome {
  height: 11.475vw;
  padding-left: 0vw;
  padding-right: 0vw;
  display: block;
}

.sec1-hh {
  height: 2.552vw;
  display: flex;
  justify-content: space-between;
}

.sec1-hh span {
  margin-top: 0;
  margin-left: 4.063vw;
  width: 0.114583vw;
  height: 1.6145833333333335vw;
  background: var(--color-4);
}
h3.no-matches {
  display: flex;
  align-items: center;
}

.sec1-hh h3 {
  font-family: "Rajdhani";
  padding-left: 5vw;
  color: var(--color-4);
  font-size: 0.6770833333333334vw;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  align-items: center;
}
/*
.mt1 {
  padding-top: 0.885vw;
}
.mt2 {
  padding-top: 0.32vw;
}*/

.sec2-hh {
  display: flex;
  margin: 0 7.677083vw;
  border-bottom: 1px solid #2e475c;
  justify-content: space-between;
}

.iconpartido {
  width: auto;
  height: 1.9791666666666665vw;
  margin-right: 0.8854166666666666vw;
}

.iconarrow {
  margin-left: 4.063vw;
  margin-top: -0.1vw;
  width: 3.854vw;
}

.comprar-boletos {
  font-family: "Rajdhani";
  width: 12.916666666666668vw;
  background-color: var(--color-7);
  color: var(--color-4);
  height: 1.875vw;
  font-size: 0.625vw;
  padding: 0.5vw 1.5vw;
  margin-top: 0;
  /* margin-left: 23.385vw;*/
  transition: 0.5s;
  text-align: center;
}

.comprar-boletos:hover {
  color: var(--color-7) !important;
  background-color: var(--color-4);
  text-decoration: none !important;
}

.patrocinador1 {
  width: 4.791666666666667vw;
  height: auto;
  margin-left: 6.823vw;
  margin-right: 7.969vw;
  margin-top: 0;
}

/** header home */
.logo-hh {
  width: 4.073vw;
  height: 4.185vw;
  margin-top: 0.8854166666666666vw;
  margin-bottom: 0.8854166666666666vw;
  margin-right: 0;
}

.sec5-hh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.4vw 7.396vw;
}

.user-hh button {
  cursor: pointer;
  font-size: 0.7291666666666666vw;
  font-weight: normal;
  font-family: var(--Roboto);
  background: none !important;
  color: var(--color-4);
  border: none;
  padding-top: 0vw !important;
}

.user-hh button:hover,
.user-hh button:active,
.user-hh button:target,
.user-hh button:focus {
  color: var(--color-4) !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.icons-user {
  margin-top: 0.5vw;
  margin-left: 5vw !important;
}

.btnslog {
  /* margin-top: 0.5vw;*/
  margin-left: 0.2vw !important;
  width: 24.406vw;
  justify-content: space-between;
  flex-wrap: nowrap !important;
  display: flex;
  margin-right: 1.5vw;
}
.btnslog .sm-cont {
  width: 16.479vw;
  display: flex;
  justify-content: space-between;
}
.btnslog .ushop-cont {
  width: 3.97vw;
  display: flex;
  justify-content: space-between;
}
.btnslog a {
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.25s;
  display: flex;
  width: 1.8vw;
  height: 1.8vw;
  color: var(--color-4);
  /* font-size: 0.6770833333333334vw; */
  border-bottom: 0.1vw solid transparent;
  justify-content: center;
  align-items: center;
}
.btnslog a:hover {
  color: var(--color-1) !important;
  background: #e33042;
  text-decoration: none;
}

/**.btnslog a:hover img{
  filter: brightness(1);
}
.btnslog a:last-child:hover {
  background: transparent;
} */

.btn_iconuser {
  width: auto;
  height: 0.99vw;
  /*width: 0.9192708333333333vw;
  height: 0.9192708333333333vw;*/
  cursor: pointer;
}

.btn_iconshop {
  width: auto;
  height: 0.99vw;
  cursor: pointer;
}

.btn_smuser.i {
  width: 1.068vw !important;
  height: 1.068vw;
}

.btn_smuser.l {
  width: 1.068vw !important;
  height: 0.885vw;
}

.btn_smuser.t {
  width: 1.094vw;
  height: 0.885vw;
}

.btn_smuser.f {
  width: 0.573vw;
  height: 1.068vw;
}

.btn_smuser.yt {
  width: 1.273vw;
  height: 0.968vw;
}

.btn_smuser.tt {
  width: 0.973vw;
  height: 1.068vw;
}

.iconbuscar {
  width: 0.8802083333333331vw;
  height: 0.8802083333333331vw;
}

.burger-cont {
  display: none;
}

#buscador-hh {
  margin-top: 1.68333333333333vw;
  margin-left: 31.145833vw;
  border: 0.1vw solid var(--color-4);
  border-radius: 1.0416666666666665vw;
  width: 26.041666666666668vw;
  height: 2.34375vw;
  margin-bottom: 1.683333vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-de-busqueda {
  border: 0vw;
  background: transparent;
}

.form-control-hh {
  margin-left: 0.6vw;
  width: 18vw;
  background: var(--color-1);
  border: 0vw;
  color: var(--color-4);
  font-weight: 700;
  font-size: 0.6vw;
  /* letter-spacing: 0.1vw; */
  padding-left: 1vw;
  padding-right: 1vw;
}

.form-control-hh:focus {
  outline: 0px;
  border: 0vw;
}

#menu-hh {
  margin-right: 5.313vw;
  display: flex;
  align-items: center;
}

#menu-hh ul {
  display: flex;
  margin-bottom: 0;
}

#menu-hh ul.menucito {
  display: flex;
  width: 50vw;
  justify-content: space-between;
}
#menu-hh ul.menucito a {
  color: white;
}

#menu-hh ul a {
  color: black;
  text-decoration-color: none !important;
}
#menu-hh ul a:hover {
  text-decoration-color: none !important;
}
#menu-hh ul.menucito li.dd1,
#menu-hh ul.menucito li.dd2,
#menu-hh ul.menucito li.dd3,
#menu-hh ul.menucito li.dd4,
#menu-hh ul.menucito li.dd5,
#menu-hh ul.menucito li {
  color: var(--color-4);
  padding-bottom: 0vw;
  padding-top: 0vw;
  margin-bottom: 0vw;
  font-size: 0.770833333333334vw;
  min-width: 4.947916666666666vw;
  height: 2.1354166666666665vw;
  padding: 0.625vw 0.6895833333333333vw;
  text-align: center;
  transition: 0.2s;
  font-weight: initial;
  font-family: "Rajdhani";
}

#menu-hh ul li.dd1:hover ul.dropa,
#menu-hh ul li.dd2:hover ul.dropa2,
#menu-hh ul li.dd3:hover ul.dropa3,
#menu-hh ul li.dd4:hover ul.dropa4,
#menu-hh ul li.dd5:hover ul.dropa5 {
  pointer-events: all;
  opacity: 1;
  max-height: fit-content;
  transition-property: max-height;
  transition-delay: 0;
  transition-duration: 0.5s;
  padding: 1.302vw;
  margin-top: 0.5s;
}
#menu-hh ul.dropa,
#menu-hh ul.dropa2,
#menu-hh ul.dropa3,
#menu-hh ul.dropa4,
#menu-hh ul.dropa5 {
  pointer-events: none;
  flex-direction: column;
  position: absolute;
  background-color: black;
  z-index: 999;
  margin-top: 0;
  top: 11vw;
  margin-left: -0.7vw;
  width: auto;
  max-height: 0;
  opacity: 0;
  transition-property: max-height;
  transition-delay: 0;
  transition-duration: 0.5s;
  padding: 0;
}
#menu-hh ul.dropa li,
#menu-hh ul.dropa2 li,
#menu-hh ul.dropa3 li,
#menu-hh ul.dropa4 li,
#menu-hh ul.dropa5 li {
  text-align: center !important;
  opacity: 0;
  transition-property: opacity;
  transition-delay: 0;
  transition-duration: 0.5s;
  overflow: hidden;
  height: 0;
  padding: 0;
  text-transform: uppercase;
}
#menu-hh li.dd1:hover ul.dropa li,
#menu-hh li.dd2:hover ul.dropa2 li,
#menu-hh li.dd3:hover ul.dropa3 li,
#menu-hh li.dd4:hover ul.dropa4 li,
#menu-hh li.dd5:hover ul.dropa5 li {
  opacity: 1;
  transition-property: opacity;
  transition-delay: 0.2s;
  font-size: 0.770833333333334vw;
  line-height: 0.7vw;
  transition: 0.25s;
  padding: 0;
  height: 1.65417vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*#menu-hh li.dd1:hover  ul.dropa li:nth-child(1),
#menu-hh li.dd2:hover  ul.dropa2 li:nth-child(1),
#menu-hh li.dd3:hover  ul.dropa3 li:nth-child(1),
#menu-hh li.dd4:hover  ul.dropa4 li:nth-child(1){
    padding-top: 1.302vw;
}#menu-hh li.dd1:hover  ul.dropa li:last-child,
#menu-hh li.dd2:hover  ul.dropa2 li:last-child,
#menu-hh li.dd3:hover  ul.dropa3 li:last-child,
#menu-hh li.dd4:hover  ul.dropa4 li:last-child{
    padding-bottom: 1.302vw;
}*/
#menu-hh li.dd1:hover,
#menu-hh li.dd2:hover,
#menu-hh li.dd3:hover,
#menu-hh li.dd4:hover,
#menu-hh li.dd5:hover,
#menu-hh li:hover {
  background-color: rgb(159, 17, 31) !important;
  color: var(--color-2);
  text-decoration-color: transparent;
}

#menu-hh li.dd1:hover ul.dropa li:hover,
#menu-hh li.dd2:hover ul.dropa2 li:hover,
#menu-hh li.dd3:hover ul.dropa3 li:hover,
#menu-hh li.dd4:hover ul.dropa4 li:hover,
#menu-hh li.dd5:hover ul.dropa5 li:hover {
  background-color: transparent !important;
  color: var(--color-2);
  text-decoration-color: transparent;
}
#menu-hh li.dd1:hover ul.dropa li a,
#menu-hh li.dd2:hover ul.dropa2 li a,
#menu-hh li.dd3:hover ul.dropa3 li a,
#menu-hh li.dd4:hover ul.dropa4 li a,
#menu-hh li.dd5:hover ul.dropa5 li a {
  transition: 0.5s;
  text-decoration-color: transparent;
}
#menu-hh li.dd1:hover ul.dropa li:hover a,
#menu-hh li.dd2:hover ul.dropa2 li:hover a,
#menu-hh li.dd3:hover ul.dropa3 li:hover a,
#menu-hh li.dd4:hover ul.dropa4 li:hover a,
#menu-hh li.dd5:hover ul.dropa5 li:hover a {
  background-color: transparent !important;
  color: var(--color-2);
  width: 100%;
}
.iconpolygon {
  width: 0.5208333333333333vw;
  height: 0.5208333333333333vw;
  margin-left: 0.5vw;
  margin-top: -0.112vw;
  transform: rotate(180deg);
  transition: 0.2s;
}
#menu-hh ul li:hover .iconpolygon {
  transform: rotate(0);
} /*
#menu-hh ul li:hover {
  background-color: var(--color-2);
}*/

#home {
  background: #ffffff !important;
}
.columna-sec1-hh {
  display: flex;
  align-items: center;
}
/** fin header home */

/**slide secc */
.principal-slide .vueperslides__parallax-wrapper {
  height: 34.271vw;
}
.principal-slide {
  display: block;
}
.principal-slide-mo {
  display: none;
}
.slide-2-cont {
  background: white;
  display: block;
  margin: 1vw auto;
}

.sl2-mo {
  display: none;
}
.slide-2-cont .img-news {
  /*background-position-x: ;width: 10vw;
    height: 5.885vw;
    border-radius: 1vw;
    background-size: 100%;
    transition:.25s;*/
  width: 10vw;
  height: 5.885vw;
  border-radius: 1vw;
  background-size: 114%;
  background-position-x: center;
  background-position-y: top;
  transition: 0.65s;
  filter: contrast(1);
  margin-left: 0.5vw;
}
.slide-2-cont .news-content-s:hover .img-news {
  background-position-x: center;
  background-size: 150%;
}
.slide-2-cont .news-content-s .hover-diag {
  position: absolute;
  background-image: url("./assets/diag.png");
  opacity: 0.6;
  z-index: 10;
  background-size: 5vw 100%;
  background-repeat: no-repeat;
  background-position-x: 10vw;
  width: 10vw;
  height: 5.885vw;
  border-radius: 1vw;
  transition: 0.75s;
}
.slide-2-cont .news-content-s:hover .hover-diag {
  background-position-x: -6vw;
  background-color: #0f2dff9c;
}
.slide-2-cont .news-content-s {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1vw;
    cursor: pointer;
}
.slide-2-cont .news-text {
  padding-left: 0.99vw;
  width: 7.302vw;
  /*position: absolute;*/
}
.slide-2-cont .news-text .titulo-nov {
  width: 4.983vw;
  background: #b70d1d;
  /*background: #032a4b;*/
  font-style: normal;
  font-size: 0.721vw;
  line-height: 0.721vw;
  color: #ffffff;
  font-family: "Rajdhani";
  padding: 0.313vw 0.885vw;
  margin-bottom: 0.5vw;
}
.news-text p {
  font-size: 0.829vw;
  line-height: 0.833vw;
  font-family: "Rajdhani";
  color: #032a4b;
  cursor: pointer;
  margin-bottom: 0;
}
.news-text a {
  text-decoration-color: white;
  transition: 0.5s;
}
.news-text a:hover {
  text-decoration-color: #032a4b;
}
.slide-2-cont .vueperslides__arrows--outside .vueperslides__arrow--next {
  left: auto;
  right: 0;
}
.slide-2-cont .vueperslides__arrows--outside .vueperslides__arrow--prev {
  right: auto;
  left: 0;
}
.slide-2-cont .vueperslides__parallax-wrapper {
  width: 86.604vw;
  display: flex;
  margin: 0 auto;
  padding-bottom: 13% !important;
}
.slide-2-cont .vueperslides__track-inner {
  white-space: nowrap;
  height: 12.792vw;
  display: flex;
  align-items: center;
}

.slide-2-cont .vueperslides {
  position: relative;
  height: 14vw;
}
.slide-2-cont .vueperslides__inner {
  /*height: 11.792vw;*/
  height: 9.792vw;
}
.slide-2-cont
  .vueperslide.vueperslide--active.vueperslide--visible
  .news-content-s {
  opacity: 1;
  width: 21.094vw;
  height: 9vw;
  border-bottom: 0.5vw solid #b70d1e94;
}
.slide-2-cont
  .vueperslide.vueperslide--active.vueperslide--visible
  .news-content-s
  a {
  pointer-events: auto;
  cursor: pointer;
}
.slide-2-cont .vueperslide .news-content-s a {
  /**pointer-events: none;*/
  cursor: default;
}
.slide-2-cont
  .vueperslide.vueperslide--active.vueperslide--visible
  .news-content-s:hover {
  border-bottom: 0.5vw solid #b70d1d;
}
.slide-2-cont .vueperslide .news-content-s {
  transition: 0.5s;
  width: 21.094vw;
  height: 9vw;
  border-bottom: 0.5vw solid transparent;
  background: rgb(255, 255, 255);
  margin: auto;
  border-radius: 0.521vw 0.521vw 0 0;
}
.slide-2-cont .vueperslide .news-content-s:hover {
  box-shadow: 0.104vw 0.104vw 0.573vw rgb(0 0 0 / 15%);
  border-bottom: 0.5vw solid #b70d1d;
}
.slide-2-cont button.vueperslides__arrow.vueperslides__arrow--prev svg,
.slide-2-cont button.vueperslides__arrow.vueperslides__arrow--next svg {
  display: none;
}
.slide-2-cont button.vueperslides__arrow.vueperslides__arrow--prev {
  width: 1.563vw;
  height: 1.042vw;
  background-image: url("./assets/arrow-left.png");
  background-size: 100%;
  background-repeat: no-repeat;
  left: 3.385vw;
  transform: 0.5s;
  top: 6vw;
}
.slide-2-cont.webp button.vueperslides__arrow.vueperslides__arrow--prev {
  background-image: url("./assets/webp/arrow-left.webp") !important;
}
.slide-2-cont.webp button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url("./assets/webp/arrow-right.webp") !important;
}
.slide-2-cont button.vueperslides__arrow.vueperslides__arrow--next {
  width: 1.563vw;
  height: 1.042vw;
  background-image: url("./assets/arrow-right.png");
  background-size: 100%;
  background-repeat: no-repeat;
  right: 3.385vw;
  transform: 0.5s;
  top: 6vw;
}
.banneh-image.pece {
  display: block;
}
.banneh-image.mobileh {
  display: none;
}
.banneh-image {
  width: 100%;
  height: auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
img.imagen-1 {
  height: 34.271vw;
  width: 50%;
  object-fit: cover;
  object-position: top;
}
.video-play-banner p {
  display: none;
}
.video-play-banner .ql-video {
  width: 100%;
  height: 34.271vw;
}

#partidos {
  width: auto;
  height: 40.213vw;
  background-image: url("./assets/slide-bk.png");
  background-size: 100%;
}
#partidos.webp {
  background-image: url("./assets/slide-bk.png");
}
#partidos .header-partidos {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 1.333vw 5.781vw 0 7.865vw;
}
#partidos .versus-h1 {
  font-size: 0.729vw;
  padding: 0.938vw 0.2vw;
  width: 2.656vw;
  height: 2.656vw;
  border: 1px solid #ffffff;
  border-radius: 2vw;
  text-align: center;
}
#partidos h1.titulo-partidos {
  color: white;
  font-size: 2.083vw;
  line-height: 2.396vw;
  font-family: "Rajdhani";
  letter-spacing: 0.135em;
  width: auto;
  height: 4.592vw;
  /* background-image: url("./assets/rect-1.png");
    background-repeat: no-repeat;
    background-size: 6.146vw;
    background-position-x: left;
    background-position-y: bottom;*/
  cursor: default;
}
#partidos .calendar-cont {
  background-image: url("./assets/arrow-calendar.png");
  background-repeat: no-repeat;
  background-size: 0;
  background-position: left;
  height: 1vw;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center; 
}
#partidos .calendar-cont:hover {
  background-position: right;
  background-size: 4.896vw;
}
#partidos .vueperslides__bullets {
  display: none;
}
#partidos .calendar-cont a {
  font-family: "Rajdhani";
  font-size: 0.938vw;
  line-height: 1.094vw;
  color: #ffffff;
  padding-right: 2.4vw;
  padding-left: 2.4vw;
  transition: 0.2s;
} /*#partidos .slide-calendar.versa {
    width: 49.583vw;
}*/
#partidos .calendar-cont a:hover {
  text-decoration: none;
  padding-right: 5.4vw;
  padding-left: 0;
  color: white !important;
}
#partidos .prev-last-next-match a {
  color: transparent;
}
#partidos .slide-calendar {
  width: 46.531vw;
  height: 13.958vw;
  display: flex;
  background: #032a4b;
  color: white;
  border-radius: 2vw;
  font-family: "Rajdhani" !important;
  transition: 0.55s;
  overflow: hidden;
}
#partidos .slide-calendar:hover {
  transform: scale(1.05);
}
#partidos
  .vueperslide.vueperslide--active.vueperslide--visible
  .prev-last-next-match
  b,
#partidos .vueperslide.vueperslide--visible .prev-last-next-match b {
  color: #032a4b;
  font-family: "Rajdhani";
}
#partidos
  .vueperslide.vueperslide--active.vueperslide--visible
  .prev-last-next-match,
#partidos .vueperslide.vueperslide--visible .prev-last-next-match {
  color: #ce1526;
  font-family: "Rajdhani";
  font-weight: lighter;
  font-size: 1.875vw;
  line-height: 2.135vw;
  width: fit-content;
  text-align: center;
  padding: 3.246vw 0 1.646vw;
  text-transform: uppercase;
  font-weight: bold;
  transition: 1s;
}
#partidos .vueperslide .prev-last-next-match {
  color: transparent;
  padding: 3.246vw 0 1.646vw;
  font-size: 1.875vw;
  line-height: 2.135vw;
  height: 10vw;
}
#partidos .vueperslides__arrows {
  color: #fff;
  position: absolute;
  top: 25vw;
  width: 100%;
}
#partidos button.vueperslides__arrow.vueperslides__arrow--prev {
  width: 12.448vw;
  height: 2.658vw;
  background-image: url("./assets/panterior.png");
  background-size: 12.448vw;
  background-repeat: no-repeat;
  margin-left: 9.219vw;
}
#partidos button.vueperslides__arrow.vueperslides__arrow--prev {
  background-image: url("./assets/webp/panterior.webp");
}

#partidos button.vueperslides__arrow.vueperslides__arrow--next {
  width: 9.688vw;
  height: 2.658vw;
  background-image: url("./assets/proxp.png");
  background-size: 12.448vw;
  background-repeat: no-repeat;
  margin-right: 9.219vw;
}
#partidos button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url("./assets/webp/proxp.webp");
}

#partidos button.vueperslides__arrow.vueperslides__arrow--next:hover,
#partidos button.vueperslides__arrow.vueperslides__arrow--prev:hover {
  transform: scale(1.15);
  margin-top: -2vw;
}
#partidos button.vueperslides__arrow.vueperslides__arrow--prev svg {
  display: none;
}
#partidos button.vueperslides__arrow.vueperslides__arrow--next svg {
  display: none;
}
#partidos .fecha-cont {
  height: 15.156vw;
  width: auto;
  background: transparent;
  margin-left: 3.229vw;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
#partidos .fecha-cont .fecha {
  font-size: 2.604vw;
  line-height: 2.169vw;
  text-align: center;
  margin-bottom: 0;
  padding: 2.24vw 1.198vw 0;
  color: #968220;
}
#partidos .fecha-cont .mes {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.25vw;
  line-height: 1.458vw;
  text-transform: uppercase;
  font-weight: 900;
}
#partidos .fecha-cont .hora {
  font-weight: bolder;
  font-size: 0.938vw;
  line-height: 1.094vw;
  text-align: center;
  margin-bottom: 0;
  background: #b70d1d;
  margin-top: 0.938vw;
    padding: 0.573vw 0;
}
#partidos .partido-cont {
  padding: 1.927vw 0;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#partidos .jornada-content {
  padding-left: 2.031vw;
  padding-bottom: 1.01vw;
  /*border-bottom: 1px solid #8293a2;*/
  border-bottom: none;
  width: 35.677vw;
  display: flex;
  justify-content: space-between;
}
#partidos .partido-res {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 9vw;
  flex-direction: row;
  /*padding: 0.99vw 2.031vw;*/
  padding: 0.39vw 2.031vw;
}
#partidos .vis .partido-res {
  display: flex;
  flex-direction: row-reverse !important;
}
#partidos .tipo-juego {
  font-family: "Rajdhani";
  font-size: 1.302vw;
  line-height: 1.51vw;
  color: #728696;
  text-transform: uppercase;
}
#partidos .estadio-name {
  font-size: 0.729vw;
  line-height: 0.833vw;
  color: #ffffff;
}
#partidos .local-cont,
#partidos .visita-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#partidos .team-cont {
  display: flex;
  height: 3vw;
}
#partidos .logo-equipo {
  width: auto;
  /*height: 4.115vw;*/
  height: 3.115vw;
}
#partidos .nombre-equipo {
  font-size: 1.042vw;
  line-height: 1.198vw;
  text-transform: uppercase;
  width: 9.6vw;
  padding-left: 1.198vw;
  margin-bottom: 0;
}
#partidos .resultado {
  font-weight: bolder;
  font-size: 1.042vw;
  line-height: 1.042vw;
  color: #ffffff;
  padding: 0.573vw 0.833vw;
  margin-bottom: 0;
  border-radius: 2.24vw;
  width: 2.24vw;
  height: 2.24vw;
  background: #b70d1d;
}
#partidos .visita-cont .resultado {
  background: #968220;
}
#partidos .vueperslide {
  /*padding: 0 2%;*/
  width: 100% !important;
  /* filter: blur(0.2vw) !important;*/
  transform: scale(0.8);
  transition: 0.5s;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
#partidos .vueperslide.vueperslide--active.vueperslide--visible,
#partidos .vueperslide--visible {
  /* filter: blur(0) !important;*/
  transform: scale(1);
}
#partidos .vueperslides__track-inner {
  width: 49% !important;
  margin: auto;
  touch-action: pan-x;
}
#partidos .vueperslides__parallax-wrapper {
  width: 100% !important;
  display: flex;
  margin: 0 auto;
  padding-bottom: 0 !important;
  height: 29vw;
}
#partidos
  .vueperslides:not(.no-shadow):not(.vueperslides--3d)
  .vueperslides__parallax-wrapper:after,
#partidos
  .vueperslides:not(.no-shadow):not(.vueperslides--3d)
  .vueperslides__parallax-wrapper:before {
  display: none;
}
#partidos .prev-last-next-match.anterior:before,
#partidos .prev-last-next-match.proximo:before {
  content: "PARTIDO ";
  color: transparent;
}
#partidos .prev-last-next-match.ultimo:after {
  content: " PARTIDO";
  color: transparent;
}
#partidos
  .vueperslide.vueperslide--active.vueperslide--visible
  .prev-last-next-match.anterior:before,
#partidos
  .vueperslide.vueperslide--active.vueperslide--visible
  .prev-last-next-match.proximo:before {
  color: #032a4b;
  font-weight: bold !important;
}
#partidos
  .vueperslide.vueperslide--active.vueperslide--visible
  .prev-last-next-match.ultimo:after {
  color: #032a4b;
  font-weight: bold !important;
}
#partidos .vueperslide.vueperslide--visible .l-button,
#partidos .vueperslide.vueperslide--active .l-button {
  margin: auto;
  opacity: 0.9 !important;
  width: 22vw;
  transition: 0.25s;
  transition-delay: 0s;
  display: block;
}
#partidos .vueperslide .l-button {
  margin: auto;
  opacity: 0;
  text-align: left;
  background-position-x: 13.5vw;
  overflow: hidden;
  display: none;
  transition: 0.5s;
}
#partidos .visita-cont .nombre-equipo {
  width: 9vw;
}
#partidos .channel {
  height: 1.094vw;
  width: auto;
  filter: brightness(5.5);
}
#partidos .canales {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-end;
  border-radius: 0 !important;
  border: none !important;
}

#sponsor {
  padding: 6.302vw 8.333vw;
}
#sponsor a .banner-sponsor {
  width: 83.542vw;
  height: auto;
  display: flex;
  margin: auto;
  padding: 0;
}
#ultimasNoticias {
  /*padding: 5.885vw 6.606vw 0 8.606vw;*/
  padding: 0vw 6.606vw 5.885vw 8.606vw;
  font-family: "Rajdhani";
}
.titulo-u-noticias {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.979vw;
  border-bottom: 1px solid black;
  background-image: url("./assets/red-rec.png");
  background-repeat: no-repeat;
  background-size: 20.99vw 0.833vw;
  background-position-y: bottom;
  transition: 0.25s;
}
.titulo-u-noticias.webp {
  background-image: url("./assets/webp/red-rec.webp");
}
.container-n-v-i:hover .titulo-u-noticias {
  background-size: 41vw 0.833vw;
}
.titulo-u-noticias h4 {
  font-family: "Rajdhani";
  font-weight: lighter;
  font-size: 1.823vw;
  line-height: 2.083vw;
  text-transform: uppercase;
  color: #b70d1d;
}
.titulo-u-noticias h4 span {
  font-family: "Rajdhani";
  color: #032a4b;
}
.patro-u-n {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.patro-u-n p {
  font-size: 0.781vw;
  line-height: 0.885vw;
  color: #b70d1d;
  cursor: default;
  font-family: "Rajdhani";
}
.patro-u-n img {
  height: auto;
  width: 7.552vw;
}
.l-div {
  width: 19.688vw;
  height: 14.115vw;
  background-color: #021d35;
  display: flex;
  align-items: flex-end;
}
#ultimasNoticias a .btn-ver-noticias {
  color: white;
  background: #b70d1d;
  font-weight: bold;
  font-size: 0.938vw;
  line-height: 1.094vw;
  width: 18.229vw;
  height: auto;
  padding: 1.875vw 0 1.875vw 2.917vw;
  transition: 0.5s;
  background-image: url("./assets/arrow2.png");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 10.573vw;
  background-size: 5.417vw;
  margin-left: auto;
}
#ultimasNoticias a .btn-ver-noticias:hover {
  background: #830814;
  background-image: url("./assets/arrow2.png");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 10.573vw;
  background-size: 5.417vw;
}
#clasificacion {
  display: none;
}
#clasificacion {
  margin: 0 6.979vw;
}
#clasificacion .titulo-clas {
  text-align: center;
  margin-top: 12.969vw;
  border-bottom: 1px solid #b70d1d;
  background-image: url("./assets/red-rec.png");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-size: 6.146vw;
}
#clasificacion .titulo-clas h2 {
  font-weight: bold;
  font-size: 1.875vw;
  line-height: 2.135vw;
  color: #032a4b;
  margin-bottom: 1.563vw;
}
#clasificacion .titulo-clas h1 {
  font-weight: bold;
  font-size: 7.813vw;
  line-height: 8.958vw;
  color: #968220;
  margin-bottom: 5.208vw;
}
#clasificacion .titulo-clas h3 {
  font-weight: bold;
  font-size: 1.458vw;
  line-height: 1.667vw;
  letter-spacing: 0.23em;
  color: #b70d1d;
  padding-bottom: 3.333vw;
}
#clasificacion .tablas-ind-clas {
  padding: 5.365vw 0 9.375vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#clasificacion .stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#clasificacion .player {
  width: 14.531vw;
  height: auto;
}
#clasificacion .ilu {
  height: 1.4vw;
  width: auto;
}
#clasificacion .stats-count {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-evenly;
}
#clasificacion .tabla-individual {
  width: 50%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}
#clasificacion .stats h3 {
  font-weight: bold;
  font-size: 1.563vw;
  line-height: 1.771vw;
  padding-top: 2.344vw;
  color: #968220;
  cursor: default;
}
#clasificacion .stats p {
  font-weight: bold;
  font-size: 0.677vw;
  line-height: 0.781vw;
  letter-spacing: 0.18em;
  color: #032a4b;
  margin-bottom: 1.563vw;
  cursor: default;
}
#clasificacion .stats-count h4 {
  font-weight: bold;
  font-size: 1.823vw;
  line-height: 449.99%;
  color: #032a4b;
}
#clasificacion .liga-tabla td {
  font-size: 1.25vw;
  padding: 1.823vw 1.01vw;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #032a4b;
  color: #032a4b;
}
#clasificacion .logo-tb-cont {
  padding: 0 !important;
}
#clasificacion .tablalmx {
  width: 46%;
}
#clasificacion .puntos-cont {
  color: white !important;
  background: #032a4b;
}
#clasificacion .puntos-cont.cobre {
  background: #968220 !important;
  color: white;
  border: 1px solid #968220 !important;
}
#clasificacion .tabla-header td {
  color: #b70d1d;
  border-bottom: 1px solid #b70d1d;
  border-top: none;
}
#clasificacion .tabla-header td:first-child {
  border-left: none;
  border-right: none;
}
#clasificacion .tabla-header td:last-child {
  border-right: none;
}
#clasificacion .tabla-header td.logo-tb-cont {
  border-left: none !important;
  border-right: 1px solid white !important;
}
#clasificacion tr.tabla-content td:first-child {
  border-left: none !important;
}
#clasificacion tr.tabla-content td:last-child {
  border-right: none !important;
}
#clasificacion tr.tabla-content:last-child td {
  border-bottom: none;
}
#clasificacion .nombre-tb-cont {
  border-right: none !important;
}
#clasificacion .btn-ver-tabla {
  width: 23.854vw;
  color: #032a4b;
  border: 1px solid #032a4b;
  padding: 1.823vw 0 1.823vw 2.969vw;
  margin-top: 8.177vw;
  font-weight: bold;
  font-size: 0.938vw;
  line-height: 1.094vw;
  background-image: url("./assets/arrow3.png") !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  background-position-x: 16.302vw !important;
  background-size: 5.521vw !important;
  background: white;
  transition: 0.5s;
}
#clasificacion .btnvt {
  width: 23.854vw;
  height: auto;
  margin-left: auto;
}
#clasificacion .btn-ver-tabla:hover {
  filter: drop-shadow(2px 4px 2px black);
}
#clasificacion .btnvt a:hover {
  text-decoration: none;
}
#clasificacion .icon-team {
  width: 3.646vw;
  height: auto;
}

#ultimosVideos,
#ultimasImagenes {
  padding: 5.885vw 6.606vw 0 8.606vw;
  padding-bottom: 12.604vw;
}
#ultimosVideos .l-cont:hover .l-m-content,
#ultimasImagenes .l-cont:hover .l-m-content {
  /*filter: contrast(1.6) saturate(1);*/
}
#ultimosVideos .l-div,
#ultimasImagenes .l-div {
  width: 19.688vw;
  height: 18.115vw;
  background-color: #021d35;
  display: flex;
  align-items: flex-end;
}

#ultimasImagenes {
  padding: 0 8.906vw;
  padding-bottom: 12.604vw;
}

.titulo-vim {
  padding: 0 1.823vw;
  position: absolute;
  margin-top: -18.115vw;
  width: 19.7vw;
  height: 18.115vw;
  background: linear-gradient(360deg, #01203a, transparent);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.l-cont .titulo-vim .titulo {
  width: 100%;
  margin: auto;
  margin-bottom: 3vw;
  font-size: 0.938vw;
  line-height: 1.094vw;
  text-transform: uppercase;
  color: #ffffff;
}
.l-cont .titulo-vim .date {
  font-size: 0.69vw;
  line-height: 0.769vw;
  color: #ffffff;
  text-transform: uppercase;
  margin: 1.031vw 0;
  background-image: url("./assets/reloj.png");
  background-repeat: no-repeat;
  background-size: 1.094vw;
  padding: 0.2vw 1.9vw;
}

.l-cont.pc {
  display: block;
  transition: 0.25s;
}
.l-cont.pc:hover {
  box-shadow: 0.104vw 0.104vw 0.573vw rgb(0 0 0 / 15%);
}
.l-cont.mo {
  display: none;
}
.last-cont {
  display: flex;
  /*justify-content: space-between;*/
  margin-top: 5vw;
  width: 105%;
  margin-bottom: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.l-cont {
  width: 19.688vw;
  height: auto;
  padding-bottom: 0;
  font-family: "Rajdhani";
  margin: 0 0.6vw 3.229vw;
  cursor: pointer;
} /*.l-cont.pc:hover {
    transform: scale(1.05);
}*/
.l-m-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: 0.5s;
}
.l-cont .date {
  font-size: 0.729vw;
  line-height: 0.833vw;
  color: #b70d1d;
  text-transform: uppercase;
  /*margin: 2.031vw 0;*/
  background-image: url("./assets/reloj.png");
  background-repeat: no-repeat;
  background-size: 1.094vw;
  padding: 0.2vw 1.5vw;
}
.l-cont .date.webp {
  background-image: url("./assets/webp/reloj.webp");
}
.l-cont .titulo {
  font-size: 1.302vw;
  line-height: 1.51vw;
  text-transform: uppercase;
  color: #032a4b;
  width: 90%;
  margin-bottom: 0;
  transition: 0.5s;
  text-decoration: none;
  font-family: "Rajdhani";
  text-decoration-color: white;
}
.l-cont a {
  transition: 0.5s;
  text-decoration: none;
}
.l-cont a .titulo:hover {
  text-decoration: underline;
  text-decoration-color: #032a4b;
}
.l-cont a:hover {
  text-decoration: none;
}
/*.l-cont:hover .l-m-content {
  filter: saturate(1.5);
}*/
.text-cont a p.parrafo {
  font-size: 0.833vw;
  line-height: 127%;
  color: #181818;
  font-family: "Rajdhani";
  margin-bottom: 0;
}
.text-cont a {
  /*height: 10vw;*/
  height: fit-content;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.texto-prev {
  font-weight: 500;
  font-size: 0.833vw;
  line-height: 1.042vw;
  color: #8293a1;
  width: 90%;
}
.l-cont:hover .red-rec {
  width: 19.588vw;
  height: 0.5vw;
} /*
.l-cont:hover .text-cont{
    filter: drop-shadow(1px 6px 5px rgba(42, 46, 75, 0.515));
}*/
.text-cont {
  padding: 2.031vw 0.7vw 1.3vw;
  background: white;
  transition: 0.5s;
  height: auto;
} /*
#ultimasImagenes .red-rec,
#ultimosVideos .red-rec {
  margin-top: 17.7%;
}*/
.red-rec {
  z-index: 99;
  width: 6.146vw;
  height: 0.5vw;
  transition: 0.2s;
  position: absolute;
}
.l-button {
  background: #b70d1d;
  color: white;
  font-size: 0.938vw;
  line-height: 1.094vw;
  font-family: "Rajdhani";
  padding: 1.823vw 6.665vw;
  width: 18.906vw;
  height: auto;
  background-image: url("./assets/arrow2.png");
  background-size: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 0;
  transition-delay: 0 !important;
  transition: 0.25s;
  display: flex;
  align-items: center;
}
.l-button:hover {
  background: #830814;
  background-image: url("./assets/arrow2.png");
  background-size: 5.885vw;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 10.521vw;
  transition-delay: 0 !important;
  text-align: left;
  padding: 1.823vw 2.865vw;
}
.l-button.webp:hover {
  background-image: url("./assets/webp/arrow2.webp");
}
.lastb {
  width: 18.906vw;
  height: auto;
  margin-left: auto;
}
.lastb a:hover {
  text-decoration: none;
}

#storeSection {
  background: linear-gradient(180deg, #e02d3e 0%, #b70d1d 100%);
  width: 100%;
  height: auto;
}
/*#storeSection .vueperslide.vueperslide--active.vueperslide--visible {
  background: #0000001c;
}*/
#storeSection .jugador-store {
  height: 63.958vw;
  width: auto;
  margin: 6.51vw 4.948vw 0;
}
#storeSection .store-sec-cont {
  display: flex;
  flex-direction: column;
}
#storeSection .store-sec-cont {
  width: 100%;
  padding: 0 9.74vw;
}
#storeSection .ssc-titulo {
  display: inline !important;
  text-align: left;
  width: 100%;
  padding-top: 3.698vw;
  color: white;
  font-family: "Roboto" !important;
  font-weight: bold !important;
  padding-bottom: 2.448vw;
}
#storeSection .ssc-titulo h2 {
  font-size: 1.25vw;
  line-height: 118.49%;
  letter-spacing: 0.105em;
  text-transform: uppercase;
  font-family: "Rajdhani";
}
#storeSection .ssc-titulo h3 {
  font-size: 3.75vw;
  line-height: 3vw;
  font-family: "Rajdhani";
  /*text-decoration: underline;*/
  color: #032a4b;
}
#storeSection .scc-slide {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 80.313vw;
  height: auto;
  padding: 3.177vw 0;
}
#storeSection .scc-slide.pc {
  display: block;
}
#storeSection .scc-slide.mo {
  display: none;
}
#storeSection .btn-ver-tienda-cont {
  margin: 3.073vw auto;
}
#storeSection .btn-ver-tienda {
  font-size: 1.042vw;
  line-height: 1.198vw;
  letter-spacing: 0.055em;
  font-family: "Rajdhani";
  text-decoration: underline;
  text-decoration-color: transparent;
  color: #ffffff;
  transition: 0.35s;
  background-image: url("./assets/arrow2.png");
  background-size: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 0;
  transition-delay: 0 !important;
}
#storeSection.webp .btn-ver-tienda {
  background-image: url("./assets/webp/arrow2.webp");
}
#storeSection .btn-ver-tienda:hover {
  text-decoration-color: transparent;
  color: white;
  background-size: 5.885vw;
  background-position-x: 14.521vw;
  transition-delay: 0 !important;
  padding-right: 12vw;
}
#storeSection a:hover {
  text-decoration: none;
}
#storeSection .store-item,
.categories-products-cont .store-item {
  margin: 0;
  margin-right: 1.4vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.25s;
}
.categories-products-cont .store-item:hover {
  transform: scale(1.1);
}
#storeSection .item-store-cont {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 1.302vw;
  padding: 2.292vw;
  width: 24.167vw;
  display: flex;
  justify-content: center;
  /*background: #c31627;*/
  transition: 0.25s;
} /*.add-widget#storeSection .store-item:hover .item-store-cont {
    background: linear-gradient(
180deg, rgba(2,0,36,0) 0%, rgba(255,230,238,.25) 49%, rgba(255,255,255,.5) 100%);
}*/
#storeSection .item-store-cont img,
.categories-products-cont .item-store-cont img {
  height: 19.74vw;
  width: auto;
}
#storeSection
  .vueperslide.vueperslide--active.vueperslide--visible
  .store-item {
  opacity: 1;
}
#storeSection .vueperslide .store-item {
  opacity: 1;
}
#storeSection .vueperslide .store-item:hover {
  transform: scale(1.05);
}
#storeSection .vueperslides__parallax-wrapper {
  /*position: relative;
    overflow: hidden;*/
  height: 45vw !important;
  padding-bottom: 0 !important;
}
#storeSection .ti-pr-cont {
  width: 100%;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 1.875vw 1.771vw;
  font-size: 1.25vw;
  margin-bottom: 0.4vw;
}
#storeSection .ti-pr-cont p,
.categories-products-cont .ti-pr-cont h5 {
  font-size: 1.25vw;
  font-family: "Rajdhani";
}
#storeSection .ti-pr-cont p {
  text-align: center;
  margin: 1vw 0;
}
#storeSection .ti-pr-cont h6,
.categories-products-cont .ti-pr-cont h6 {
  font-size: 1.25vw;
  font-family: "Rajdhani";
} /*
#storeSection .item-store-cont {
  transition: .25s;
}
#storeSection .item-store-cont:hover {
  transform: scale(1.2);
}*/
#storeSection .pr-cont,
.categories-products-cont .pr-cont {
  padding: 0.5vw 0;
  background: #968220;
  width: 10vw;
  height: 2.04vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rajdhani";
  transition: 0.25s;
  font-size: 1vw;
}
#storeSection .pr-cont:hover,
.categories-products-cont .pr-cont:hover {
  background: #7c6a0d;
}
#storeSection .vueperslides__track-inner {
  height: 43vw;
  display: flex;
  align-items: center;
}
#storeSection .comprar-btn,
.categories-products-cont .comprar-btn {
  background: #032a4b;
  color: white !important;
  width: 24.01vw;
  height: 4.688vw;
  letter-spacing: 0.1vw;
  display: flex;
  align-items: center;
  padding: 0 9.688vw;
  font-family: 1.042vw;
  font-family: "Rajdhani";
  cursor: pointer;
  transition: 0.5s;
  background-image: url("./assets/arrow2.png");
  background-size: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 0;
  transition-delay: 0 !important;
  transition: 0.25s;
}
#storeSection.webp .comprar-btn {
  background-image: url("./assets/webp/arrow2.webp");
}
#storeSection .comprar-btn:hover,
.categories-products-cont .comprar-btn:hover {
  padding: 0 4vw;
  color: #ffffff !important;
  background: #021729;
  background-image: url("./assets/arrow2.png");
  background-size: 5.885vw;
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 14.521vw;
  transition-delay: 0 !important;
}
#storeSection.webp .comprar-btn:hover {
  background-image: url("./assets/webp/arrow2.webp");
}
#storeSection .vueperslides__arrows.vueperslides__arrows--outside {
  position: absolute;
  width: 8.2vw;
  height: 5vw;
  top: -8vw;
  display: flex;
  justify-content: space-evenly;
  margin-left: 71.031vw;
}
#storeSection.webp button.vueperslides__arrow.vueperslides__arrow--prev,
#storeSection.webp button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url("./assets/webp/slide-btn-store.webp");
}
#storeSection.webp button.vueperslides__arrow.vueperslides__arrow--prev:hover,
#storeSection.webp button.vueperslides__arrow.vueperslides__arrow--next:hover {
  background-image: url("./assets/webp/slide-btn-store-white.webp");
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--prev {
  background-image: url("./assets/slide-btn-store.png");
  background-size: 100%;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  width: 3.75vw;
  height: 3.75vw;
  top: 0;
  left: 0;
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url("./assets/slide-btn-store.png");
  background-size: 100%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  width: 3.75vw;
  height: 3.75vw;
  top: 0;
  right: 0;
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--prev:hover {
  background-image: url("./assets/slide-btn-store-white.png");
  background-size: 100%;
  background-repeat: no-repeat;
  transform: rotate(0deg);
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--next:hover {
  background-image: url("./assets/slide-btn-store-white.png");
  background-size: 100%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--prev svg {
  display: none;
}
#storeSection button.vueperslides__arrow.vueperslides__arrow--next svg {
  display: none;
}

/**footer */
.footer {
  display: flex;
  background: #032a4b;
  color: #ffffff;
  font-family: "Rajdhani";
  padding: 6.771vw 8.333vw;
}
.footer .column {
  width: 50%;
}
.footer .column.due {
  display: flex;
  justify-content: center;
}
.footer .foo-titulo {
  font-size: 1.563vw;
  line-height: 304.49%;
  color: #b70d1d;
  background-image: url("./assets/arrow-footer.png");
  background-size: 3.854vw;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  padding: 0 0 0 5.2vw;
  cursor: default;
}
.footer .foo-titulo.webp {
  background-image: url("./assets/webp/arrow-footer.webp");
}
.footer .foo-parrafo {
  font-size: 0.833vw;
  line-height: 184.99%;
  text-transform: uppercase;
  width: 36.646vw;
}
.footer .historia {
  padding-bottom: 5vw;
}
.footer .col-column {
  padding: 0 3.854vw;
}
.footer .col-column.uno {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid #8293a1;
  justify-content: space-around;
}
.footer .col-column.due {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer .col-mob {
  display: none;
}
.footer .sec-suscr .foo-parrafo {
  margin-bottom: 3.333vw;
}
.footer .list-col-cont {
  width: auto;
  height: 16.771vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.footer a .list-col {
  font-size: 0.99vw;
  line-height: 184.99%;
  color: #ffffff;
  text-decoration: underline;
  transition: 0.5s;
  cursor: pointer;
  text-align: center;
}
.footer a .list-col:hover {
  background-color: #8293a1;
  color: #032a4b;
  text-decoration-color: #ffffff;
}
.footer .btn-sm-cont {
  padding: 1.014vw;
  border: 1px solid #ffffff;
  height: 4.01vw;
  border-radius: 2vw;
  width: 4.01vw;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  align-items: center;
}
picture {
  height: fit-content;
}
.footer .btn-sm-col {
  width: auto;
  height: 1.146vw;
  vertical-align: middle;
}
.footer .redes-soc-col {
  display: flex;
  justify-content: space-between;
  width: 27.583vw;
}
.footer .btn-sm-cont:hover {
  border: 1px solid #968220;
  background: #968220;
}
.footer .btn-sm-cont:hover .btn-sm-col {
  filter: hue-rotate(162deg) brightness(0.5);
}
.patrocinadores {
  background: #002340;
  padding: 0 20.208vw;
  padding-bottom: 7vw;
}
.patro-col.due {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.patro-col.uno,
.patro-col.tre {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.patro-col.uno,
.patro-col.due {
  border-bottom: 1px solid #8293a1;
}
.patro-col {
  padding: 5.365vw 0;
}
.patro-logo.uno {
  width: 17.1875vw;
  height: auto;
}
.patro-logo.due {
  width: 8.906vw;
  height: auto;
}
.patro-logo.tre {
  margin-right: 2.5vw;
  width: 13.541666666666666vw;
  height: auto;
}
.patro-logo.quattro {
  width: 7.031vw;
  height: auto;
}
.patro-logo.cincue {
  width: 5.677vw;
  height: auto;
}
.patro-logo.sei {
  width: 7.865vw;
  height: auto;
}
.patro-logo.sette {
  width: 9.271vw;
  height: auto;
}
.patro-logo.octo {
  width: 9.271vw;
  height: auto;
}
.patro-logo.nueve {
  width: 6.510416666666667vw;
  height: auto;
  margin-right: 1vw;
}
.patro-logo:hover {
  filter: brightness(1.5);
}
.patro-logo {
  transition: 0.5s;
}
.foot-cimarrones {
  background: #001c33;
  padding: 3.333vw 6.875vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Rajdhani";
}
.foot-cimarrones .logo-cima-foot {
  width: auto;
  height: 7.344vw;
}
.foot-cimarrones .list-foot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 3.542vw;
}
.foot-cimarrones .list-foot a h5:hover {
  color: white;
  text-decoration-color: white;
}
.foot-cimarrones .list-foot a h5 {
  padding: 0 1.25vw;
  border-right: 1px solid white;
  font-size: 0.833vw;
  line-height: 0.833vw;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.5s;
  text-decoration: underline;
  text-decoration-color: transparent;
  cursor: pointer;
}
.foot-cimarrones .list-foot a h5.last {
  border-right: none;
}
.foot-cimarrones .list-foot a:hover {
  text-decoration: none;
}
.foot-cimarrones h5.copyright {
  font-size: 0.729vw;
  line-height: 292.99%;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 0.781vw;
  cursor: default;
}
/***FIN HOME */

/**RESULTADOS */
#res-contenido .errore {
  margin-top: 3vw;
  width: 60vw;
  display: flex;
  justify-content: center;
} /*#res-contenido .errore h1 {
    font-size: 2vw;
    text-align: center;
    font-family: 'Rajdhani';
    color: #6e94b5;
}*/
#res-contenido {
  background-color: white;
  background-image: url("./assets/res-header.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
#res-contenido .header-result-cont {
  width: 100%;
  height: 31.771vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.281vw 22.656vw;
}
#res-contenido .liga-logo-cont {
  height: 2.76vw;
  width: auto;
  margin-bottom: 2.344vw;
}
#res-contenido h1.day-result {
  font-weight: bold;
  font-size: 1.563vw;
  line-height: 1.563vw;
  text-align: center;
  color: #b70d1d;
  font-family: "Rajdhani";
  margin-bottom: 0;
  text-transform: capitalize;
}
#res-contenido h2.est-cap-result {
  font-weight: bold;
  font-size: 1.042vw;
  line-height: 2.031vw;
  text-align: center;
  color: white;
  font-family: "Rajdhani";
}
#res-contenido .ht-ft {
  font-weight: bold;
  font-size: 1.146vw;
  line-height: 1.302vw;
  letter-spacing: 0.12em;
  color: #ffffff;
  margin-top: 1.927vw;
  margin-bottom: 1.927vw;
}
#res-contenido .ng-result h2 {
  font-weight: bold;
  font-size: 1.875vw;
  line-height: 2.135vw;
  color: #ffffff;
  font-family: "Rajdhani";
  text-transform: uppercase;
}
#res-contenido h5.goal-cim {
  font-weight: normal;
  font-size: 1.042vw;
  line-height: 1.198vw;
  color: #ffffff;
  font-family: "Rajdhani";
}
.button-match-cont a {
  text-decoration-color: transparent;
}
.button-match-cont a:hover {
  text-decoration-color: transparent;
}
#res-contenido h1.result-cir {
  font-weight: normal;
  font-size: 2.865vw;
  line-height: 110.49%;
  color: #ffffff;
  font-family: "Rajdhani";
  width: 5.938vw;
  height: 5.938vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b70d1d;
  border-radius: 10vw;
  margin: 0 0.2vw;
}
#res-contenido .team-result {
  display: flex;
  align-items: center;
}
#res-contenido .ng-result {
  width: 10.969vw;
  height: auto;
}
#res-contenido .ng-result:last-child {
  display: flex;
  justify-content: flex-end;
}
#res-contenido .match-result {
  width: 54.49vw;
  display: flex;
  justify-content: center;
}
#res-contenido .logo-result {
  height: 7.135vw;
  width: auto;
  margin: 0 1.615vw;
}
#res-contenido .ht-result {
  font-weight: bold;
  font-size: 1.146vw;
  line-height: 1.302vw;
  letter-spacing: 0.045em;
  color: #ffffff;
  padding: 0.625vw 1.667vw;
  border: 1px solid white;
  font-family: "Rajdhani";
  border-radius: 3vw;
  margin-top: 1.823vw;
}
#res-contenido .match-result {
  display: flex;
  align-items: center;
}
.visita-invert {
  flex-direction: row-reverse;
}
.visita-invert-m {
  flex-direction: row-reverse;
}

#res-contenido .news-widget {
  border: 1px solid gainsboro;
}
#res-contenido .res-contenido-inside {
  display: flex;
}
#res-contenido .tabs {
  background: transparent !important;
  width: 64.26vw !important;
}
#res-contenido .tab__nav {
  flex: none !important;
  width: 100% !important;
  border-right: 1px solid white;
}
#res-contenido .tab__pagination__prev,
#res-contenido .tab__pagination__next {
  min-width: 0 !important;
}
#res-contenido .tab__nav__items .tab__nav__item {
  list-style: none;
  text-align: center;
  cursor: pointer;
  padding: 2.152vw 0 1.679vw !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
  height: 5.946vw;
  color: white;
  user-select: none;
  font-family: "Rajdhani";
  border: 1px solid white;
}
#res-contenido .bar-name-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
#res-contenido .bar-name-item.res {
  width: 16.719vw;
  height: inherit;
}
#res-contenido .bar-name-item.est {
  width: 22.917vw;
  height: inherit;
}
#res-contenido .bar-name-item.gal {
  width: 13.021vw;
  height: inherit;
}
#res-contenido .bar-name-item.vid {
  width: 11.356vw;
  height: inherit;
}
#res-contenido .bar-name-item h5 {
  letter-spacing: 0.0892857143em;
  font-size: 1.042vw;
  font-weight: 500;
  margin-bottom: 0;
}
#res-contenido .icon {
  width: 1.102vw;
  height: auto;
  margin-right: 0.99vw;
}
#res-contenido .tab__nav__items .tab__nav__item.active {
  background-color: #a7000b;
  color: white !important;
  background-image: none;
}
#res-contenido .tab__pagination {
  background: transparent !important;
}
#res-contenido .tab__nav__items .tab__nav__item:first-child {
  border-left: none;
}
#res-contenido .tab__nav__items .tab__nav__item:last-child {
  border-right: none;
}
#res-contenido .patro-u-n {
  width: 34.54vw;
  height: 6.094vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  transition: 0.5s;
}
#res-contenido .patro-u-n:hover {
  background-color: #ffffff87;
}
#res-contenido .patro-u-n p {
  font-size: 0.833vw;
  line-height: 0.833vw;
  margin-bottom: 0;
  color: white;
  transition: 0.5s;
}
#res-contenido .patro-u-n:hover p {
  color: black;
}
#res-contenido .patro-u-n img {
  height: auto;
  width: 5.26vw;
  filter: invert(1);
  margin-left: 0.625vw;
  transition: 0.5s;
}
#res-contenido .patro-u-n:hover img {
  filter: invert(0);
}
#res-contenido hr.tab__slider {
  display: none;
}
#res-contenido .post-cont {
  width: 66vw !important;
}
#res-contenido .post-text .post-cont {
  width: 66vw !important;
}
#res-contenido .post-text .post-cont {
  width: 53vw !important;
}
#res-contenido .date-post {
  color: #032a4b !important;
  background-image: url("./assets/reloj-blue.png") !important;
}
#res-contenido .post-text {
  padding: 4.74vw 3.333vw !important;
  border: 1px solid gainsboro !important;
  border-radius: 2vw !important;
  margin-top: 2.188vw !important;
  width: 60vw;
}
#res-contenido h1.titulo-post-text {
  font-weight: normal;
  font-size: 4.167vw !important;
  line-height: 110.49% !important;
  color: #032a4b !important;
  text-transform: uppercase;
  font-family: "Rajdhani";
}
#res-contenido .post-text h2 {
  margin: 3.5vw 0;
}
#res-contenido .widgets-cont-sb {
  padding: 12.51vw 7.188vw 1.51vw 3.094vw;
}
#res-contenido .add-widget .sponsor-add-dr {
  width: 24vw;
  height: auto;
}
#res-contenido .widgets-cont-sb .last-galleries-cont {
  width: 24vw;
}
#res-contenido .lineup {
  display: block;
}
#res-contenido .lineup-tab {
  display: none;
}
#res-contenido .rs-tab {
  padding: 6.302vw 1.927vw;
}
#res-contenido .stats-lineup-cont {
  border: 1px solid gainsboro;
  border-radius: 3vw;
  padding: 4.844vw 4.583vw;
}
#res-contenido .stlc-header {
  padding-bottom: 2.135vw;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 3.438vw;
}
#res-contenido .stlc-header h1 {
  font-size: 3.125vw;
  line-height: 110.49%;
  color: #032a4b;
  font-family: "Rajdhani";
  width: 23vw;
}
#res-contenido .rol-stat-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.031vw;
}
#res-contenido .rol-stat-cont h5 {
  font-size: 1.042vw;
  line-height: 164%;
  color: #032a4b;
  font-family: "Rajdhani";
  margin-bottom: 2.031vw;
}
#res-contenido .rol-stat {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#res-contenido .rol {
  width: 40.677vw;
  height: 1.302vw;
  display: flex;
}
#res-contenido .rol.local {
  background: #b70d1d;
  width: 50%;
  border-radius: 2vw 0 0 2vw;
}
#res-contenido .rol.visita {
  background: #032a4b;
  width: 50%;
  border-radius: 0 2vw 2vw 0;
}
#res-contenido .lineup {
  border: 1px solid gainsboro;
  border-radius: 2vw;
  width: 100%;
  margin-top: 6.76vw;
}
#res-contenido .teams {
  display: flex;
  border-bottom: 1px solid gainsboro;
  justify-content: space-around;
  padding: 2.132vw 0;
}
#res-contenido .team-cont {
  width: 16.796vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loc-visit-cont {
  display: flex;
  align-items: center;
}
#res-contenido .logo-team-lup {
  height: 4.836vw;
  width: auto;
}
#res-contenido .name-team-lup {
  font-size: 24px;
  line-height: 28px;
  color: #032a4b;
  font-family: "Rajdhani";
  width: 10.036vw;
}
#res-contenido .lineup-players {
  padding: 3.692vw 5.564vw;
  display: flex;
  justify-content: space-between;
}
#res-contenido .players-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  padding: 1.144vw 0;
  width: 17.628vw;
}
#res-contenido .number-player {
  font-size: 1.56vw;
  line-height: 155.5%;
  color: #032a4b;
  margin-bottom: 0;
  font-family: "Rajdhani";
}
#res-contenido img.player-footage {
  height: 4.992vw;
  width: auto;
}
#res-contenido .player-name {
  font-size: 1.144vw;
  line-height: 155.5%;
  color: #032a4b;
  font-family: "Rajdhani";
  width: 10.036vw;
}
#res-contenido .player-name-pos-cont {
  width: 10.036vw;
}
#res-contenido .result-gallery {
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 1.56vw;
  padding: 5.72vw 3.068vw;
}
#res-contenido .cont-gal-title {
  padding-bottom: 3.12vw;
  margin-bottom: 3.12vw;
  border-bottom: 1px solid gainsboro;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#res-contenido .result-gal-title {
  font-size: 1.823vw;
  line-height: 110.49%;
  color: #032a4b;
  font-family: "Rajdhani";
  text-transform: uppercase;
  width: 50%;
}
#res-contenido .gallery-link {
  width: 12.636vw;
  height: 6.408vw;
  margin-top: 0.52vw;
  margin-right: 0.5vw;
  cursor: pointer;
  /*display: flex;
    flex-direction: column;
    justify-content: flex-end;*/
}
#res-contenido .galleries-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.gallery-image {
  width: 12.636vw;
  height: 6.408vw;
}
#res-contenido .d-t-gal-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(180deg, transparent, #032a4b);
  width: 12.636vw;
  height: 6.408vw;
  position: absolute;
}
#res-contenido .date-g-link {
  font-size: 0.364vw;
  line-height: 0.624vw;
  letter-spacing: 0.085em;
  color: #ffffff;
  font-family: "Rajdhani";
  background-image: url("./assets/reloj-white.png");
  background-size: 0.624vw;
  background-repeat: no-repeat;
  padding-left: 1.092vw;
  padding: 0 1.404vw 0 2.404vw;
  background-position-x: 1.404vw;
  text-transform: uppercase;
}
#res-contenido .title-g-link {
  font-size: 0.676vw;
  line-height: 0.78vw;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Rajdhani";
  margin-bottom: 0.8vw;
  padding: 0 1.404vw;
}
#res-contenido .red-rect {
  background: #b70d1d;
  height: 0.26vw;
  width: 50%;
  transition: 0.5s;
}
#res-contenido .gallery-link:hover .red-rect {
  width: 100%;
}
#res-contenido .videos-gal-link {
  width: 12.636vw;
  height: 10.66vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.3vw;
  margin-bottom: 0.3vw;
  cursor: pointer;
}
#res-contenido .videos-gal-link:hover .red-rect {
  width: 100%;
}
#res-contenido .videos-gal-link .d-t-gal-link {
  height: 10.66vw;
}
#res-contenido .videos-gal-link .gallery-image {
  height: 10.66vw;
  object-fit: cover;
}
#res-contenido .play-button {
  width: 3.328vw;
  height: auto;
  margin-top: 3.468vw;
  position: absolute;
  margin-left: -1.5vw;
}
#res-contenido .post-text .autor-text {
  display: none;
}
#res-contenido .header-result-cont .patro-u-n {
  display: none;
}
#res-contenido .post-text .share-in {
  display: none;
}
#res-contenido h6.res {
  font-size: 1.146vw;
  line-height: 164%;
  color: #032a4b;
  font-family: "Rajdhani";
}
/*#res-contenido .tabs__content {
  overflow: auto;
}*/

.last-matches-widget .lresw-header {
  width: 100%;
}
.last-matches-widget .lresw-header .uno {
  text-align: center;
  padding: 1.771vw 0;
  background-color: #b70d1d;
  color: white;
  font-family: "Rajdhani";
  border-radius: 0.7vw 0.7vw 0 0;
}
.last-matches-widget .lresw-header .uno .t {
  font-size: 0.99vw !important;
  line-height: 0.99vw !important;
  margin-bottom: 0;
}
.last-matches-widget .lresw-header .uno .t span {
  font-family: "Rajdhani";
  /*color: #032a4b;*/
  color: white;
  cursor: default;
}
.last-matches-widget select#matchday-lresw {
  width: 100%;
  border: 0;
  background-color: #032a4b;
  color: white;
  font-family: "Rajdhani";
  font-size: 0.729vw;
  line-height: 261.99%;
  height: 3.542vw;
  padding: 0 2.083vw;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./assets/arrow-select.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 22vw;
  background-size: 0.6vw;
}
.last-matches-widget .match-result-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1vw 0;
}
.last-matches-widget .match-result-cont .uno {
  display: flex;
  align-items: center;
  width: 19.896vw;
  justify-content: space-between;
}
.last-matches-widget .lresw-resultados-cont {
  padding: 1.927vw 1.615vw;
  border: 1px solid gainsboro;
  border-radius: 0 0 0.5vw 0.5vw;
  margin-bottom: 2vw;
}
.last-matches-widget .team {
  margin-bottom: 0;
  width: 4.479vw;
  font-weight: bold;
  font-size: 0.625vw;
  line-height: 0.729vw;
  color: #032a4b;
  font-family: "Rajdhani";
}
.last-matches-widget img.logo-team {
  height: 2.344vw;
  width: auto;
}
.last-matches-widget .resultado-team {
  width: 2.083vw;
  height: 2.083vw;
  background: #b70d1d;
  border-radius: 3vw;
  color: white;
  font-family: "Rajdhani";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1vw;
}
.last-matches-widget h6.ht-ft2 {
  margin: 0 !important;
  font-size: 0.625vw !important;
  line-height: 0.729vw !important;
  color: #b70d1d !important;
}
/**FIN RESULTADO */

/** suscripciones */
.group-form-suscr {
  display: flex;
}

#inputEhome {
  border-radius: 0vw;
  background-color: var(--color-three);
  font-size: 0.833vw;
  height: 4.322916666666667vw;
  font-family: "Rajdhani";
  line-height: 1.1458333333333333vw;
  color: #ffffff;
  width: 26.406vw;
  margin-left: 0;
  padding: 1.9270833333333335vw;
}

#inputEhome::placeholder {
  color: #ffffff;
  font-size: 0.833vw;
  font-family: "Rajdhani";
  font-style: normal;
  line-height: 1.1458333333333333vw;
}

#inputEhome:focus {
  border-color: var(--color-1);
  outline: 0;
  box-shadow: none;
}

#btn-suscr {
  width: 11.51vw;
  border-radius: 0vw;
  height: 4.322916666666667vw;
  background-color: #968220;
  color: var(--color-4);
  font-family: "Rajdhani";
  font-style: normal;
  /* border: 0.052083333333333336VW solid #968220;*/
  font-size: 0.833vw;
  line-height: 1.1458333333333333vw;
  transition: 0.5s;
}
#btn-suscr:hover {
  background-color: #6d5b00;
}

.message-subscriptor {
  background-color: var(--color-three);
  text-align: center;
  width: 33vw;
  height: 3vw;
  padding: 0.5vw;
  color: var(--color-two);
  font-size: 1.1vw;
  margin-top: 1vw;
  /*margin-left: 61.2vw;*/
}

.message-subscriptor span {
  margin-left: 1vw;
  cursor: pointer;
}

.message-subscriptor span:hover {
  color: var(--color-four);
  background-color: var(--color-two);
  padding: 0vw 0.2vw;
  border-radius: 0.1vw;
}

/**POST */
#blog-post .blog-post-cont {
  background-color: #f0f0f0;
  padding: 0.781vw 0;
  display: flex;
  justify-content: space-between;
}
#blog-post .post-cont {
  width: 74.74vw;
}
#blog-post .header-post-cont {
  height: 49.74vw;
  width: 100%;
}
.titulo-header-cont.pc {
  display: flex;
}
.titulo-header-cont.mo {
  display: none;
}
.titulo-header-cont {
  position: absolute;
  z-index: 99;
  height: 49.74vw;
  padding: 2.865vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 74.74vw;
  background: linear-gradient(to bottom, transparent, #0f2957);
}
.header-post-img {
  width: 100%;
  height: 49.74vw;
  object-fit: cover;
  object-position: top;
  /*border-radius: 2vw 2vw 0 0;*/
}
#blog-post .date-post {
  font-family: "Rajdhani" !important;
  font-size: 0.781vw;
  line-height: 0.729vw;
  color: #ffffff;
  margin-bottom: 1.615vw;
  background-image: url("./assets/reloj-white.png");
  background-size: 0.7vw;
  background-repeat: no-repeat;
  padding-left: 1vw;
  position: relative;
  background-color: transparent;
  width: fit-content;
  text-transform: uppercase;
}
#blog-post .titulo-post {
  font-family: "Rajdhani" !important;
  width: 50.448vw;
  text-transform: uppercase;
  font-size: 4.427vw;
  line-height: 110.49%;
  color: #ffffff;
  position: relative;
}
#blog-post .post-text-cont {
  background-color: white;
  padding: 1.458vw 3.281vw;
}
#blog-post .authors-cont {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 1.302vw;
}
#blog-post .autor-text {
  font-size: 0.521vw;
  line-height: 0.573vw;
  letter-spacing: 0.045em;
  text-decoration-line: underline;
  color: #b70d1d;
  text-transform: uppercase;
  font-family: "Rajdhani";
}
#blog-post .sm-share {
  width: auto;
  height: 0.729vw;
}
#blog-post .share-in {
  width: 12.417vw;
  padding: 0.729vw 0;
  display: flex;
  border: 1px solid #032a4b;
  justify-content: space-around;
  align-items: center;
  border-radius: 2vw;
}
#blog-post .sm-share-in {
  width: 4.552vw;
  display: flex;
  justify-content: space-between;
}
#blog-post .sm-share-in a {
  line-height: 0;
}
#blog-post .sm-share-in.mobile,
#res-contenido .sm-share-in.mobile {
  display: none;
}
#blog-post .share-in p {
  font-size: 0.521vw;
  line-height: 0.573vw;
  letter-spacing: 0.045em;
  color: #032a4b;
  margin-bottom: 0;
  font-family: "Rajdhani";
}
#blog-post .post-text {
  padding: 4.74vw 3.333vw;
  display: flex;
  flex-direction: column;
}
#blog-post .intro-post {
  font-style: normal;
  font-weight: bold;
  font-size: 1.458vw;
  line-height: 164.5%;
  color: #032a4b;
  width: 45.99vw;
  padding-bottom: 5.885vw;
}
#blog-post .post-text p,
#blog-post .post-text li {
  font-style: normal;
  font-weight: normal;
  font-size: 0.99vw;
  line-height: 171.5%;
  color: #3b3b3b;
  display: initial;
  padding-right: 0.3vw;
  margin-bottom: 0;
}
#blog-post .post-text p {
  margin-bottom: 0;
}
#blog-post .post-text ul {
  display: flex;
  flex-direction: column;
}
#blog-post .post-text p strong {
  color: #000000;
}
#blog-post .post-text p strong em {
  font-style: normal;
  font-weight: bold;
}
#blog-post .post-text h2 {
  font-style: normal;
  font-size: 1.042vw;
  line-height: 164.5%;
  color: #032a4b;
  width: 42.99vw;
  font-family: "Rajdhani";
}
#blog-post .post-text h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.99vw;
  line-height: 204.5%;
  color: #3b3b3b;
}
#blog-post .post-text img {
  height: 100%;
  width: 100%;
  margin: 3vw 0;
}
#blog-post iframe.ql-video {
  height: 30vw;
  width: 100%;
}
#blog-post a.auth-date-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 0.99vw;
  line-height: 204.5%;
  color: #b70d1d;
}
#blog-post .widgets-cont {
  width: 23.063vw;
  margin-right: 1vw;
}
#blog-post .lnc-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #b70d1d;
  color: white;
  align-items: flex-end;
  border-radius: 1vw 1vw 0 0;
  padding: 1.094vw 1.771vw;
  background-image: url("./assets/rec-blue.png");
  background-repeat: no-repeat;
  background-size: 4.74vw 0.4vw;
  background-position-x: left;
  background-position-y: bottom;
  transition: 0.5s;
}
#blog-post .last-news-cont:hover .lnc-header {
  background-size: 24.09vw 0.4vw;
}
#blog-post .lnc-header .t {
  font-weight: bold;
  font-size: 0.99vw;
  line-height: 1.146vw !important;
  text-transform: uppercase;
  font-family: "Rajdhani";
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  cursor: default;
}
#blog-post .lnc-header a {
  color: white;
  font-family: "Rajdhani";
}
#blog-post .lnc-header a:hover {
  color: white !important;
  font-family: "Rajdhani";
}
#blog-post .lnc-header .t span {
  color: #01203a;
  font-family: "Rajdhani";
}
#blog-post .ugc-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #01203a;
  color: white;
  align-items: flex-end;
  border-radius: 1vw 1vw 0 0;
  padding: 1.094vw 1.771vw;
  background-image: url("./assets/rec-red.png");
  background-repeat: no-repeat;
  background-size: 4.74vw 0.4vw;
  background-position-x: left;
  background-position-y: bottom;
  transition: 0.5s;
}
#blog-post .last-galleries-cont:hover .ugc-header {
  background-size: 24.09vw 0.4vw;
}
#blog-post .ugc-header .t {
  font-weight: bold;
  font-size: 0.99vw;
  line-height: 1.146vw !important;
  text-transform: uppercase;
  font-family: "Rajdhani";
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  cursor: default;
}
#blog-post .ugc-header a {
  color: white !important;
  font-family: "Rajdhani";
  font-size: 0.573vw;
}
#blog-post .ugc-header a:hover {
  color: white !important;
}
#blog-post .ugc-header .t span {
  /*color: #b70d1d;*/
  color: #ffffff;
  font-family: "Rajdhani";
}
#blog-post .sponsor-add-dr {
  width: 100%;
  height: auto;
  margin: 1.302vw 0;
}

/**widget news */
#blog-post .news-widget {
  background: white;
  margin: 1.51vw 0;
  border-radius: 1vw 1vw 0 0;
  padding: 0.885vw;
  transition: 0.5s;
}
#blog-post .news-widget:hover {
  filter: drop-shadow(2px 5px 5px rgb(102, 102, 102));
}
#blog-post .news-img {
  width: 100%;
  height: 9.635vw;
  object-fit: cover;
  object-position: top;
  transition: 0.5s;
}
#blog-post .news-widget:hover .news-img {
  object-position: center;
}
#blog-post .day-sm {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1.198vw 0;
  padding: 0 0.8vw;
  font-family: "Rajdhani";
}
#blog-post .sm-cont-n-w p {
  font-size: 0.469vw;
  line-height: 0.9vw;
  margin: 0;
}
#blog-post .sm-cont-n-w {
  width: 7.708vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#blog-post .sm-cont-n-w a {
  line-height: 0;
}
#blog-post .day-ago {
  font-style: normal;
  font-weight: bold;
  font-size: 0.521vw;
  line-height: 0.573vw;
  color: #b70d1d;
  text-transform: uppercase;
}
#blog-post .sm.cont-n-w p {
  font-weight: bold;
  font-size: 0.521vw !important;
  line-height: 0.521vw;
  letter-spacing: 0.045em;
  color: #a0a0a0;
  margin-bottom: 0 !important;
}
#blog-post .sm-icon-w {
  width: auto;
  height: 0.608vw;
}
#blog-post .titulo-n-w {
  font-style: normal;
  font-size: 0.99vw;
  line-height: 1.146vw;
  text-transform: uppercase;
  color: #032a4b;
  width: 19.167vw;
  font-family: "Rajdhani";
  cursor: pointer;
}
#blog-post br {
  margin: 2vw auto !important;
  display: block !important;
  content: " " !important;
  line-height: 2vw !important;
}
/**FIN POST */

/**BLOG */
#blog {
  background: #f0f0f0;
}
/**FIN BLOG */

/**VIDEO CONT*/

#video-c {
  background: #f0f0f0;
}
#video-c .video-sec-cont {
  width: 100%;
  margin: auto;
  border-radius: 1vw 1vw 0 0;
}
#video-c .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0;
}
#video-c .videos-cont {
  background: white;
  position: relative;
  padding: 5.833vw 7.792vw;
}
#video-c .l-cont {
  width: 19.635vw;
  height: 18.229vw;
  padding-bottom: 1vw;
  font-family: "Rajdhani";
  margin-bottom: 1vw;
  margin: 0 0.5vw 1vw;
  cursor: pointer;
}
#video-c .l-div {
  width: 19.635vw;
  height: 18.229vw;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}
#video-c .red-rec {
  position: absolute;
  z-index: 99;
  width: 6.146vw;
  height: 0.5vw;
  transition: 0.2s;
}
#video-c .l-cont:hover .red-rec {
  width: 19.768vw;
  height: 0.5vw;
}
#video-c .titulo-vim {
  padding: 0 1.823vw;
  position: absolute;
  margin-top: -10vw;
  width: 19.7vw;
  background: linear-gradient(360deg, #01203a, transparent);
  height: 10vw;
  /*border-radius: 0 0 2vw 0vw;*/
}
.video-cont .t-usl-cont div {
  cursor: pointer;
}
.video-cont .l-cont .titulo-vim .titulo {
  cursor: pointer;
} /*#video-c .l-cont img.l-m-content {
    border-radius: 2vw 2vw 2vw 0;
}*/

img.play-video-icon {
  position: absolute;
  height: 19vw;
  width: 19vw;
  margin-left: 0;
  padding: 7vw;
  z-index: 99;
  display: flex;
  transition: 0.25s;
  opacity: 0.4;
} /*.a-signin:hoverimg.play-video-icon:hover {
    padding: 6.5vw;
}*/
/**FIN VIDEO CONT */

/**slide videos */
.video-cont .video-usl-cont {
  display: flex;
  background: black;
  height: 5.625vw;
  cursor: pointer;
  width: 33.33%;
  transition: 0.25s;
}
.video-cont .video-header-sl {
  width: 100%;
  height: 27.604vw;
}
.video-cont .vid-usl-cont {
  height: 5.625vw;
  width: 10.938vw;
  object-fit: cover;
  object-position: top;
  transition: 0.25s;
}
.video-cont .video-usl-cont:hover {
  background: #8f000d;
}
.video-cont .video-usl-cont:hover .vid-usl-cont {
  object-position: center;
  transform: scale(1.2);
  position: relative;
  z-index: 99;
  margin-top: -2.1vw;
  height: 7vw;
}
.video-cont .slide-dest-cont {
  height: 5.625vw;
  width: 100%;
  display: flex;
}
.video-cont .t-usl-cont {
  padding: 1.406vw;
  display: flex;
  align-items: center;
}
.video-cont .plug-play:hover {
  transform: scale(1.2);
}
.video-cont .plug-play {
  width: 1.823vw;
  height: auto;
  transition: 0.5s;
}
.video-cont .text-tuslc p {
  font-family: "Rajdhani";
}
.video-cont .text-tuslc {
  font-size: 0.729vw;
  line-height: 0.833vw;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Rajdhani";
  padding-left: 0.99vw;
}
.video-cont .text-tuslc h5 {
  font-size: 0.729vw;
}
.video-cont .data-video-sl {
  text-transform: uppercase;
  font-size: 0.625vw;
  line-height: 0.729vw;
  color: #ffffff;
  font-family: "Rajdhani";
  margin-bottom: 1.615vw;
}
.video-cont div .titulo-vid-sl {
  font-family: "Rajdhani";
  font-size: 3.125vw;
  line-height: 110.49%;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}
.video-cont div .titulo-vid-sl:hover {
  text-decoration: none;
  filter: drop-shadow(2px 4px 6px black);
}
.video-cont .titulo-video-cont {
  position: absolute;
  left: 3.542vw;
  width: 50%;
  height: 26.458vw;
  display: flex;
  align-items: flex-end;
  padding: 2.448vw 3.229vw;
}
#video-c .l-div:hover {
  background-color: white;
}
.video-cont .gallery-frame {
  width: 20vw;
  background: transparent;
  height: 10vw;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
}
.video-gal-cont iframe {
  width: 47vw !important;
  height: 30vw !important;
}

.filosofia {
  background-color: #f0f0f0;
  background-image: url("./assets/fondiu-filo.png"),
    url("./assets/filopoint.png");
  background-size: 100%, 1.432vw;
  background-repeat: no-repeat;
  padding-bottom: 4.01vw;
  background-position-x: 0, 0;
  background-position-y: 87.219vw, 55vw;
  position: relative;
}
.filosofia .filo-header {
  width: 100%;
  height: 32.313vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /*background-image: url("./assets/filo-header.png");
  background-size: 100%;*/
  background-repeat: no-repeat;
  padding: 4.31vw 27.135vw 0;
  transition: 0.5s;
  position: relative;
}
.filosofia .h-tra {
  -webkit-text-stroke: 2px white;
  color: transparent !important;
}
.filosofia .filo-header h1 {
  font-size: 9.375vw;
  line-height: 6.375vw;
  color: rgba(183, 13, 29, 1);
  font-family: "Rajdhani";
  width: 100%;
  background-position: center;
  cursor: default;
  margin-bottom: 1vw;
}
.filosofia .filo-header h6 {
  font-size: 0.938vw;
  line-height: 110.49%;
  letter-spacing: 0.31em;
  color: #ffffff;
  font-family: "Rajdhani";
  width: 100%;
  cursor: default;
  padding-left: 0.5vw;
  text-align: center;
  padding-top: 1vw;
}
.filosofia .filo-cont {
  margin: 0 4.01vw;
  /*background: white;*/
}
.filosofia .filo-cont .sec {
  display: flex;
}
.filosofia .filo-cont .sec.due {
  height: 35.625vw;
}
.filosofia img.filo-img {
  width: 50%;
  height: auto;
}
.filosofia .sec-texto {
  padding: 11.875vw 9.844vw;
  width: 50%;
  /*background-image: url('./assets/cuerno.png');*/
  background-repeat: no-repeat;
  background-size: 43.17vw;
  background-position-y: -23.75vw;
  background-position-x: 7.448vw;
}
.filosofia .sec-texto.mo {
  display: none;
}
.filosofia .filo-titulo {
  font-size: 1.823vw;
  line-height: 164.5%;
  color: #b70d1d;
  font-family: "Rajdhani";
  width: fit-content;
  border-bottom: 0.365vw solid #b70d1d;
  padding-bottom: 2.302vw;
  margin-bottom: 2.865vw;
}
.filosofia .filo-parrafo {
  font-weight: normal;
  font-size: 0.833vw;
  line-height: 204.5%;
  color: #686868;
}
.filosofia .sec.tre .sec-texto {
  background: #032a4b;
}
.filosofia .filo-list {
  display: flex;
  margin-bottom: 10%;
}
.filosofia .filo-list .filo-titulo {
  font-size: 4.167vw;
  line-height: 4.167vw;
  color: #9f111f;
  border: none;
  margin: 0;
  padding: 0;
  margin-right: 1.302vw;
}
.filosofia .filo-list .filo-parrafo {
  color: #ffffff;
}
.filosofia .sec.tre .sec-texto {
  padding: 7.396vw 20.229vw 7.396vw 6.354vw;
}
.filosofia .sec.tre {
  height: 56.771vw;
}
.filosofia .sec.quattro {
  height: 42.604vw;
}
.filosofia img.head-obje {
  display: block;
  height: 53.906vw;
  width: auto;
  position: absolute;
  left: 34.375vw;
  margin-top: -8.125vw;
}
.filosofia img.head-obje-m {
  display: none;
}
.filosofia .sec.quattro .sec-texto {
  padding: 8.125vw 9.844vw;
  background-image: url("./assets/filopoint2.png");
  background-size: 2.865vw;
  background-position-x: 5vw;
  background-position-y: 17vw;
  background-repeat: no-repeat;
}
.filosofia img.redpoints {
  position: absolute;
  margin-left: 85vw;
  margin-top: 35vw;
  width: 2.865vw;
}

#calendario .calendar-cont {
  /*background-image: url("./assets/calendar-header.png");
  background-size: 100%;*/
  background-repeat: no-repeat;
  position: relative;
}
#calendario img.cal-t-h {
  position: absolute;
  width: 40.204vw;
  margin-top: -4.7vw;
  margin-left: 0.3vw;
}
#calendario .calendar-header {
  width: 100%;
  padding: 9.688vw 31.042vw 6.198vw;
  position: relative;
  z-index: 100;
}
#calendario .calendar-header h1 {
  font-size: 6.771vw;
  line-height: 99.99%;
  font-family: "Rajdhani";
  color: white;
}
#calendario .calendar-header h6 {
  font-size: 1.354vw;
  line-height: 110.49%;
  letter-spacing: 0.485em;
  color: #ffffff;
  text-align: right;
  font-family: "Rajdhani";
}
#calendario .tabs {
  background: transparent !important;
  position: relative;
  z-index: 100;
}
#calendario .tab__pagination {
  background: transparent !important;
  padding-bottom: 3.646vw;
}
#calendario .tab__nav__items {
  justify-content: center;
}
#calendario .tab__nav__items .tab__nav__item {
  padding: 0;
}
#calendario .tab__nav__items .tab__nav__item .team-type-header h4 {
  font-size: 0.833vw;
  line-height: 0.938vw;
  color: #ffffff;
  font-family: "Rajdhani";
  margin-bottom: 0;
}
#calendario .tab__nav__items .tab__nav__item .team-type-header {
  max-width: 23.438vw;
  width: 23.438vw;
  height: 3.906vw;
  border: 1px solid white;
  padding: 0;
  display: flex;
  justify-content: center;
  transition: 0.25s;
  padding-right: 0;
  background-image: url("./assets/arrow2.png");
  background-size: 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 0;
}
#calendario .tab__nav__items .tab__nav__item .team-type-header:hover {
  color: white !important;
  display: flex;
  padding-right: 5vw;
  background-size: 2.865vw;
  background-position-x: 14.115vw;
}
#calendario .tab__nav__items .tab__nav__item.active .team-type-header {
  color: white !important;
  background: #b70d1d;
  padding-right: 5vw;
  background-size: 2.865vw !important;
  background-position-x: 14.115vw !important;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  background-image: url("./assets/arrow2.png") !important;
}
#calendario .calendar-first-team {
  padding: 2.656vw 6.302vw;
}
#calendario .calendar-ft-header h1 {
  font-size: 2.083vw;
  line-height: 110.49%;
  color: #032a4b;
  font-family: "Rajdhani";
  padding-bottom: 3vw;
}
#calendario .calendar-ft-header {
  width: 86.615vw;
  border-bottom: 1px solid gainsboro;
  background-image: url("./assets/red-rec.png");
  background-repeat: no-repeat;
  background-size: 20.99vw 0.833vw;
  background-position-y: bottom;
}
#calendario .matches-month {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /*  padding-top: 6.615vw;*/
}
#calendario .match-ma-m-mo {
  display: none;
}
#calendario .match-ma-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.813vw 0;
  border-bottom: 1px solid gainsboro;
  cursor: default;
  transition: 0.25s;
}
#calendario .match-ma-m:hover {
  transform: scale(1.02);
}
#calendario .ma-m-header h3 {
  font-size: 1.667vw;
  line-height: 110.49%;
  color: #032a4b;
  font-family: "Rajdhani";
}
#calendario .ma-m-header {
  border-bottom: 1px solid gainsboro;
  background-image: url("./assets/redarrow.png");
  background-repeat: no-repeat;
  background-size: 4.844vw;
  background-position-y: 0.6vw;
  padding-left: 6vw;
  padding-bottom: 1.5vw;
  margin-bottom: 1.688vw;
  display: none;
}
#calendario .data-match h4 {
  font-size: 1.458vw;
  line-height: 138.5%;
  color: #032a4b;
  font-family: "Rajdhani";
  margin-bottom: 0;
  text-transform: capitalize;
}
#calendario .data-match h6 {
  font-size: 1.158vw;
  line-height: 138.5%;
  color: #b70d1d;
  font-family: "Rajdhani";
}
#calendario .logo-journey-cont {
  display: flex;
  align-items: center;
  width: 24vw;
}
#calendario .tou-logo {
  width: 8.625vw;
  height: auto;
}
#calendario .journey-stadium-cont h4 {
  font-size: 0.938vw;
  line-height: 167.5%;
  color: #032a4b;
  font-family: "Rajdhani";
  margin-bottom: 0;
}
#calendario .journey-stadium-cont h6 {
  font-size: 0.938vw;
  line-height: 167.5%;
  color: #000000;
  font-family: "Rajdhani";
  margin-bottom: 0;
}
#calendario .journey-stadium-cont {
  padding-left: 2.031vw;
  margin-left: 2.031vw;
  border-left: 1px solid gainsboro;
}
#calendario .match-m-cont {
  display: flex;
  align-items: center;
  width: 45vw;
  justify-content: space-between;
}
#calendario .cim-team {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 12vw;
  justify-content: space-between;
}
#calendario .cim-team h6 {
  font-size: 0.885vw;
  line-height: 1.042vw;
  color: #032a4b;
  font-family: "Rajdhani";
  width: 8vw;
  text-transform: uppercase;
  margin: 0 0.5vw;
}
#calendario .logo-cont {
  width: auto;
  height: 3.985vw;
  transition: 0.5s;
}
#calendario .logo-cont:hover {
  filter: drop-shadow(2px 2px 2px black);
}
#calendario .cim-team.vis h6 {
  margin-left: 0.7vw;
}
#calendario .versus {
  font-size: 0.99vw;
  line-height: 1.146vw;
  color: #b70d1d;
  font-family: "Rajdhani";
  margin: 0 1.563vw;
}
#calendario .result-versus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#calendario .match-result-vs {
  margin-bottom: 0;
  font-size: 1.4vw;
  font-family: "Rajdhani";
  color: #b70d1d;
  margin: auto 1vw;
}
#calendario .l-button {
  height: 3.906vw;
  width: 14.583vw;
  padding: 1.823vw 3.4vw;
}
#calendario .l-button.bol {
  padding: 1.823vw 3.1vw;
}
#calendario .l-button:hover,
#calendario .l-button.bol:hover {
  padding: 1.823vw 1.265vw;
  background-position-x: 10.521vw;
  background-size: 3vw;
}
#calendario .team-versus {
  display: flex;
}
#calendario .tab__nav__items .tab__nav__item .team-type-header.activa h4 {
  color: white !important;
}
#calendario .tab__nav__items .tab__nav__item:hover .team-type-header.activa {
  background-image: url("./assets/arrow2.png") !important;
}
#calendario .data-match {
  width: 9vw;
}

#calendario.cla .calendar-cont {
  background-image: none;
  background-size: 100%;
  background-repeat: no-repeat;
}
#calendario.cla .clas-body {
  padding: 5.729vw;
}
#calendario.cla .titulo-u-noticias h4 {
  font-family: "Rajdhani";
  text-transform: uppercase;
  color: #01203a;
  padding-left: 2vw;
}
#calendario.cla .tab__nav__items .tab__nav__item {
  width: auto;
}
#calendario.cla .tab__nav__items .tab__nav__item .team-type-header {
  max-width: 15.781vw;
  width: 15.781vw;
  height: 3.906vw;
  border: 1px solid gainsboro;
  padding: 0;
  display: flex;
  justify-content: center;
  transition: 0.25s;
  padding-right: 0;
  background-size: 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 0;
  background-image: url("./assets/arrow2.png") !important;
}
#calendario.cla .tab__nav__items .tab__nav__item .team-type-header:hover {
  color: white !important;
  display: flex;
  padding-right: 5vw;
  background-size: 2.865vw;
  background-position-x: 10.115vw;
  background-image: url("./assets/arrow3.png") !important;
}
#calendario.cla
  .tab__nav__items
  .tab__nav__item.active
  .team-type-header:hover {
  background-image: url("./assets/arrow2.png") !important;
}
#calendario.cla .tab__nav__items .tab__nav__item.active .team-type-header {
  color: white !important;
  background: #b70d1d;
  padding-right: 5vw;
  background-size: 2.865vw !important;
  background-position-x: 10.115vw !important;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  background-image: url("./assets/arrow2.png") !important;
}
#calendario.cla .tab__nav__items .tab__nav__item .team-type-header.activa h4 {
  color: white !important;
}
#calendario.cla
  .tab__nav__items
  .tab__nav__item:hover
  .team-type-header.activa {
  background-image: url("./assets/arrow2.png") !important;
}
#calendario.cla .tab__nav__items .tab__nav__item.active .team-type-header h4 {
  color: white !important;
}
#calendario.cla .tab__nav__items .tab__nav__item .team-type-header h4 {
  color: gray;
  font-family: "Rajdhani";
}
#calendario.cla .tab__pagination {
  background: transparent !important;
  padding: 3.646vw 12vw 3.75vw 22vw;
  border-bottom: 1px solid;
}
#calendario.cla .clasificacion-sub-header {
  width: 88.052vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding-top: 3.75vw;
  align-items: center;
}
#calendario.cla .liga-logo-mx {
  /* width: 21.198vw;
  height: auto;*/
  width: auto;
  height: 4vw;
  margin: auto;
}
#calendario.cla .logo-container {
  width: 25vw;
  height: auto;
  display: flex;
}
#calendario.cla .temp-sem-liga {
  height: 4vw;
}
#calendario.cla .temp-sem-liga h4 {
  font-size: 1.042vw !important;
  font-family: "Rajdhani";
  color: #032a4b;
}
#calendario.cla .temp-sem-liga h5 {
  font-size: 0.781vw !important;
  font-family: "Rajdhani";
}
#calendario.cla .tabla-liga {
  display: flex;
  flex-direction: column;
}
#calendario.cla .tabla-header {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#calendario.cla .tabla-equipos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
#calendario.cla .tabla-equipos .tb.eq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#calendario.cla .dots-forma {
  display: flex;
}
#calendario.cla .tb.pos {
  width: 4.844vw;
  height: 4.323vw;
}
#calendario.cla th,
#calendario.cla td {
  text-align: center;
}
#calendario.cla .tabla-liga th {
  font-weight: 500;
  font-size: 0.885vw;
  line-height: 1.042vw;
  color: #032a4b;
  font-family: "Rajdhani";
  width: 6vw;
}
#calendario.cla .tabla-liga tr {
  height: 4.323vw;
}
#calendario.cla .tabla-liga td.position-tb {
  background: #032a4b;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  color: white;
  font-family: "Rajdhani";
  width: 4.844vw;
  justify-content: center;
}
#calendario.cla .tabla-liga td {
  font-family: "Rajdhani";
  font-size: 0.885vw;
  width: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
#calendario.cla .tabla-liga td.name-club-tb {
  text-transform: uppercase;
  font-family: "Rajdhani";
  color: #032a4b;
  text-align: left;
  padding-left: 5.521vw;
  width: 33.865vw;
  padding-right: 5vw;
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: flex-start;
}
#calendario.cla .tabla-liga td.name-club-tb p {
  margin: 0;
}
#calendario.cla .tabla-liga td.pts-tb {
  font-family: "Rajdhani";
}
#calendario.cla .tabla-liga tr.elements {
  display: flex;
  flex-direction: row;
  width: 97vw !important;
}
#calendario.cla .tabla-liga tr.titleelements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 87vw;
}
#calendario.cla .tabla-liga th.teamth {
  width: 32vw;
}

#calendario.pla .calendar-header h1 {
  font-size: 3.385vw;
  font-family: "Rajdhani";
  text-align: center;
}
#calendario.pla .calendar-cont {
  /*background-image: url("./assets/header-players.png");
  background-size: 100%;*/
  background-repeat: no-repeat;
}
#calendario.pla .pl-header {
  font-size: 2.865vw;
  line-height: 2.065vw;
  color: var(--color-2);
  font-family: "Rajdhani";
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  cursor: default;
  padding-left: 1.1vw;
  border-left: 3vw solid #032a4b;
  transition: 0.25s;
  /* background-image: url('./assets/dots.png');
    background-repeat: no-repeat;
    background-size: 4.688vw 1.302vw;
    background-position-y: center;
    background-position-x: right;*/
  margin: auto;
}
#calendario.pla .pl-header:hover {
  background: #032a4b;
  border-left: 3vw solid var(--color-2);
  line-height: 2.35vw;
  color: white;
  /* padding-right: 0vw;*/
}
#calendario.pla .pl-header:hover img.dots-ti {
  filter: invert(1);
}
#calendario.pla img.dots-ti {
  height: 1.865vw;
  width: auto;
  padding-left: 1.1vw;
  transition: 0.25s;
}
#calendario.pla .tab-item {
  background-position-x: right;
  background-image: url("./assets/le-fonde.png");
  width: 100%;
}
#calendario.pla .first-squad {
  margin: 0 0 5.417vw;
  width: 100%;
}
#calendario.pla .players-pos {
  padding: 5.417vw 9.896vw 0;
}
.players-pos.defensas,
.players-pos.delanteros {
  background: #f7f7f7;
}
#calendario.pla .cont-players {
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
}
#calendario.pla .squad-player {
  width: 23vw;
  height: 28vw;
  display: flex;
  align-items: flex-end;
  margin: 3vw 0 1.5vw;
  background: #032a4b;
  transition: 0.5s;
  border: 1vw solid white;
}
#calendario.pla .player-img {
  width: 21vw;
  height: 25.99vw;
  position: absolute;
  transition: 0.5s;
  margin-top: -26vw;
}
#calendario.pla .data-player {
  position: relative;
  z-index: 99;
  color: white;
  display: flex;
  align-items: center;
  border-top: 1px solid white;
  height: auto;
  background: #0e1013a1;
  width: 21vw;
}
#calendario.pla .name-cont {
  border-bottom: 1px solid white;
  font-size: 0.938vw;
  line-height: 110.49%;
  color: white;
  font-family: "Rajdhani";
  width: 15.302vw;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 2.396vw;
  padding: 0 2vw;
}
#calendario.pla .player-number {
  font-size: 2.604vw;
  line-height: 2vw;
  font-family: "Rajdhani";
  padding: 0.9vw 0.99vw;
  border-right: 1px solid white;
  width: 5.698vw;
  display: flex;
  justify-content: center;
}
#calendario.pla .country-cont {
  font-size: 0.521vw;
  display: flex;
  justify-content: center;
  letter-spacing: 0.3vw;
  align-items: center;
  height: 1.396vw;
  font-family: "Rajdhani";
  text-transform: uppercase;
}
#calendario.pla .name-country-cont.ct {
  width: 100%;
}
#calendario.pla .name-country-cont.ct .name-cont {
  width: 100%;
}
.players-pos.defensas .squad-player,
.players-pos.delanteros .squad-player {
  border: 1vw solid #f7f7f7 !important;
}
#calendario.pla .tab__nav__items .tab__nav__item .team-type-header.activa h4 {
  color: white !important;
}
#calendario.pla
  .tab__nav__items
  .tab__nav__item:hover
  .team-type-header.activa {
  background-image: url("./assets/arrow2.png") !important;
  padding: 0 !important;
}
/*
#calendario.pla .squad-player:hover img.player-img {
    filter: grayscale(1);
}+*/
#calendario.pla .squad-player:hover {
  background: var(--color-2);
  border: 1vw solid white;
  filter: drop-shadow(6px 7px 7px gainsboro);
}
#calendario.pla .squad-player a {
  color: transparent;
  pointer-events: none;
}
#calendario.pla .squad-player a.ignore {
  color: transparent;
  pointer-events:all;
}
/*#calendario div#primer-equipo {
    display: block!important;
}*/
/**slider tienda */

#jugador-profile .vueperslides__parallax-wrapper {
  padding-bottom: 0 !important;
  height: 37vw !important;
}

#Tienda #storeSection .vueperslides__arrows.vueperslides__arrows--outside {
  width: 100%;
  margin-left: 0;
}
#Tienda #storeSection .header-slider {
  height: 5.5vw;
  background: #b70d1d00;
}
#Tienda #storeSection .vueperslides__arrows.vueperslides__arrows--outside {
  width: 100%;
  margin-left: 0;
  top: 9vw;
  z-index: 999;
}
#Tienda #storeSection button.vueperslides__arrow.vueperslides__arrow--prev {
  background-image: url("./assets/arrowleft14.png");
  transform: rotate(180deg);
}
#Tienda #storeSection button.vueperslides__arrow.vueperslides__arrow--next {
  background-image: url("./assets/arrowleft14.png");
  transform: rotate(0deg);
}
#Tienda
  #storeSection
  button.vueperslides__arrow.vueperslides__arrow--prev:hover {
  background-image: url("./assets/arrowrigth14.png") !important;
  transform: rotate(180deg);
}
#Tienda
  #storeSection
  button.vueperslides__arrow.vueperslides__arrow--next:hover {
  background-image: url("./assets/arrowrigth14.png") !important;
  transform: rotate(0deg);
}

/****VIDEO MODAL */
.modal2-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 66%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal2 {
  background: #ffffff;
  border-radius: none;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 82%;
  height: 72vh;
}

.modal2 .ql-video,
.modal2 iframe {
  width: 100%;
  height: 55vh;
}
.modal2-header,
.modal2-footer {
  padding: 0;
  display: flex;
}

.modal2-header {
  position: relative;
  /*border-bottom: 1px solid #eeeeee;*/
}
.modal2-header h1 {
  color: #ffffff;
  justify-content: space-between;
  font-family: "Rajdhani";
  font-size: 2vw;
  line-height: 1vw;
  background: #b70d1d;
  height: 5vw;
  border-radius: 0 0 1vw 0;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0 1vw;
}

.modal2-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal2-body {
  position: relative;
  height: 61vh;
  padding: 1vw 2vw;
}

.modal2-body-gal {
  display: flex;
  padding: 2.5vw 2vw;
  flex-wrap: wrap;
}

.modal2-body-gal.enj {
  flex-direction: column;
  padding: 2.5vw 0vw;
  align-items: center;
}

.modal2-body-gal .gallery-item {
  margin: 0 0.5vw 2vw;
}
.modal2-body-gal .gallery-item .gallery-frame {
  transition: 0.5s;
  cursor: pointer;
  width: 14vw;
  height: 14vw;
  object-fit: cover;
}
.modal2-body-gal .gallery-item:hover .gallery-frame {
  transform: scale(1.1);
}
.modal2.gale {
  background: transparent;
  height: 95%;
  box-shadow: none;
  border-radius: 1vw;
  /*width: 72%;*/
  width: 59%;
}
.modal2.gale::-webkit-scrollbar {
  background-color: transparent;
}
.modal2.gale .gallery-mosaic {
  margin-top: 2vw;
  display: flex;
  width: 55vw;
  justify-content: center;
  align-items: center;
  height: 8vw;
  overflow-x: scroll;
  overflow-y: hidden;
}
.modal2.gale .gallery-mosaic::-webkit-scrollbar {
  width: 55vw;
}
.modal2.gale .gallery-mosaic::-webkit-scrollbar-track {
  background: transparent;
}
.modal2.gale .gallery-mosaic::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.337); /* color of the scroll thumb */
  border-radius: 2vw;
}
img.rest-of-pics {
  height: 8vw !important;
  margin: 0 0.5vw;
  cursor: pointer;
}
.cont-img-gal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cont-img-gal .btn-content {
  height: 100%;
  display: flex;
  align-items: center;
}
.modal2.gale .prev,
.modal2.gale .next {
  height: fit-content;
  font-family: "Rajdhani";
  font-size: 4vw;
  color: white;
  cursor: pointer;
  text-decoration-color: transparent;
  transition: 0.25s;
}
.modal2.gale .prev:hover,
.modal2.gale .next:hover {
  font-size: 8vw;
  text-decoration-color: transparent;
} /*img.gallery-frame-img {
    cursor: zoom-in;
    transition: .5s;
}img.gallery-frame-img:hover {
    transform: scale(1.7);
}*/
.modal2.gale img {
  width: auto;
  height: 70vh;
}
.modal2.gale .modal2-header {
  padding: 0;
}
.modal2-backdrop.enjambre {
  background-color: #000000b3;
  /*top: auto!important;*/
}
.modal2.gale .btn-close {
  font-size: 2vw;
  height: 2.5vw;
  width: 2.5vw;
  background: none;
  /*margin-right: 4vw;*/
}
.modal2.gale .modal2-body-gal {
  justify-content: center;
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0vw;
  border: none;
  font-size: 2vw;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  background: #168cd1;
  border-radius: 0 0 0 1vw;
  width: 3vw;
  height: 3vw;
  font-family: "Roboto";
  line-height: 2vw;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2vw;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

/*
a:hover {
    color: transparent!important;
    text-decoration: none!important;
}*/
/**fin slide videos */

/*_________Comen___________*/
/* header*/

#user-img {
  display: none;
  overflow: hidden;
  margin-top: 11vw;
  margin-left: 5.7vw;
  border-radius: 3vw;
}

#user-img img {
  width: 4.444444444444445vw;
  height: 4.444444444444445vw;
}

#edit-user-img img {
  width: 5.833333333333333vw;
}
#edit-video-img img {
  width: 5.833333333333333vw;
  margin-top: 1vw;
  margin-left: 26vw;
}

#edit-prod-img {
  margin-top: -4vw;
  margin-left: 32vw;
}
#edit-prod-img img {
  width: 5.833333333333333vw;
}
#edit-calendar-img img {
  height: 5.27777777777778vw;
  width: 5.27777777777778vw;
  margin-left: 5vw;
  margin-top: 0.5vw;
}
#edit-larg-img img {
  height: 25.27777777777778vw;
  max-width: 51.2vw;
  margin-bottom: 1.6666666666666667vw;
}
#edit-larg-img {
  margin-top: 2.5vw;
}

.Imagen_Edit {
  margin-top: -2vw !important;
}
.Imagen_Edit2 {
  margin-top: -4vw !important;
}
.Imagen_EditSpons {
  margin-left: 33vw !important;
}

#edit-user-img {
  margin-top: -8vw;
  margin-left: 43vw;
}
#edit-user-img p {
  font-size: 0.9722222222222222vw;
  font-weight: 600;
  font-style: normal;
  line-height: 1.3888888888888888vw;
  margin-left: 1vw;
}
#header {
  height: 10vw;
  background: var(--color-2);
}

#header h1,
#header h2 {
  font-size: 3.3333333333333335vw !important;
  font-weight: 600;
  font-style: normal;
  margin-left: 3.3333333333333335vw;
  margin-top: 3.0729166666666665vw;
  color: var(--color-4);
}

#user-name-img {
  width: 36.5vw;
}
#user-name {
  width: 42.98vw;
  margin-left: 5.8vw !important;
}

#header h1 {
  font-size: 2.430555555555556vw;
}

#header h2 {
  font-size: 2.083333333333333vw;
}

#btn-agregar a {
  width: 15.555556vw;
  height: 4.2vw;
  margin-top: -4.7vw;
  letter-spacing: 0.1388888888888889vw;
  font-weight: 600;
  padding-top: 1.4vw;
  padding-left: 1.4vw;
  font-size: 0.9375vw;
  text-decoration: none;
  border-radius: 1vw;
  background-color: var(--color-7);
  color: var(--color-4);
}
.btns-dflx {
  display: flex;
}

#btn-agregar-lrg a {
  width: 18.555556vw;
  margin-left: 61vw;
  height: 4.2vw;
  margin-top: -4.7vw;
  letter-spacing: 0.1388888888888889vw;
  font-weight: 600;
  padding-top: 1.4vw;
  padding-left: 1.4vw;
  font-size: 0.9375vw;
  text-decoration: none;
  border-radius: 1vw;
  background-color: var(--color-7);
  color: var(--color-4);
}

#btn-agregar a:hover,
#btn-agregar-lrg a:hover {
  color: var(--color-4) !important;
}

#btn-agregar img {
  width: 0.9722222222222222vw;
  height: 0.9722222222222222vw;
  margin-right: 0.61vw;
}

#btn-agregar-lrg img {
  width: 0.9722222222222222vw;
  height: 0.9722222222222222vw;
  margin-right: 0vw;
}

.div-logo {
  width: 13.020833333333334vw;
  height: 10vw;
  margin-left: 2.745vw;
  background-color: var(--color-8);
  text-align-last: center;
}

.logo {
  margin-top: 1.7187500000000002vw;
  /*margin-left: 15.277778VW;
    margin-right: 2vw;*/
  width: auto;
  height: 6.5625vw;
}

.logo-secciones {
  margin-top: 5.263889vw;
  margin-left: 15.277778vw;
  margin-right: 24vw;
  width: 7.520833vw;
  height: 8.09375vw;
}

.title-sec {
  margin-left: 19.680556vw !important;
}
.title-sec2 {
  margin-left: 17.180556vw !important;
}
div#btn-agregar {
  padding-left: 63.5vw !important;
}
div#btn-agregarC {
  padding-left: 63.5vw !important;
}

/* QUILL EDITOR */
.quill-editor {
  margin-bottom: 3vw;
  margin-top: 1vw;
  margin-left: 10vw;
}
.quill-editor,
#prev_quill,
#prev_title {
  width: 53.65vw;
}

.pvz-title {
  margin-top: 3vw;
}

#prev_title {
  margin-bottom: 2vw;
}
#prev_quill {
  margin-bottom: 5vw;
  overflow: hidden;
  padding: 1vw;
  border: 0.1vw solid #ccc;
  margin-left: 10vw;
}

#prev_quill img {
  max-width: 51vw;
}
/*FIN QUILL EDITOR */

/** content */

#content {
  width: 87vw;
  min-height: 65.138889vw;
  margin-left: 9.86111111111111vw;
  margin-top: 2.4945vw;
  margin-bottom: 9vw;
  /*box-shadow: 0VW 0.5555555555555556VW 0.6944444444444444VW rgba(0, 0, 0, 0.25);*/
}

/* sidemenu */
.admin-menu {
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.admin-menu li {
  /*box-shadow: 0vw 0.1777777777777778VW 0.1777777777777778VW 0vw rgb(0 0 0 / 25%);*/
  text-transform: uppercase;
  text-align: center;
  background-size: 0;
  background-repeat: no-repeat;
  background-position-x: 3.99vw;
  background-position-y: 2.3vw;
  transition: 0.5s;
}

.text-menu:hover {
  padding-bottom: 2vw;
  background-image: url("./assets/red-rec.png");
  background-size: 50% 0.24vw;
  background-position-y: 1.3vw !important;
  background-repeat: no-repeat;
  transition: 0.5s;
}

.admin-menu li:hover {
  background-size: 3.2vw auto;
}

.msg-error {
  color: #ffffff;
  margin-top: 1vw;
  background: var(--color-2);
  padding: 0.5vw;
}

#sidemenu {
  margin-left: -7.13vw;
  margin-right: 1vw;
  width: 13.030833333333334vw;
  max-height: 62vw;
  margin-top: -2.5vw;
}

#PoweredByLegrafica {
  margin-left: 0.8333333333333334vw;
  margin-top: 1.1805555555555556vw;
  width: 8.680555555555555vw;
  height: 1.3888888888888888vw;
}

.logo-lg {
  display: none;
  width: 10.277777777777777vw;
  height: 3.75vw;
  margin-left: 4.027777777777778vw;
  margin-top: 2.569444444444444vw;
  margin-bottom: 2.569444444444444vw;
}

li {
  font-style: normal;
  font-size: 0.8333333333333334vw;
  font-weight: bolder;
  padding-bottom: 1vw;
  padding-top: 1vw;
  margin-bottom: 0.06944444444444445vw;
}

li.active span {
  padding-bottom: 2vw;
  color: var(--color-7);
  background-image: url("./assets/red-rec.png");
  background-size: 50% 0.23vw;
  background-repeat: no-repeat;
  background-position-y: 1.3vw;
  font-weight: bolder;
}

ul {
  list-style: none;
  padding: 0px;
  cursor: pointer;

  overflow-y: none;
}

.icon-menu {
  margin-left: 4.513888888888888vw;
}

.text-menu {
  /*margin-left: 1.3194444444444444VW*/
}

#icon-us {
  width: 1.3194444444444444vw;
  height: 1.1805555555555556vw;
}

#icon-pr {
  width: 1.6666666666666667vw;
  height: 1.5277777777777777vw;
}

#icon-ct {
  width: 1.3888888888888888vw;
  height: 1.3888888888888888vw;
}

#icon-sv {
  width: 1.3888888888888888vw;
  height: 1.3888888888888888vw;
}

#icon-pm {
  width: 1.3888888888888888vw;
  height: 1.3888888888888888vw;
}

.ahome {
  color: var(--color-4);
  font-size: 0.8333333333333334vw;
  font-weight: bolder;
  font-family: "Lato";
  text-decoration: none !important;
  text-transform: none !important;
  background: var(--color-2);
  width: 13.030833333333334vw;
  height: 2.2916666666666665vw;
  transition: all 300ms;
  text-align: center;
  margin-bottom: 0vw;
  padding-top: 0.5vw;
  cursor: pointer;
}

.ahome:hover {
  color: var(--color-4);
  background: var(--color-7);
}
/** Sections */

.msg-coincidencias {
  font-size: 1vw;
  margin-left: 20vw;
  margin-top: 1.3vw;
}

#num_items {
  height: 2vw;
  width: 9vw;
  margin-left: 3.4vw;
}
#num_items2 {
  height: 2vw;
  width: 9vw;
  margin-left: 5.4vw;
}
#num_items p,
#num_items2 p {
  background: var(--color-4);
  margin-top: 2.2vw;
  font-size: 0.9375vw;
  font-weight: 600;
  color: var(--color-8);
  padding-top: 0.45vw;
  height: 2.34375vw;
  text-align: center;
  margin-bottom: 0vw !important;
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-radius: 2vw;
}
#buscador-categoria button,
#prod-page button {
  border-radius: 0vw !important;
  background: var(--color-4) !important;
  border: 0vw;
  margin-left: 3.4vw;
  width: 11.25vw;
  margin-top: 2.2vw;
  height: 2.34375vw;
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-radius: 2vw !important;
}

#prod-page button:hover,
#buscador-categoria button:hover {
  background-color: var(--color-3) !important;
}

#buscador-categoria li,
#prod-page li {
  padding-bottom: 0.2vw;
  padding-top: 0.2vw;
}

#buscador-categoria .dropdown-menu,
#prod-page .dropdown-menu {
  width: 12vw !important;
}

#buscador-categoria .dropdown-toggle::after,
#prod-page .dropdown-toggle::after {
  margin-left: 8.4vw !important;
  color: var(--color-8);
  margin-top: -1.2vw !important;
}

#buscador-categoria .drop-text,
#prod-page .drop-text {
  margin-left: 0.3vw;
  font-size: 0.9375vw;
  font-weight: bolder;
  color: var(--color-8);
  overflow-x: hidden;
  width: 9vw;
  height: 1.8vw;
  text-align: center;
  margin-bottom: 0.01vw;
}

#buscador {
  margin-top: 2.5vw;
  margin-left: 2.99vw;
}

.searchleft {
  margin-left: 38vw !important;
}

#input-search {
  border-radius: 0vw;
  width: 12vw !important;
  font-size: 0.9vw;
}

#buscador .form-control {
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-top-left-radius: 2vw;
  border-bottom-left-radius: 2vw;
  border-right: none !important;
}

#btn-search {
  background: var(--color-4);
  color: var(--color-4);
  border: none;
  width: 4vw;
  font-size: 0.7vw;
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-top-right-radius: 2vw;
  border-bottom-right-radius: 2vw;
  border-left: none !important;
}
.btn_searchimg img {
  width: auto;
  height: 1.0364583333333333vw;
}

#btn-search,
#input-search {
  height: 2.34375vw;
}

#btn-search:hover {
  background: var(--color-3);
}

#navigate-buttons {
  margin-left: 34vw;
  padding: 1vw;
  width: 20vw;
}

.num-pages {
  width: auto;
  padding-top: 0.1vw;
  margin-left: 1vw;
  text-align: center;
  height: 1.5vw;
  font-size: 1vw;
}

.btn-navigate {
  border: 0vw;
  margin-left: 1vw;
  font-size: 1vw;
  width: 2vw;
  height: 2vw;
  color: var(--color-4);
  background: var(--color-7);
  border-radius: 2vw;
}
.btn-navigate img {
  width: 0.9vw;
  height: 0.5vw;
}

a.btn-ver {
  background: var(--color-7);
  text-decoration: none;
  color: #ffffff;
  padding: 0.5vw 1vw;
  font-size: 0.8vw;
  font-family: "Rajdhani";
  border-radius: 0vw 1vw 1vw 0vw;
}

a.btn-ver img {
  width: 2.8vw;
  margin-left: 1vw;
}
a.btn-ver:hover {
  background: var(--color-2);
  color: #ffffff !important;
  text-decoration: none;
}

.btn-navigate {
  border: 0vw;
  margin-left: 1vw;
  /*font-size: 1vw;*/
  width: 2vw;
  height: 2vw;
  color: var(--color-4);
  background: var(--color-7);
  border-radius: 1vw;
}
.btn-navigate img {
  width: 0.9vw;
  height: 0.5vw;
}
#btn-next {
  transform: scaleX(-1);
}

.btn-navigate:hover {
  background: var(--color-2);
}

/** suscripciones 
.sec-suscr {
  margin-top: 1vw;
}*/

#content .downloadbtn {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
  font-size: 0.7vw;
  font-family: var(--font-family) !important;
  border-radius: 1.2vw;
  color: white;
  margin-left: 2vw;
  height: 2.3vw;
  margin-top: 2.2vw;
}
#content .downloadbtn:hover {
  background-color: var(--color-1) !important;
  box-shadow: none !important;
}

#content .downloadbtn:focus {
  background-color: var(--color-1) !important;
  box-shadow: none !important;
}

#content .icon {
  max-width: 0.8vw;
  max-height: 0.8vw;
  cursor: pointer;
}

#content .mrcsv {
  margin-right: 1.7vw !important;
}

.searchleft2 {
  margin-left: 6.3vw !important;
}

.message-subscriptor {
  background-color: var(--color-9);
  text-align: center;
  width: 33vw;
  height: 3vw;
  padding: 0.5vw;
  color: var(--color-4);
  font-size: 1.1vw;
  margin-top: 1vw;
}

.message-subscriptor span {
  margin-left: 1vw;
  cursor: pointer;
}

.message-subscriptor span:hover {
  color: var(--color-4);
  background-color: var(--color-7);
  padding: 0vw 0.2vw;
  border-radius: 0.1vw;
}

/** fin suscripciones */

/* SECTION  */
.estadisticas {
  margin-bottom: 5vw;
  margin-left: 19.427083333333332vw;
}
.estadisticas label {
  width: 26vw;
}
#Estadisticabtn_guardar {
  margin-left: -9.5vw !important;
}
.Last_Guardar {
  margin-left: -11vw;
}

/** ______CARRITO______ */

#sec_product_detail,
#section_carrito,
#sec_shipment_details {
  background: var(--color-4);
  height: 100%;
}

.cart_msg {
  padding: 1vw;

  font-family: "Lato";
  color: var(--color-4);
  margin-top: 2vw;
  width: 20.8vw;
}

.btn_toCart {
  cursor: pointer;
  padding-left: 1vw;
}

.cart_error {
  background: var(--color-2);
}
.cart_success {
  background: var(--color-11);
}

#title_sec_car {
  text-align: left;
  margin-left: 8.489583333333334vw;
  margin-top: 2vw;
  color: var(--color-8);
  font-size: 2.604166666666667vw;
  font-weight: 800;
  width: 90%;
  font-family: Rajdhani;
}

.title_product {
  font-size: 1.1458333333333333vw;
}

.Cont_Empty {
  text-align: -webkit-center;
}

.Pedido_Total {
  /*width: 28.541666666666664vw;*/
  width: 21.541667vw;
  height: fit-content;
  border-radius: 1.1vw;
  border: 1px solid var(--color-8);
  background: var(--color-4);
  margin-bottom: 4vw;
  margin-left: 5vw;
}

.Tupedido_Text {
  height: 3.229166666666667vw;
  width: auto;
  font-size: 1.25vw;
  font-weight: 700;
  color: var(--color-4);
  background: var(--color-8);
  border-top-right-radius: 1vw;
  border-top-left-radius: 1vw;
  text-align: center;
  padding-top: 0.7vw;
  font-family: Rajdhani;
}

div#items_car_list {
  display: flex;
  width: 100%;
  height: fit-content;
  background: var(--color-4);
  margin-bottom: 0.01vw;
  text-align: -webkit-center;
}

div#items_car_list1 {
  display: flex;
  width: 83%;
  height: 10vw;
  background: var(--color-4);
  margin-bottom: 0.01vw;
  text-align: -webkit-center;
  border-top: 1px solid var(--color-3);
  place-content: center;
}

.msg_carrito_vacio {
  width: 18vw;
  height: 3vw;
  text-align: center;
  font-weight: 600;
  font-size: 1.5625vw;
  color: var(--color-3);
  margin-top: 6.614583333333333vw;
  margin-left: 0vw;
}

.div_BackTienda {
  width: 15.833333333333332vw;
  height: 4.6875vw;
  display: flex;
  background: var(--color-7);
  align-items: center;
  margin-left: 3vw;
  margin-top: 3vw;
  margin-bottom: 7.968749999999999vw;
  cursor: pointer;
  justify-content: center;
  font-family: "Rajdhani";
}
.div_BackTienda p {
  margin-top: 1vw;
  color: var(--color-4);
  font-size: 0.9375vw;
}
.div_BackTienda img {
  width: 0;
  height: 0;
  margin-right: 0;
  margin-left: 0;
  transition: 0.5s;
}
.div_BackTienda:hover img {
  width: 4vw;
  height: 0.7291666666666666vw;
  margin-right: 1vw;
  margin-left: 1vw;
}

.item_car_name {
  font-weight: 600;

  transition: all 300ms;
  color: var(--color-5) !important;
  margin-top: 0.5vw;
}
.item_car_name:hover {
  color: var(--color-7);
}
#title_car_list {
  display: flex;
  background: var(--color-4);
  width: 100%;
  padding-left: 25vw;
  padding-top: 7vw;
}

#title_car_list h4 {
  margin-left: 1vw;
  margin-bottom: 0vw;
  font-size: 1.7187500000000002vw;
  font-weight: 700;
  width: 10vw;
  /* padding: 0.5vw; */
  color: var(--color-8);
  text-align: center;
  font-family: Rajdhani;
}

.div_item {
  /*height: 31.4vw;*/
  margin-bottom: 3vw;
  margin-right: 2vw;
  margin-left: 8.5vw;
  /* overflow-y: scroll;*/
}

.WHITELINE {
  width: 0.01vw;
  height: auto;
  background-color: var(--color-4);
  margin-top: 11vw;
  margin-right: 5vw;
  margin-bottom: 5vw;
}

.GoldLine {
  /* width: 21.7vw;*/
  width: 15.7vw;
  height: 0.01vw;
  background: var(--color-3);
  /*  margin-right: 1vw;*/
  margin-bottom: 1.3vw;
}

.Bar_Precio {
  padding-right: 2vw !important;
}

.DivDet {
  display: flex;
  height: fit-content;
  /*padding-top: 2vw;*/
}

.item_car_list {
  display: flex;
  align-items: center;
  width: 55vw;
  height: fit-content;
  font-size: 1.25vw !important;
  font-family: Rajdhani;
  border-top: 1px solid var(--color-3);
  border-bottom: 1px solid var(--color-3);
  padding: 2vw 0;
}
.ie_item_car {
  cursor: pointer;
  transition: all 300ms;
  font-size: 0.7vw;
}

.ie_item_car:hover {
  color: var(--color-2);
  font-size: 1vw;
}

.cantCar button {
  display: none;
}
.cantCar img {
  display: none;
}

.TallaColor {
  font-family: "Rajdhani";
  color: var(--color-3) !important;
}
.TallaColor2 {
  display: none;
}
.Text_Producto {
  font-size: 0.9416666666666665vw !important;
  width: 14vw !important;
  font-family: "Rajdhani";
  color: var(--color-3) !important;
}
.Text_Cantidad {
  font-size: 1vw !important;
  font-family: Rajdhani;
}
.Text_Precio {
  padding: 0.6vw !important;
  font-size: 1.1458333333333333vw !important;
  background: var(--color-4);
  color: var(--color-2) !important;
  font-family: Rajdhani;
}

.DivCupones {
  width: 55vw;
  height: 14vw;
}
table.TableCupones {
  width: 50vw;
  height: 11vw;
  margin-left: 8.8vw;
}
.TC_Title {
  width: 7vw;
  height: 3vw;
  margin-bottom: 0vw;
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  white-space: nowrap;
  font-size: 2vw;
  margin-left: -43vw;
}
.TC_Delet {
  width: 2vw;
  height: 2vw;
}
.TC_Code {
  width: 22vw;
  height: 2vw;
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  white-space: nowrap;
  font-size: 1.25vw;
}
.TC_Desc {
  width: 14vw;
  height: 2vw;
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  white-space: nowrap;
  font-size: 1.25vw;
}
.TC_F2 {
  height: 2vw;
}
.TC_DDelet {
  margin-bottom: 0.3vw;
}
.TC_DDelet span {
  border: 1px solid var(--color-8);
  border-radius: 50%;
  padding: 0.2vw 0.3vw !important;
  text-align: center;
}
.TC_DCode {
  font-size: 1.25vw;
  color: var(--color-3);
  font-family: "Rajdhani";
}
.TC_DDesc {
  font-size: 1.25vw;
  color: var(--color-2);
  font-family: "Rajdhani";
}

.item_car_list p {
  width: 10vw;
  font-size: 1.0416666666666665vw;
  text-align: center;
  color: var(--color-8);
  margin-bottom: 0vw;
}

.item_car_list p input {
  width: 5vw;
  font-weight: 400;
  text-align: center;
  border: 0;
  color: var(--color-3);
  background: var(--color-4);
  margin-top: 0.6vw;
}

.item_car_list p input:focus {
  outline: 0px;
  background: transparent;
}

.item_car_list p span {
  margin-top: 1vw;
  border: 1px solid var(--color-8);
  border-radius: 17vw;
  padding: 0.2vw 0.3vw;
}
.img_car_item {
  height: 12vw;
  width: 12vw;
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 0.9vw;
  text-align: center;
  border: 1px solid var(--color-8);
  border-radius: 1vw;
}
.img_car_item img {
  padding: 0vw;
  max-height: 10vw;
}

#tab_car {
  margin-top: 2vw;
  margin-bottom: 0.01vw;
  width: 100%vw;
  /*border-bottom: 1px solid var(--color-1);*/
}

#car_list_total {
  margin-top: 2vw;
}

.car_subtotal {
  margin-bottom: 0vw;
}
.car_subtotal h4 {
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
}
.car_subtotal p {
  color: var(--color-1);
  font-family: Rajdhani;
}

.Div_Envio {
  display: flex;
  justify-content: space-between;
  /*width: 20.7vw;*/
  width: 15.7vw;
  height: fit-content;
  margin: auto;
}
.Div_Envio h4 {
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  font-size: 1.25vw;
}
.Div_Envio p {
  color: var(--color-1);
  font-family: Rajdhani;
  width: fit-content;
  text-align: right;
  font-size: 0.9375vw;
}

.Div_Desc {
  display: flex;
  justify-content: space-between;
  /*width: 20.7vw;*/
  width: 15.7vw;
  height: fit-content;
  margin: auto;
}
.Div_Desc h4 {
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  white-space: nowrap;
  font-size: 1.25vw;
}
.Div_Desc p {
  color: var(--color-1);
  font-family: Rajdhani;
  width: fit-content;
  text-align: right;
  font-size: 0.9375vw;
}

.Div_Subtotal {
  display: flex;
  /*width: 20.7vw;*/
  width: 15.7vw;
  margin: auto;
  justify-content: space-between;
}
.Div_Subtotal h4 {
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  font-size: 1.25vw;
}
.Div_Subtotal p {
  color: var(--color-1);
  font-family: Rajdhani;
  width: fit-content;
  text-align: right;
  font-size: 1.25vw;
}

.Div_Total {
  margin-bottom: 0.01vw;
  display: flex;
  justify-content: space-between;
  /*width: 20.7vw;*/
  width: 15.7vw;
  margin: auto;
}
.Div_Total h4 {
  font-weight: 600;
  color: var(--color-8);
  font-family: Rajdhani;
  font-size: 1.25vw;
}
.Div_Total p {
  color: var(--color-2);
  font-family: Rajdhani;
  width: fit-content;
  text-align: right;
  font-size: 1.4583333333333333vw;
}

.PedidoF2 {
  display: flex;
  justify-content: space-between;
  /*width: 20.7vw;*/
  width: 15.7vw;
  margin: auto;
}
.PedidoF2_Producto {
  width: 7vw;
  font-size: 1.25vw;
  text-align: left;
}
.PedidoF2_Precio {
  /*width: 13vw;*/
  font-size: 1.25vw;
  text-align: right;
}

.PedidoF3 {
  margin-left: 4vw;
  margin-right: 4vw;
  margin: auto;
  /*width: 20.7vw;*/
  width: 15.7vw;
  height: fit-content;
  /*overflow-y: scroll;*/
}

.PedidoF4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Div_TuProducto {
  text-align: left;
}
.Text_Name {
  color: var(--color-5) !important;
  font-size: 0.9375vw;
  margin-bottom: 0vw;
}
.Text_Pedido {
  width: 8vw;
  margin-right: 2vw;
  text-align: left;
  font-size: 0.7475vw;
  margin-bottom: 0vw;
  font-family: "Rajdhani" !important;
  color: var(--color-5) !important;
}
.Precop_Pedido {
  font-size: 1.25vw;
  width: fit-content;
  text-align-last: right;
  align-self: center;
  font-family: "Rajdhani" !important;
}
.Pedido_Cantidad {
  font-size: 0.9375vw;
}
.Pedido_Cantidad input {
  border: 0;
}

.Div_Cupon {
  display: flex;
  align-items: center;
  height: 6vw;
}
.Actualizar_Carrito {
  height: 4.947916666666666vw;
  width: 14.9vw;
  color: var(--color-1);
  border: 1px solid var(--color-8);
  background: var(--color-4);
  font-size: 0.7291666666666666vw;
  text-transform: uppercase;
  margin-left: 8.5vw;
  font-family: "Rajdhani";
}
.Input_Cupon {
  height: 4.947916666666666vw;
  width: 14.9vw;
  padding: 0vw 1vw;
  color: var(--color-1);
  border: 1px solid var(--color-3);
  background: var(--color-4);
  font-size: 0.7291666666666666vw;
 
  margin-left: 1vw;
  font-family: "Rajdhani";
}
.Input_Cupon::placeholder {
  color: var(--color-3);
  font-size: 0.7291666666666666vw;
  text-align: center;
}
.Aplicar_Cupon {
  height: 4.947916666666666vw;
  width: 19.0625vw;
  color: var(--color-4);
  border: 1px solid var(--color-8);
  background: var(--color-8);
  font-size: 0.7291666666666666vw;
  text-transform: uppercase;
  margin-left: 1vw;
  font-family: "Rajdhani";
}
.Aplicar_Cupon img {
  width: 0;
  height: auto;
  margin-left: 0;
  transition: 0.35s;
}
.Aplicar_Cupon:hover img {
  width: 4vw;
  height: auto;
  margin-left: 4vw;
}
.Fin_Compra {
  font-family: "Rajdhani";
  /*width: 20vw;*/
  width: 18vw;
  height: 4.6875vw;
  color: var(--color-4);
  background: var(--color-2);
  border: 0;
  font-size: 0.9375vw;
  /*margin: 2vw 4vw;*/
  margin: 2vw auto;
}
.Fin_Compra img {
  width: 0;
  height: auto;
  margin-left: 0;
  transition: 0.35s;
}
.Fin_Compra:hover img {
  width: 5vw;
  height: auto;
  margin-left: 3vw;
}

/* Detalles de envio*/
.Content_Detalles {
  height: fit-content;
  width: 90vw;
  margin: 5vw;
  padding-bottom: 11vw;
}
.form_detalles_envio {
  margin-left: 7.604166666666666vw;
  border-right: 1px solid var(--color-3);
}
.Div_Titulo {
  background: var(--color-4);
  width: 100%;
  height: 4.791666666666667vw;
  text-align: -webkit-center;
  padding-top: 1.2vw;
}
.Div_Titulo h1 {
  color: var(--color-8);
  font-size: 1.875vw;
  font-weight: 700;
  font-family: "Rajdhani";
  text-transform: uppercase;
  width: 17vw;
  height: 2vw;
  margin-left: 8vw;
  float: left;
}
.Div_Titulo span {
  color: var(--color-8);
  font-weight: 700;
}

.Div_SubTitulo {
  background: var(--color-4);
  width: 89%;
  height: 6.958333333333333vw;
  text-align: -webkit-center;
  padding-top: 1.2vw;
  text-align: left;
  margin-left: 8vw;
  margin-bottom: 7.135416666666666vw;
  border-bottom: 1px solid var(--color-3);
}
.Div_SubTitulo p {
  color: var(--color-3);
  font-size: 1.25vw;
  font-weight: 700;
  font-family: "Rajdhani";
  width: 33vw;
  height: 2vw;
}
.Div_SubTitulo span {
  color: var(--color-5);
}

.Labels_Formulario {
  color: var(--color-8);
  font-size: 0.9375vw;
  font-weight: 900;
}

.Inputs_dobles {
  width: 20.625vw;
  height: 3.6458333333333335vw;
  border: 1px solid var(--color-8);
  border-radius: 0vw !important;
  margin-right: 0.9vw;
  padding-left: 1vw;
}
.Inputs_dobles::placeholder {
  font-size: 0.8333333333333334vw;
}
.Input_Solo {
  width: 43.072916666666664vw;
  height: 3.6458333333333335vw;
  border: 1px solid var(--color-8);
  border-radius: 0vw;
  padding-left: 1vw;
  font-size: 0.9375vw;
}
.Input_Solo::placeholder {
  font-size: 0.8333333333333334vw;
}
select.Input_Solo {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./assets/flecha-blue.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 40.5vw;
  background-size: 0.6vw;
}
.Input_CP {
  width: 43.072916666666664vw;
  height: 3.6458333333333335vw;
  border: 1px solid var(--color-8);
  border-radius: 0vw;
  padding-left: 1vw;
}
.Pais {
  font-size: 0.9375vw;
  color: var(--color-8);
}

.in_cupon {
  font-weight: 600;
  cursor: pointer;
}

.cols_shdls {
  display: flex;
}

.cl1_shdls {
  width: 59%;
}
.cl2_shdls {
  width: 30%;
  margin-left: 7.03125vw;
  margin-top: 1.6666666666666667vw;
}

.tupedido {
  background: white;
  border: 1px solid var(--color-3);
  border-radius: 1vw;
  width: 28.541666666666664vw;
}
.tupedido_title {
  color: var(--color-4);
  background: var(--color-2);
  width: 100%;
  height: 5.104166666666667vw;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  text-align: center;
  padding-top: 2vw;
  font-size: 1.25vw;
  font-family: "Rajdhani";
  font-weight: 700;
}

.items_tupedido {
  padding-left: 2.96875vw;
}

.productos_tupedido {
  display: flex;
  padding-left: 2.96875vw;
  margin-top: 2.5vw;
}
.productos_tupedido p {
  color: var(--color-8);
  font-size: 1.25vw;
  font-weight: 700;
  font-family: "Rajdhani";
  margin-right: 12.9vw;
}
.productos_tupedidoS {
  margin-right: 0vw !important;
}
.AllProducts {
  width: 18vw;
  height: fit-content;
}

.Producto_1 {
  font-family: "Rajdhani";
  color: var(--color-5) !important;
}
.Producto_1desc {
  color: var(--color-3) !important;
}
.items_tupedido .itm_tpd {
  display: flex;
}
.items_tupedido .itm_tpd p {
  margin-right: 1vw;
  color: var(--color-8);
  font-size: 0.9375vw;
  margin-bottom: 0.1vw;
}
.SubtotalOnly {
  width: 5vw;
  height: 3vw;
  padding-top: 1.2vw;
  font-size: 1.25vw !important;
  font-weight: 400;
}

.RedLine {
  background: var(--color-3);
  width: 22vw;
  height: 0.01vw;
  margin-left: 3vw;
}

.subtotal_tupedido {
  display: flex;
  padding-left: 2.96875vw;
}
.subtotal_tupedido p {
  color: var(--color-8);
  font-size: 1.25vw;
  font-weight: 700;
  font-family: "Rajdhani";
  margin-right: 13.3vw;
}
.SubtotalOnl2 {
  color: var(--color-8) !important;
  font-weight: 600 !important;
  margin-right: 0vw !important;
}

.envio_tupedido {
  display: flex;
  padding-left: 2.96875vw;
}
.envio_tupedido p {
  color: var(--color-8);
  font-size: 1.25vw;
  font-weight: 700;
  font-family: "Rajdhani";
  margin-right: 14.8vw;
}
.Precio_Fijo {
  width: 9.4vw;
  height: 2vw;
  margin-right: 0vw !important;
}
.Precio_Fijo span {
  color: var(--color-8);
}

.total_tupedido {
  display: flex;
  padding-left: 2.96875vw;
}
.total_tupedido p {
  color: var(--color-8);
  font-size: 1.25vw;
  font-weight: 700;
  font-family: "Rajdhani";
  margin-right: 15vw;
}

.Total_TuPedido {
  font-size: 1.4583333333333333vw !important;
  color: var(--color-2) !important;
  margin-right: 0vw !important;
}

.radio_sec {
  width: 24.427083333333332vw;
  height: 3.4375000000000004vw;
  display: flex;
  background: rgb(231, 231, 231);
  color: var(--color-8);
  font-size: 0.9375vw;
  font-family: "Rajdhani";
  padding-top: 1vw;
  padding-left: 4.5vw;
  margin-top: 1.0416666666666665vw;
  margin-bottom: 1.5625vw;
  align-items: baseline;
}
.radio_sec2 {
  width: 24.427083333333332vw;
  height: 3.4375000000000004vw;
  display: flex;
  background: rgb(241, 241, 241);
  font-size: 0.9375vw;
  font-family: "Rajdhani";
  padding-top: 1vw;
  padding-left: 1vw;
  margin-top: 1.0416666666666665vw;
  margin-bottom: 1.5625vw;
  margin-left: 2.2vw;
  align-items: baseline;
}
.radio_sec2 input {
  margin-right: 1vw;
}
.radio_sec3 {
  width: 24.427083333333332vw;
  height: 3.4375000000000004vw;
  display: flex;
  background: var(--color-4);
  font-size: 0.9375vw;
  font-family: "Rajdhani";
  padding-top: 1vw;
  margin-top: 1.0416666666666665vw;
  margin-bottom: 1.5625vw;
  margin-left: 2.2vw;
  align-items: baseline;
}
.radio_sec3 input {
  margin-right: 1vw;
  align-self: center;
}
.Text_Terminos {
  color: var(--color-8);
  font-size: 0.8vw;
}
.Text_Terminos span {
  font-family: "Rajdhani";
  color: #032a4b;
}
.radio_sec input {
  margin-right: 1vw;
}

.Text1 {
  width: 24.427083333333332vw;
  height: 6vw;
  text-align: justify;
  color: var(--color-8);
  font-size: 0.9375vw;
  font-family: "Rajdhani";
}
.Text2 {
  width: 24.427083333333332vw;
  height: 5vw;
  text-align: justify;
  color: var(--color-8);
  font-size: 0.9375vw;
  margin-left: 2.2vw;
  font-family: "Rajdhani";
}
.Text2 span {
  font-family: "Rajdhani";
}

.Pedir {
  width: 24.427083333333332vw;
  height: 4.947916666666666vw;
  background: var(--color-2);
  color: var(--color-4);
  font-size: 0.9375vw;
  font-family: "Rajdhani";
  border: 0;
  margin-left: 2.2vw;
}
.Pedir img {
  width: 0;
  height: auto;
  margin-left: 0;
  transition: 0.35s;
}
.Pedir:hover img {
  width: 5vw;
  height: auto;
  margin-left: 7vw;
}

.df_group_frm {
  display: flex;
}
.group_frm {
  display: grid;
  margin-right: 1vw;
}
input#nombreDE {
  border: 1px solid var(--color-8);
  border-radius: 2.604166666666667vw;
}

/**fin carrito */

#content-stn {
  margin-bottom: 5vw;
  margin-left: -0.007vw;
  margin-top: 0.3vw;
  margin-bottom: 5vw;
  margin-top: 2.65625vw;
}

#content-stn .row {
  margin-left: 0vw;
}

.content_colors {
  width: 77vw !important;
  height: auto;
  margin-left: 1.9vw;
  margin-top: 3.0729166666666665vw;
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-radius: 2.8vw !important;
  /*padding-left: 1.1vw;*/
  padding-bottom: 1.1vw;
}

#Perfil_divblue {
  width: 10.51vw !important;
  margin-top: 2.65625vw;
}
#Perfil_divred {
  width: 6.927083333333334vw !important;
  margin-top: 2.65625vw;
}

#Perfil_div2blue {
  width: 10.51vw !important;
}
#Perfil_div2red {
  width: 6.927083333333334vw !important;
}

#Perfil_nombre {
  padding-top: 0.65vw;
}
#Perfil_col1-4 {
  border-radius: 2.604166666666667vw;
  width: 5.9375vw;
  height: 2.65625vw;
  font-size: 0.9375vw !important;
  color: var(--color-4) !important;
  padding-top: 0.65vw;
  margin-left: 2vw;
}
#Perfil_col5 {
  border: 0.06944444444444445vw solid var(--color-8) !important;
  border-radius: 2.604166666666667vw;
  width: 5.9375vw;
  height: 2.65625vw;
  font-size: 0.9375vw !important;
  padding-top: 0.65vw;
  margin-left: 2vw;
}

#section_stock #content-stn {
  min-height: 21.3vw;
}

.msg_stock {
  font-size: 0.7638888888888888vw;
  font-style: normal;
  font-weight: 600;
  margin-top: -0.1vw;
  margin-left: 2vw;
  width: 3.5vw;
  /* height: 2vw; */
  /* padding-left: 2vw; */
  padding-top: 0.4vw;
  margin-top: 0.8vw;
}
.msg_stock p {
  color: var(--color-3);
}

.separador {
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: 1vw;
}
#section_stock .msg-coincidencias {
  margin-left: 14vw;
}

.Div_Perfiles {
  border: 0vw !important;
}
.Content_Colors {
  margin-left: 0vw !important;
}

#section_usuarios,
#section_calendario,
#section_subscriptores,
#section_equipo,
#section_store,
#section_galeria,
#section_torneo,
#section_perfiles {
  width: 77vw !important;
  height: auto;
  margin-left: 1.9vw;
  margin-top: 3.0729166666666665vw;
  border: 0.06944444444444445vw solid var(--color-8);
  border-radius: 2.8vw;
}
/*
#section_usuarios, #section_calendario, #section_subscriptores, #section_equipo, #section_store, #section_galeria, #section_torneo{
    width: 77VW !important;
    height: auto;
    margin-left: 1.9VW;
    margin-top: 3.0729166666666665VW;
    border: 0.06944444444444445VW solid var(--color-8);
    border-radius: 2.8vw; 
}*/

#section_stock {
  width: 53.6vw;
  background: transparent;
}

#section_usuarios .data p,
.title p,
#section_equipo .data p,
#section_equipo .title p,
#section_store .title p,
#section_store .data p,
#section_stock .data p,
#section_stock .title p,
#section_torneo .title p,
#section_perfiles .title p {
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  /*width: 53.6vw;*/
}
.Div_Equipo {
  widows: 20vw;
}

#section_usuarios .data p,
.title p,
#section_equipo .data p,
#section_equipo .title p,
#section_store .title p,
#section_store .data p,
#section_stock .data p,
#section_stock .title p,
#section_torneo .title p,
#section_perfiles .title p {
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
}

#section_usuarios .t1 {
  width: 15.76388888888889vw;
  height: 4.861111111111112vw;
}

#section_usuarios .d1 {
  width: 15.76388888888889vw;
  height: 3.8194444444444446vw;
  overflow: auto;
}
#section_equipo .t1 {
  width: 47.4vw;
  height: 4.861111111111112vw;
}

#section_equipo .d1 {
  width: 47.4vw;
  height: 3.8194444444444446vw;
}

#section_torneo .t1 {
  width: 23.7vw;
  height: 4.861111111111112vw;
}
#section_torneo .d1 {
  width: 23.8vw;
  height: 3.8194444444444446vw;
}

#section_perfiles .t1 {
  width: 13.5vw;
  height: 4.9vw;
}
#section_perfiles .d1 {
  width: 13.5vw;
  height: 3.8194444444444446vw;
}

#section_perfiles .t4 {
  width: 6.7vw;
  height: 4.9vw;
}
#section_perfiles .d4 {
  width: 6.7vw;
  height: 3.8194444444444446vw;
}
#section_perfiles .d4 p {
  color: var(--color-10);
}

#section_stock .t1 {
  width: 9.739583333333334vw;
  height: 4.9vw;
}

#section_stock .d1 {
  width: 9.72vw; /*14.543889vw*/
  height: 3.8194444444444446vw;
}

#section_store .d1 {
  width: 11.793889vw;
  height: 4.861111111111112vw;
}
#section_store .t1 {
  width: 11.793889vw;
  height: 3.8194444444444446vw;
}

#section_calendario .t1 {
  width: 10vw;
  height: 4.9vw;
}

#section_calendario .d1 {
  width: 10.06vw;
  height: 3.8194444444444446vw;
}
/*
#section_calendario .players .t1 {
  width: 12.1vw !important;
  height: 4.861111111111112vw;
}*/

#section_calendario .players .d1 {
  width: 12.1vw;
  height: 3.8194444444444446vw;
}

#section_calendario .t4 {
  width: 7vw;
  height: 4.9vw;
}

#section_calendario .d4 {
  width: 7vw;
  height: 4.791666666666667vw;
}

#section_calendario .t5 {
  width: 7vw;
  height: 4.9vw;
}

#section_calendario .players .d5 {
  width: 7vw;
  height: 3.8194444444444446vw;
}

#section_calendario .players .t5 {
  width: 7vw;
  height: 4.861111111111112vw;
}

#section_calendario .d5 {
  width: 7vw;
  height: 3.8194444444444446vw;
}

#section_calendario .data p,
#section_calendario .title p {
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
}

#section_subscriptores .row-data {
  height: 3.8194444444444446vw;
}

#section_galeria .row-data {
  height: 14.819444vw;
}
#section_galeria .data {
  margin-left: 0.06944444444444445vw;
}

#section_subscriptores .data p,
#section_galeria .data p {
  font-size: 0.7638888888888888vw;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}
/*
#section_subscriptores .t1,
#section_subscriptores .d1 {
  width: 52.44vw;
  height: 4.861111111111112vw;S
}
*/

#section_subscriptores .t1,
#section_subscriptores .d1 {
  width: 70.44vw;
  height: 4.9vw;
}

#section_galeria .t1 {
  width: 76.8vw;
  height: 4.861111111111112vw;
  /*margin-top: 2.65625VW;*/
}

#section_galeria {
  width: 57vw;
}

#section_galeria .d1 {
  width: 52.44vw;
  height: 14.819444vw;
}

#section_galeria .img-glly {
  margin-top: 1.2vw;
  height: 16.875vw;
  width: 16.875vw;
  margin-left: 1.2vw;
  transition: all 300ms;
  box-shadow: 0vw 0.1777777777777778vw 0.1777777777777778vw 0vw rgb(0 0 0 / 25%);
}

#section_galeria .img-glly:hover {
  height: 16.2vw;
  width: 16.2vw;
}

#section_galeria .img-sponsor {
  padding: 1vw;
  width: 12vw;

  margin-left: 1.2vw;
  transition: all 300ms;
}
.doble_sec {
  margin-left: 0vw !important;
}

.editar-sp {
  padding: 0.9vw 0vw !important;
}
.editar-sp {
  padding: 0.9vw 0vw !important;
  border-radius: 0vw !important;
}
.sponsor {
  /*height: 5vw;*/
}
#section_galeria .img-sponsor:hover {
  width: 12.2vw;
}

#section_subscriptores .t1 p,
#section_galeria .t1 p {
  text-align: center;
  margin-top: 1.7361111111111112vw;
}

#section_subscriptores .d1 p {
  margin-top: 0.772222vw;
  margin-bottom: 0vw !important;
}
/*
#section_galeria .item-galeria p {
  margin-top: 0.772222vw;
  margin-bottom: 0vw !important;
  width: 56%;
  text-align: center;
  font-size: 1.0416666666666665vw;
  }*/
#section_galeria .item-galeria p {
  margin-top: 0.772222vw;
  margin-bottom: 0vw !important;
  width: 56%;
  text-align: center;
  font-size: 1.0416666666666665vw;
  color: var(--color-8);
  font-family: "Rajdhani";
  font-weight: 800;
}

#section_subscriptores .t2 p,
#section_galeria .t2 p {
  margin-top: 1.7361111111111112vw;
}

#section_subscriptores .d2 img,
#section_galeria .d2 img {
  margin-left: -0.5vw;
  margin-top: -0.8vw;
  width: auto;
  height: 1.4583333333333333vw;
}

#section_subscriptores .d3 a img,
#section_galeria .d3 a img {
  margin-left: 2.083333333333333vw;
  margin-top: 1vw;
  width: auto;
  height: 1.4583333333333333vw;
}

.items-galeria {
  width: 100%;
  margin-top: 2vw;
}

.item-galeria {
  margin-top: 1vw;
  width: 26%;
  margin-left: 4.010416666666667vw;
  text-align: -webkit-center;
}
.title-img {
  font-weight: 800;
  font-size: 1.25vw;
}

.item-galeria .eliminar-modal {
  margin-top: 0vw;
  height: 3.2vw;
  margin-left: 0vw;
}

.item-galeria .itemgp {
  background: var(--color-2);
  border-radius: 2vw;
  width: 8.541666666666666vw !important;
}
.title-img {
  font-weight: 800;
  font-size: 1.25vw !important;
}

.item-galeria .eliminar-modal {
  margin-top: 0vw;
  /* height: 3.2VW; */
  margin-left: 0vw;
  vertical-align: baseline;
}

.item-galeria .itemgp {
  background: var(--color-2);
  border-radius: 2vw;
  width: 8.541666666666666vw !important;
  height: 2.34375vw;
}
.itemgp img {
  width: auto;
  height: 1.4583333333333333vw;
  margin-top: -0.5vw;
}
#Patroc_edit {
  width: 4.6875vw !important;
  height: 2.34375vw !important;
  -webkit-writing-mode: vertical-lr;
  padding-left: 1.4vw !important;
}

.galeria_cnt {
  width: 77vw;
  height: auto;
}

.cat-genre {
  width: fit-content;
  height: 1.875vw;
  border: 1px solid #8293a2;
  box-sizing: border-box;
  border-radius: 2vw;
  font-size: 0.677vw;
  line-height: 1.771vw;
  text-align: center;
  font-family: "Rajdhani";
  color: #8293a1;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0 1vw;
}

.cat-genre a {
  color: #8293a1;
}

.row-colors input {
  width: 10.438889vw !important;
}

#btn-agregar-min {
  margin-left: 19.4vw;
  padding: 1.544444vw 1.3vw;
  background-color: var(--color-7);
  color: var(--color-4);
  border: 0px;
  border-color: 0vw !important;
  border-radius: 1vw !important;
  font-size: 0.9027777777777777vw;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
}

#btn-agregar-min a:hover {
  color: var(--color-4) !important;
  text-decoration: none !important;
}

#btn-agregar-min img {
  margin-right: 0.5vw;
}

.h-prod {
  font-size: 2vw;
  color: var(--color-1);
}

.p-prod {
  font-size: 0.7vw;
  margin-bottom: 0vw;
}

.f_actual {
  color: var(--color-3);
  font-size: 0.5vw;
  margin-left: 4vw;
}

.e_text {
  text-align: center;
  margin-left: 1vw;
  color: var(--color-3);
  font-size: 0.7291666666666666vw;
}

#label_subtitle {
  font-size: 0.9375vw !important;
}

.row-data {
  height: 3.8194444444444446vw;
}

.data p {
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  width: 100%;
}

.clmn-one {
  overflow-y: auto;
  overflow-x: hidden;
}

.data p {
  text-align: center;
}

.t2 {
  background: var(--color-2);
}

.t1,
.t4,
.t3,
.t5,
.d3 {
  background: var(--color-1);
}
.d3 {
  background: none !important;
}

.title {
  color: var(--color-4);
  font-family: "Rajdhani";
  font-weight: 800;
}

.t2,
.t3,
.d2,
.d3 {
  width: 6.44vw;
  height: 4.9vw;
}
.d2,
.d3 {
  height: 3.8194444444444446vw;
}

.t1 p,
.t2 p,
.t4 p,
.t5 p {
  text-align: center;
  margin-top: 1.7361111111111112vw;
}

.d1 p,
.d4 p,
.d5 p {
  margin-top: 0.9722222222222222vw;
  margin-bottom: 0vw !important;
  font-size: 0.9vw !important;
  color: var(--color-8);
}

.d2 img {
  margin-left: -0.5vw;
  margin-top: -0.4vw;
  width: 1.4583333333333333vw;
  height: 1.4583333333333333vw;
}

.d3 a img {
  margin-left: 2.083333333333333vw;
  margin-top: 1vw;
  width: 1.4583333333333333vw;
  height: 1.4583333333333333vw;
}

/**MODAL section  */
button.eliminar-modal {
  border: none;
  margin-left: 2.1vw;
  margin-top: 0.8vw;
  width: 1.2vw;
  height: 1.2vw;
}
button.eliminar-modal:hover {
  background-color: transparent;
}
button.eliminar-modal:focus {
  outline: none;
}

.modal-content {
  min-height: 22.083333333333332vw !important;
  width: 34.569444vw !important;
  border: 0vw !important;
  border-radius: 0vw !important;
}

#modal-logout .modal-body {
  padding: 1vw !important;
}

.btn {
  border: 1px solid var(--color-3);
}

.btns {
  width: 10vw;
  color: var(--color-4);
  font-size: 1vw !important;
  border: 0px !important;
}

.btns:hover {
  border: 0px !important;
}
#stn-edituser .dropdown .btn:hover {
  background: var(--color-4) !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none;
}
#modal-logout .p-0 {
  padding: 1vw !important;
}
.icon3 {
  max-width: 4vw;
}
p.modalHeader {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
}
p.modalBody {
  font-size: 1vw;
  font-weight: lighter;
  font-family: "Inter", sans-serif;
}
.wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: auto;
  padding-left: 4.3vw;
  padding-top: 2vw;
}
.one {
  grid-column: 1;
  grid-row: 4;
  font-family: "Inter", sans-serif;
  background-color: var(--color-3);
  color: var(--color-4);
  border-color: var(--color-3);
}

.two {
  grid-column: 2;
  grid-row: 4;
  font-family: "Inter", sans-serif;
}

.btns {
  border-radius: 0vw !important;
}

.Jugadores_Nombre {
  width: 20vw !important;
  height: 4.9vw !important;
}
.Jugadores_Nacionalidad {
  width: 12vw !important;
  height: 4.9vw !important;
}
.Jugadores_Num {
  width: 12vw !important;
  height: 4.9vw !important;
}
.Jugadores_Equipo {
  width: 10vw;
  height: 4.9vw !important;
}
.Jugadores_Posicion {
  width: 10vw;
  height: 4.9vw !important;
}
.Jugadores_Editar {
  width: 6.44vw !important;
  height: 4.9vw !important;
}
.Jugadores_Borrar {
  width: 6.44vw !important;
  height: 4.9vw !important;
}

#JData_Name {
  width: 19vw !important;
}
#JData_Nationality {
  width: 13vw !important;
}
#JData_Num {
  width: 12vw !important;
}
#JData_TeamName {
  width: 10vw !important;
}
#JData_Position {
  width: 10vw !important;
}
#JData_Edit {
  width: 6vw !important;
}
#JData_Deleted {
  width: 6vw !important;
  padding-left: 0.5vw;
}

.Equipos_Equipo {
  width: 64vw !important;
  height: 4.9vw !important;
}
#EData_Name {
  width: 64vw !important;
}

.Posicion_posicion {
  width: 64vw !important;
  height: 4.9vw !important;
}
#POData_Name {
  width: 64vw !important;
}

.Tienda_Producto {
  height: 4.9vw !important;
  width: 16vw !important;
}
.Tienda_Precio {
  height: 4.9vw !important;
  width: 16vw !important;
}
.Tienda_Stock {
  height: 4.9vw !important;
  width: 16vw !important;
}
.Tienda_Codigo {
  height: 4.9vw !important;
  width: 16vw !important;
}
#TIData_Name {
  width: 16vw !important;
}
#TIData_Price {
  width: 16vw !important;
}
#TIData_Stock {
  width: 16vw !important;
}
#TIData_Code {
  width: 16vw !important;
}

.Categoria_categoria {
  width: 32vw !important;
  height: 4.9vw !important;
}
.Categoria_Padre {
  width: 32vw !important;
  height: 4.9vw !important;
}
#CATData_Name {
  width: 32vw !important;
}
#CATData_Parent {
  width: 32vw !important;
}

.Colores_Color {
  width: 64vw !important;
  height: 4.9vw !important;
}
#COData_Name {
  width: 64vw !important;
}

.Tallas_Talla {
  width: 64vw !important;
  height: 4.9vw !important;
}
#TAData_Name {
  width: 64vw !important;
}

.Blog_Titulo {
  width: 21.4vw !important;
  height: 4.9vw !important;
}
.Blog_Autor {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
.Blog_Fecha {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
#BData_Title {
  width: 21.4vw !important;
}
#BData_Autor {
  width: 21.3vw !important;
}
#BData_Date {
  width: 21.3vw !important;
}

.Videos_Titulo {
  width: 21.4vw !important;
  height: 4.9vw !important;
}
.Videos_Fecha {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
.Videos_Url {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
#VData_Title {
  width: 21.4vw !important;
}
#VData_DateVideo {
  width: 21.3vw !important;
}
#VData_Url {
  width: 21.3vw !important;
}

.Canales_Canal {
  width: 64vw !important;
  height: 4.9vw !important;
}
#CANData_Name {
  width: 64vw !important;
}

.Ligas_Nombre {
  width: 64vw !important;
  height: 4.9vw !important;
}
#LData_Name {
  width: 64vw !important;
}

.Torneos_Torneo {
  width: 32vw !important;
  height: 4.9vw !important;
}
.Torneos_Liga {
  width: 32vw !important;
  height: 4.9vw !important;
}
#TOData_Name {
  width: 32vw !important;
}
#TOData_Liga {
  width: 32vw !important;
}

.Usuarios_Nombre {
  width: 21.4vw !important;
  height: 4.9vw !important;
}
.Usuarios_Apellido {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
.Usuarios_Correo {
  width: 21.3vw !important;
  height: 4.9vw !important;
}
#UData_Name {
  width: 21.4vw !important;
}
#UData_LastName {
  width: 21.3vw !important;
}
#UData_Email {
  width: 21.3vw !important;
}

/**FIN Modal */

/** Section edit user */
#stn-edituser {
  margin-left: 3.3333333333333335vw;
  margin-top: 6.944444444444445vw;
  border-radius: 2.604166666666667vw !important;
  border: 1px solid var(--color-8) !important;
  width: 74vw;
}

#stn-edituser li {
  font-size: 0.8333333333333334vw;
  padding-bottom: 0vw;
  padding-top: 0vw;
  margin-bottom: 0vw;
}

.stn_edituserAdd {
  margin-top: 2vw !important;
}

.CEF1_B1 {
  margin-left: 15.135416666666668vw;
}
.CEF1_B2 {
  margin-left: 3.0208333333333335vw;
}
.CEF2_B1 {
  margin-left: 15.135416666666668vw;
}
.CEF2_B2 {
  margin-left: 3.0208333333333335vw;
}
.CEF3_B1 {
  margin-left: 8vw;
}
.CEF4_B1 {
  margin-left: 15.135416666666668vw;
}
.CEF4_B2 {
  margin-left: 3.0208333333333335vw;
}
.CEF5_B1 {
  margin-left: 8vw;
}

#Label_AgrCanales {
  margin-left: 8vw !important;
}
.div_Canal {
  margin-left: 10vw !important;
}

.Title_AgregarP {
  width: 100%;
  height: 4.791666666666667vw;
  background-color: var(--color-8);
  margin-top: 2.65625vw;
  margin-bottom: 3.6458333333333335vw;
  text-align-last: center;
}
.Title_AgregarP p {
  color: var(--color-4);
  font-size: 1.25vw;
  font-weight: 800;
  padding-top: 1.7361111111111112vw;
}

.F1_C1 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
  margin-left: 8vw;
}
.F1_C2 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
}
.F1_C3 {
  width: 17.1875vw;
}

.F2_C1 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
  margin-left: 8vw;
}
.F2_C2 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
}
.F2_C3 {
  width: 17.1875vw;
}

.F3_C1 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
  margin-left: 8vw;
}
.F3_C2 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
}
.F3_C3 {
  width: 17.1875vw;
}

.F4_C1 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
  margin-left: 18vw;
}
.F4_C2 {
  width: 17.1875vw;
  margin-right: 2.8125vw;
}

.div_Stock {
  width: 42.1268vw;
  padding-top: 2.65625vw;
  /*padding-bottom: 2.5vw;*/
  margin-left: 16.614583333333332vw;
  border-radius: 2.604166666666667vw !important;
  border: 1px solid var(--color-8) !important;
}
#Stock_transparent {
  background: transparent;
}

.div_EProduct {
  width: 100%;
  height: 4.791666666666667vw;
  background-color: var(--color-8);
  margin-top: 2.65625vw;
  margin-bottom: 3.6458333333333335vw;
  /*text-align-last: center;*/
}
.div_EProduct p {
  color: var(--color-4);
  font-family: "Rajdhani";
  font-size: 1.25vw;
  font-weight: 800;
  padding-top: 1.7361111111111112vw;
  text-align: center;
}

.div_EPForm {
  margin-bottom: 2vw;
}

.EPF1_C1 {
  margin-left: 18vw !important;
}
.EPF1_C1centrado {
  margin-left: 26.5vw !important;
  text-align: -webkit-center;
}
.Button_Center {
  margin-left: 31vw !important;
}
.EPF1_C2 {
}

.EPF2_C1 {
  margin-left: 8vw !important;
}

.EPSock_text {
  width: 5vw;
  height: 2vw !important;
  margin-top: 1vw;
  margin-left: 17vw;
}

.btn-group,
.btn-group-vertical {
  display: revert !important;
}

#stn-edituser label {
  font-size: 1.25vw;
  font-weight: 600;
  font-style: normal;
  color: var(--color-8);
  line-height: 1.3888888888888888vw;
  /*margin-left: 1vw;*/
  text-transform: uppercase;
  letter-spacing: 0.1388888888888889vw;
  height: 2.34375vw !important;
  width: 19.21875vw !important;
  border-radius: 2.604166666666667vw !important;
}

.Div_EditPart {
  width: 100%;
  height: 4.791666666666667vw;
  background-color: var(--color-8);
  margin-top: 2.65625vw;
  margin-bottom: 3.6458333333333335vw;
  /*text-align-last: center;*/
}
.Div_EditPart p {
  color: var(--color-4);
  font-size: 1.25vw;
  font-weight: 800;
  padding-top: 1.7361111111111112vw;
  text-align: center;
}

.Div_ImagesCenter {
  margin-left: 16vw;
}

.row-one input,
.row-two input,
.row-two button,
.row-three button {
  border: 1px solid var(--color-3);
  font-size: 0.9375vw;
}

.row-one button {
  font-size: 0.6vw !important;
}

.row-two .form-control,
.row-two .btn,
.row-one .form-control,
.row-one .btn,
.row-three .form-control,
.row-three .btn {
  border-radius: 0vw !important;
  background: var(--color-4) !important;
  color: var(--color-3) !important;
  border-color: var(--color-3) !important;
  border-radius: 2.604166666666667vw !important;
  width: 17.1875vw !important;
  height: 2.34375vw;
  border: 1px solid var(--color-8) !important;
}

.dropdown-toggle::after {
  margin-left: 14vw !important;
  margin-top: -0.9vw !important;
  border-top: 0.5vw solid !important;
  vertical-align: 0.7vw !important;
  border-right: 0.3vw solid transparent !important;
  color: var(--color-3);
  border-left: 0.3vw solid transparent !important;
  width: 0vw !important;
  display: flex !important;
}

#stn-edituser .row-two .drop-text,
.row-three .drop-text {
  margin-left: 1vw !important;
  margin-bottom: 0.2vw;
  /*margin-top: 0.4vw;*/
}

.Input_vs {
  width: 20.729166666666668vw !important;
}

.drop-text {
  margin-left: 1vw;
  font-size: 0.9375vw !important;
}

#stn-edituser .row-two .dropdown-toggle::after {
  margin-left: 14vw !important;
  margin-top: -0.8vw;
}

.show > .btn-secondary.dropdown-toggle {
  border-color: #d0d0d0 !important;
}

.dropdown-menu {
  background: var(--color-6) !important;
  border-color: var(--color-6);
  border-radius: 0vw !important;
  border: 1px solid var(--color-6) !important;
  max-height: 10vw;
  width: 16.7vw !important;
  overflow-y: scroll;
}

.dropdown-item {
  color: var(--color-4) !important;
  text-decoration: none;
  padding: 0.3vw 0.2vw !important;
  font-size: 0.6vw;
}

.dropdown-item:hover {
  background: linear-gradient(
    90deg,
    var(--color-gr-1) 1.59%,
    var(--color-gr-2) 98.15%
  ) !important;
  color: var(--color-4) !important;
}

.row-one input {
  /*width: 26.498333VW;*/
  height: 3.6111111111111107vw;
  margin-right: 0.5555555555555556vw;
  /*padding-left: 2.46vw;*/
}

.row-two input,
.row-three input {
  /*padding-left: 2.46vw;*/
}

.btn-secondary {
  color: var(--color-4);
  background: var(--color-1) !important;
}

.btn-secondary:hover {
  background: var(--color-2) !important;
}
#inputP::placeholder,
#inputImg::placeholder {
  font-size: 0.7638888888888888vw;
  color: var(--color-3);
}
.custom-file-label {
  border-radius: 0vw !important;
  background: var(--color-4) !important;
  color: var(--color-3) !important;
  border-color: var(--color-3) !important;
  width: 26.498333vw !important;
  height: 3.6111111111111107vw !important;
  padding: 1vw !important;
  margin-right: 0.4166666666666667vw !important;
  margin-left: 0vw !important;
  border: 1px solid var(--color-3) !important;
  font-size: 0.7638888888888888vw !important;
  border: 1px solid var(--color-8) !important;
}

#EditUser .custom-file-label {
  width: 19.21875vw !important;
}
#EditUser .custom-file-label::after {
  right: 0vw !important;
}

#EditUser .div-image {
  width: 17vw;
}

.custom-file-label::after {
  height: 3.6111111111111107vw !important;
  width: 3.6111111111111107vw !important;
  padding: 0vw 1vw !important;
  color: var(--color-4) !important;
  background-color: var(--color-8) !important;
  border-radius: 0vw !important;
  content: "\2B71" !important;
  font-size: 1.763889vw !important;
}
.custom-file-label span {
  font-size: 0.7638888888888888vw !important;
  color: var(--color-3) !important;
  margin-top: -0.5vw !important;
}

#inputI .custom-file-label {
  background: black !important;
}

.bannerinputs .custom-file-label {
  width: 25.498333vw !important;
}
#inputPV {
  font-size: 0.7638888888888888vw !important;
}

#stn-edituser .row-two input {
  margin-right: 0.3472222222222222vw;
}
.row-two input,
.row-two button,
.row-three input,
.row-three button,
.row-one button {
  width: 17.63888888888889vw;
  height: 3.6111111111111107vw;
  margin-right: 0.625vw;
}

.imagen-banner img {
  width: 12vw;
}

.imagen-banner {
  display: grid;
  margin-left: 2vw;
}

#stn-edituser .row-two {
  margin-top: 1.6666666666666667vw;
}

.mtrow {
  margin-top: 1.6666666666666667vw;
}

.div-image {
  width: 26vw;
  margin-bottom: 1.6666666666666667vw;
  margin-left: 18vw;
}
.div-image3 {
  width: 26vw;
  margin-bottom: 1.6666666666666667vw;
  margin-left: 9vw;
}
.div-image2 {
  width: 20vw;
  margin-bottom: 1.6666666666666667vw;
}
.QUITLEFT {
  margin-left: 0vw !important;
}
.Div_ImageTop {
  margin-top: 2vw;
}

.div-image-video {
  width: 26vw;
  margin-bottom: 1.6666666666666667vw;
  margin-left: 0vw;
}

.calendar .Input_VS {
  width: 20.729166666666668vw !important;
}
.calendar .Input_Fecha {
  width: 19.53125vw !important;
}
.calendar .Input_RC {
  width: 20.729166666666668vw !important;
}
.calendar .Input_RO {
  width: 19.53125vw !important;
}
.calendar .Input_HorarioDe {
  width: 20.729166666666668vw !important;
}
.calendar .Input_TP {
  width: 19.53125vw !important;
}

.btn-form button {
  margin-top: 2vw;
  margin-bottom: 4.84375vw;
  margin-left: 50.3vw;
  width: 13.125vw;
  height: 4.444444444444445vw;
  background-color: var(--color-7);
  color: var(--color-4);
  border: 0px;
  border-color: 0vw !important;
  border-radius: 1vw !important;
  font-size: 0.9027777777777777vw;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
}

.btn-form button:hover {
  background: var(--color-1);
}

#EditPartbtn_Guardar1 {
  margin-left: 4.1vw;
}

.btn-form-2 button {
  margin-top: -4vw;
  margin-left: 40.48vw;
  width: 13.125vw;
  height: 4.444444444444445vw;
  background-color: var(--color-7)!important;
  color: var(--color-4);
  border: 0px;
  border-color: 0vw !important;
  border-radius: 1vw !important;
  font-size: 0.9027777777777777vw;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
}

.btn-form-2 button:hover {
  background: var(--color-1);
}

#EditPartbtn_Agregar {
  margin-left: 10vw;
}

.canales {
  overflow: hidden;
  width: 53.7vw;
  margin-top: 3vw;
  margin-left: 10.052083333333334vw;
  border-radius: 2.604166666666667vw !important;
  border: 1px solid var(--color-8) !important;
}

.images_gallery {
  overflow: hidden;
  width: 53.7vw;
  margin-top: 2vw;
}

.images_gallery .row {
  margin-left: 0vw;
  margin-right: 0vw;
}

.canales .row {
  margin-left: 0vw;
  margin-right: 0vw;
}

.channel_titles p,
.item_channel p {
  font-family: "Lato";
  font-size: 1.25vw;
  font-weight: 800;
  width: 17.85vw;
  padding: 1vw;
  text-align: center;
}

.gallery_titles p,
.item_gallery p {
  font-family: "Lato";
  font-size: 0.8vw;
  font-weight: 400;
}

.gallery_titles .gt1 p {
  width: 36vw;
  padding: 1vw;
  text-align: center;
}

.item_gallery .gt1 p {
  width: 36vw;
  text-align: center;
}

.gallery_titles .gt2 p,
.item_gallery .gt2 p {
  width: 6vw;
  padding: 1vw;
  text-align: center;
}

.item_gallery p {
  font-family: "Lato";
  font-size: 0.8vw;
  font-weight: 400;
  width: 14vw;
  border-bottom: 0.1vw solid var(--color-5);
  height: 9.5vw;
}

.item_channel p {
  font-family: "Lato";
  font-size: 0.8vw;
  font-weight: 400;
  width: 17vw;
  /*border-bottom: 0.1vw solid var(--color-5);*/
  height: 6vw;
}

.msg_channels {
  font-family: "Lato";
  font-size: 0.8vw;
  font-weight: 400;
  /*border-bottom: 0.1vw solid var(--color-5);*/
  /*width: 42vw;*/
  text-align: center;
  margin-bottom: 2vw;
  margin-left: 0vw;
}
.msg_channels p {
  color: var(--color-8) !important;
}

#div_canales {
  margin-top: 2.65625vw;
}

.channel_titles p {
  color: var(--color-4);
  background: var(--color-1);
  /*border-right: 0.1vw solid var(--color-4);*/
}

.gallery_titles p {
  color: var(--color-4);
  background: var(--color-1);
  border-right: 0.1vw solid var(--color-4);
}

.item_channel img {
  height: 4vw;
  max-width: 12vw;
}

.item_gallery img {
  height: 8vw;
  max-width: 26vw;
}
.eliminar-gallery {
  color: var(--color-2);
  cursor: pointer;
  transition: all 300ms;
}

.eliminar-gallery:hover {
  font-size: 1vw;
}

.eliminar-channel {
  color: var(--color-2);
  cursor: pointer;
  transition: all 300ms;
}

.eliminar-channel:hover {
  font-size: 1vw;
}

hr {
  background: var(--color-4);
  height: 0.5vw;
  border-width: 0;
  margin-left: 0vw;
  width: 53.65vw;

  text-align: center;
}

.mod-hr {
  width: 75.1vw;
  margin-left: 2.7vw;
  margin-bottom: 1.6666666666666667vw;
}

.row-hour input {
  height: 3.6111111111111107vw;
  width: 6.32vw;

  border-radius: 0vw !important;
  background: var(--color-4) !important;
  color: var(--color-3) !important;
  border-color: var(--color-3) !important;
  border: 1px solid var(--color-3);
  font-size: 0.7638888888888888vw;
  margin-bottom: 0.625vw;
}

.row-hour .row {
  margin-right: 0vw;
  margin-left: 0vw;
}

.row-hour p {
  margin-right: 0.625vw;
}

.row-hour label {
  margin-left: 0.625vw !important;
  margin-right: 0.625vw;
  font-size: 0.7vw !important;
}

.txthour {
  font-size: 0.7638888888888888vw !important;
}

.tham {
  margin-top: 3vw;
}

.thpm {
  margin-top: 2.2vw;
}
.row-hour .form-control {
  text-align: center;
}

/** fin secciones*/

/** Modal 2 edit add*/
#modal-editar .modal-content,
#modal-error .modal-content {
  min-height: 22.083333333333332vw !important;
  width: 34.569444vw !important;
  border: 0vw !important;
  border-radius: 0vw !important;
}

#modal-registro .modal-content {
  min-height: 19.083333vw !important;
  width: 34.569444vw !important;
  border: 0vw !important;
  border-radius: 0vw !important;
}

#modal-editar .modal-body,
#modal-registro .modal-body,
#modal-error .modal-body {
  padding: 0vw !important;
}

#modal-editar .modal-header,
#modal-registro .modal-header,
#modal-error .modal-header {
  padding: 0vw !important;
  border-bottom: none !important;
  border-bottom: none !important;
  margin-top: 1.3888888888888888vw;
  margin-right: 2.083333333333333vw;
}

#modal-editar img,
#modal-registro img {
  margin-top: 3.125vw;
  margin-left: 15.375vw;
  width: 4.305555555555555vw;
  height: 4.305555555555555vw;
}

#modal-error img {
  margin-top: 1.125vw;
  margin-left: 15.375vw;
  width: 4.305556vw;
  height: 6.305556vw;
}

#modal-editar button,
#modal-registro button,
#modal-error button {
  font-family: "Rajdhani" !important;
}

#modal-editar .close-modal:hover,
#modal-registro .close-modal:hover,
#modal-error .close-modal:hover {
  background: transparent !important;
}

.close-modal {
  font-size: 2.388889vw !important;
  color: var(--color-6) !important;
  opacity: 1 !important;
  background: none !important;
  border: none !important;
  margin-top: -34vw;
  margin-left: 31vw;
}

#modal-error .close-modal {
  margin-top: -34vw;
}
.close-modal:hover {
  border-color: none !important;
  box-shadow: none !important;
}

#modal-editar p,
#modal-registro p,
#modal-error p {
  font-family: "Rajdhani" !important;
  font-size: 1.1111111111111112vw;
  font-style: normal;
  font-weight: 600;
  margin-top: 2.8472222222222223vw;
  color: var(--color-6);
  margin-bottom: 0vw !important;
}

#modal-editar p {
  margin-left: 8.222222vw;
}

#modal-error p {
  text-align: center;
  padding: 1vw;
  margin-top: 1vw;
}

.btn:active {
  background-color: var(--color-4) !important;
  border-color: var(--color-4) !important;
}

#section_galeria .btn:active {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}

.btns-dflx .btn-modal {
  margin-left: 1.5vw !important;
  width: 9.452778vw !important;
}

.btn-void {
  width: 6vw;
}
.btn-modal {
  color: var(--color-4) !important;
  margin-left: 11.5vw;
  margin-top: 2.5vw;
  border-radius: 0vw !important;
  background-color: var(--color-1) !important;
  border: 0vw !important;
  width: 12.152777777777777vw;
  height: 3.4027777777777777vw;
  letter-spacing: 0.1388888888888889vw;
  font-size: 1vw !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none !important;
}

/** fin modal 2 */

/** Logout */

.logout {
  margin-top: -15vw;
  margin-left: 78.1vw;
}

button.logout {
  width: 10.78vw;
  height: 1.4vw;
  border: 0.06944444444444445vw solid var(--color-2) !important;
  color: var(--color-4);
  font-size: 0.9375vw;
  font-weight: bolder;
  padding: 0vw !important;
}
button.logout p {
  font-size: 0.9375vw;
  padding-right: 2vw;
  margin-bottom: 0vw;
}

button.logout:hover,
button.logout:active {
  background-color: var(--color-2) !important;
  border: 0px !important;
  border-color: var(--color-2) !important;
  color: var(--color-4);
}
button.logout img {
  width: auto;
  height: 1.6630208333333334vw;
  margin-top: -1.5vw;
}

/* login */

.login-page {
  height: 53.333vw;
  background-image: url("./assets/fondo-log.png");
  background-size: 100vw;
  background-repeat: no-repeat;
}

.a-signin {
  text-decoration: none;
  color: var(--color-1);
  font-size: 0.7vw;
  text-transform: uppercase;
  font-weight: 700;
  transition: 0.5s;
}
.reg-txt {
  text-decoration: none;
  color: #ce1526;
  font-size: 0.7vw !important;
  text-transform: uppercase;
  font-weight: 700;
  align-self: center;
}

.a-signin:hover {
  text-decoration-color: var(--color-1);
  color: var(--color-1);
}

.code-page {
  height: 48.8vw;
}

.main-container {
  height: inherit;
}
.container-flex {
  display: flex;
}

.col-logo {
  display: flex;
  width: 100%;
  justify-content: center;
}
.col-logo img {
  width: 8.906vw;
  height: auto;
}
.titulo-h4 {
  font-weight: 800;
  font-size: 2.604vw;
  line-height: 2.969vw;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #032a4b;
  text-align: center;
  padding-top: 2.656vw;
  font-family: "Rajdhani";
}
.lb-form {
  font-weight: bold;
  font-size: 0.938vw !important;
  line-height: 1.458vw;
  text-transform: uppercase;
  padding-bottom: 0.885vw;
  font-family: "Rajdhani";
}
.col-one,
.col-one-code {
  background-image: url("./assets/Login_Web.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.col50 {
  width: 50%;
}
#login .form-container {
  width: 25.313vw;
  margin: auto;
  padding-top: 3.802vw;
}
#code-page .form-container-code {
  width: 35vw;
  margin: auto;
  padding-top: 5vw;
}

.col-two h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  font-weight: bold;
  margin: 0;
  font-size: 3.2rem;
  text-align: left;
}

.col-two img {
  margin-top: 10vh;
  width: 29.011vw;
  height: 8.3vw;
}

form#loginapp {
  margin-top: 3.5vw;
}

.sm-logo {
  width: auto;
  height: 1.129vw;
  transition: 0.5s;
}
.sm-cont-log {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 6vw;
}
.sm-logo:hover {
  filter: brightness(3.5);
}

form#logincodeapp {
  margin-top: 3vw;
}
#logincodeapp label {
  margin: 0;
  width: 100%;
  margin-top: 2vw;
  margin-bottom: 2vw;
  font-size: 1vw;
  text-align: left;
}

.form-solicitar-codigo {
  margin-top: 2vw;
}
.form-reenviar-codigo {
  text-decoration: none !important;
}

.form-reenviar-codigo {
  color: var(--color-3);
}

.form-reenviar-codigo :hover {
  color: var(--color-1);
  cursor: pointer;
}

.form-container p {
  font-size: 0.9rem;
}
.error-code {
  margin-top: 0vw;
}

p.error-code {
  color: var(--color-2);
  font-size: 0.9rem;
}

p.error {
  color: var(--color-2);
  font-size: 0.9rem;
}

.row-block {
  margin-bottom: 1.979vw;
}

.row-block label {
  margin: 0;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 0vh;
}
.form-container input,
.form-container-code input {
  border: 1px solid var(--color-3);
  background-color: transparent;
  width: 100%;
  height: 4.01vw;
  font-size: 1.2rem;
  color: var(--color-3);
  transition: 0.5s;
  padding: 0 2vw;
}

.form-container input:focus {
  outline: 1px solid var(--color-1);
  background: transparent;
}

.form-container input[type="submit"]:focus,
.form-container input[type="button"]:focus {
  outline: 1px solid var(--color-2);
  background-color: rgb(133, 18, 18);
  background-image: url("./assets/flecha.png");
  background-repeat: no-repeat;
  background-position-y: 1.7vw;
  background-position-x: 19.74vw;
  background-size: 3.073vw;
}

::placeholder {
  color: var(--color-3);
  font-size: 1.2rem;
}

input[type="submit"],
input[type="button"] {
  background: var(--color-2);
  border-radius: 0vw;
  height: 4.01vw;
  font-weight: bold;
  font-size: 0.938vw;
  line-height: 1.094vw;
  font-family: "Rajdhani";
  text-transform: uppercase;
  color: #ffffff;
  border-bottom: 0px;
  cursor: pointer;
  border: none;
  background-image: url("./assets/flecha.png");
  background-repeat: no-repeat;
  background-position-y: 1.7vw;
  background-position-x: 19.74vw;
  background-size: 3.073vw;
  transition: 0.5s;
}
input:focus {
  outline: 1px solid #ce1526;
  background: transparent;
}
input[type="submit"]:hover,
input[type="button"]:hover {
  background-color: rgb(133, 18, 18);
}

.btn-login {
  margin-top: 1vw;
}

.txt-version {
  text-align: center;
  font-size: 1vh;
  width: 100%;
  margin-bottom: 0;
}
.v-reg-cont {
  height: 5.521vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.center {
  margin: auto;
  text-align: center;
  margin-left: 8.3vw;
}

.user {
  max-width: 70px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 0.7vw;
  margin-bottom: 0.5vw;
}

#AddUser .div-error,
#EditUser .div-error {
  margin-left: 35.7vw;
}

/* paypal */

.metodos_pago {
  margin-top: 1vw;
}

/** Fin paypal styles */
.product_image .vueperslides__parallax-wrapper {
  height: 24.895833333333332vw !important;
}
.product_image .vueperslides__bullets {
  display: none;
}
.product_imgs .vueperslides__parallax-wrapper {
  position: relative;
  overflow: hidden;
  height: 5.3125vw !important;
}

/**ERROR 404 */
.errore-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.errore-cont .bandera {
  height: 9.479vw;
  width: auto;
}
.errore-cont .offside {
  font-size: 4.688vw;
  font-family: "Rajdhani";
  color: #b80c18;
}
.errore-cont .cuatrocientoscuatro {
  font-size: 15.625vw;
  font-family: "Rajdhani";
  color: #032a4b;
  line-height: 12vw;
}
.errore-cont .textifero-1,
.errore-cont .textifero-2 {
  width: 21.719vw;
  text-align: center;
}
.errore-cont .textifero-1 {
  font-size: 1.302vw;
  font-family: "Rajdhani";
  color: #032a4b;
  padding-top: 2vw;
}
.errore-cont .textifero-2 {
  font-size: 0.938vw;
  font-family: "Rajdhani";
  color: #032a4b;
}
.errore-cont .related-page-cont {
  padding-top: 2vw;
  width: 9vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.errore-cont .related-page-cont a,
.errore-cont .related-page-cont p {
  color: #b70d1d;
  font-size: 0.781vw;
  font-family: "Rajdhani";
  margin: 0;
}
.errore-cont .related-page-cont a {
  text-decoration-line: underline;
}
.errore-cont .related-page-cont a:hover {
  color: #b70d1d !important;
}

.en-construccion-cont {
  display: flex;
  align-items: center;
  width: 34vw;
  justify-content: space-between;
}
.en-construccion-cont .tarjetiza {
  width: 10.052vw;
  height: auto;
}
.en-construccion-cont .titulo-en-constr {
  font-weight: bold;
  font-size: 1.823vw;
  line-height: 118.49%;
  font-family: "Rajdhani";
  color: #032a4b;
  width: 21vw;
}
.en-construccion-cont .titulo-en-constr .red {
  color: #b70d1d;
}
.errore.posting {
  padding: 6.302vw 1.927vw;
}

/* Media querys */
@media (max-width: 768px) {

   .modal_view_checkout {
  z-index: 900;
  background-color: rgb(164 164 164 / 32%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
  
}
#cnt-mdl-checkout{

    height: 70%;
    padding: 4%;
    width: 91%;
    margin-left: 4.8%;
    margin-top: 30%;
}


img.close_modal {
    margin-left: 91%;
    width: 6vw;
    height: 5vw;
    margin-bottom: 4vw;
}

  .modal2.gale .prev:hover,
  .modal2.gale .next:hover {
    font-size: 9vw;
  }
  .modal2.gale .prev,
  .modal2.gale .next {
    font-size: 11vw;
  }
  .en-construccion-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vw;
    width: 50vw;
  }
  .en-construccion-cont .tarjetiza {
    width: 21.981vw;
    height: auto;
  }
  .en-construccion-cont .titulo-en-constr {
    width: 70vw;
    font-size: 5.314vw;
    text-align: center;
  }
  .en-construccion-cont .errore.posting {
    padding: 14.734vw 1.927vw;
  }
  #res-contenido .errore {
    width: 100%;
  }

  /*404 ERROR*/
  .errore-cont .bandera {
    height: 20.29v;
  }
  .errore-cont .offside {
    font-size: 7.246vw;
    margin-top: 13.043vw;
  }
  .errore-cont .cuatrocientoscuatro {
    font-size: 31.401vw;
    line-height: 33vw;
  }
  .errore-cont .textifero-1,
  .errore-cont .textifero-2 {
    width: 55.719vw;
  }
  .errore-cont .textifero-1 {
    font-size: 4.348vw;
  }
  .errore-cont .textifero-2 {
    font-size: 3.865vw;
  }
  .errore-cont .related-page-cont a,
  .errore-cont .related-page-cont p {
    font-size: 3.865vw;
  }
  .errore-cont .related-page-cont {
    padding-top: 9vw;
    width: 40vw;
  }

  .product_image .vueperslides__parallax-wrapper {
    height: 62.07729468599034vw !important;
  }
  .product_image .vueperslides {
    position: relative;
    width: 57.971014492753625vw;
  }
  .product_image .vueperslides__bullet .default {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: none;
    background-color: #ababab;
  }
  .product_image .vueperslides__bullet--active .default {
    border-width: 3vw;
    background: #032a4b;
  }
  .product_image .vueperslides__bullets {
    top: 75vw;
  }
  .product-cont-sl {
    padding-bottom: 20vw;
  }
  .product_image .vueperslides__bullets {
    display: flex;
  }
  /**LOGIN */
  .login-page {
    margin-top: 0 !important;
    height: 228.261vw !important;
    width: 100%;
    background-image: url("./assets/login-fondo-m.png");
    background-size: 100vw;
  }
  #login .form-container {
    width: 77.295vw;
    margin: auto;
    padding-top: 22.947vw;
  }
  .col-logo img {
    width: 34.3vw;
    height: auto;
  }
  .titulo-h4 {
    padding-top: 9.42vw;
    font-size: 7.246vw;
    line-height: 8.213vw;
    letter-spacing: 0.115em;
  }
  form#loginapp {
    margin-top: 10.628vw;
  }
  .lb-form {
    font-size: 3.623vw !important;
    line-height: 6.763vw !important;
  }
  #login .form-container input {
    border: 2px solid gainsboro;
    width: 100%;
    height: 18.599vw;
    font-size: 3.623vw;
    text-align: left;
    background-position-x: 56.522vw;
    background-position-y: 8.454vw;
    background-size: 9.179vw;
    padding-left: 7vw;
  }
  .row-block {
    margin-bottom: 7.729vw;
  }
  .v-reg-cont {
    height: 22.459vw;
  }
  .a-signin {
    font-size: 3.7vw;
  }
  .reg-txt {
    font-size: 3.7vw !important;
  }
  .sm-logo {
    height: 4.094vw;
  }
  .sm-cont-log {
    padding: 0 16vw;
  }

  /**HEADER HOME */
  .content-hh {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 99;
  }
  .logo-hh {
    width: 8.937vw;
    height: 8.937vw;
    margin-top: 0;
    margin-right: 8.213vw;
  }
  #headerHome {
    height: auto;
    position: fixed;
    z-index: 999;
  }
  .sec2-hh {
    order: 2;
    margin: 1.932vw 7.677083vw;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h3.no-matches {
    font-size: 2vw;
    margin: 0;
    padding: 3vw 0 3vw 7vw;
  }
  .buscador-hh {
    display: none;
  }
  .iconarrow {
    display: none;
  }
  .iconpartido {
    height: 5.314vw;
    margin-right: 3.382vw;
    position: relative;
  }
  .sec1-hh {
    height: auto;
    display: flex;
    align-items: center;
    padding: 0.91vw 0;
  }
  h3.mt1 {
    display: none;
  }
  .sec1-hh span.linea {
    display: none;
  }
  .sec1-hh .mt2 {
    font-family: "Rajdhani";
    padding-left: 4.063vw;
    font-size: 2.174vw;
    width: 78%;
    display: flex;
    margin: 0;
    padding: 2vw 0 2vw 4.063vw;
  }
  img.patrocinador1 {
    display: block;
    position: absolute;
    top: 2.3vw;
    left: 48vw;
    width: 19.565vw;
    height: auto;
  }
  .comprar-boletos {
    width: 33.575vw;
    height: 8.213vw;
    font-size: 1.932vw;
    /*padding: 3.140vw 1.5vw;
    margin-left: 14.734vw;*/
    margin-right: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /**DROPDOWN */
  #check1 {
    display: none;
  }
  .sec5-hh {
    order: 1;
    display: none;
    position: absolute;
    list-style-type: none;
    z-index: 99;
    background-color: black;
    top: 19vw;
  }
  .burger-cont {
    display: block;
  }
  .burger-cont img {
    width: 4.263vw;
    height: auto;
    cursor: pointer;
  }
  .sec5-hh-mo .ushop-cont-mo a {
    height: 4vw !important;
  }
  .sec5-hh-mo img.btn_iconuser {
    width: 4.263vw;
  }
  .sec5-hh-mo .ushop-cont-mo {
    width: 11vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sec5-hh-mo {
    order: 1;
    max-height: 0;
    position: absolute;
    list-style-type: none;
    z-index: 999;
    background-color: transparent;
    top: 24.15vw;
    left: 0;
    width: 100%;
    color: white;
    overflow: hidden;
    opacity: 0;
    transition: max-height 1s, opacity 1s;
  }
  .sec5-hh-mo li {
    opacity: 0;
    overflow: hidden;
    display: flex;
    font-size: 3.299vw;
    align-items: center;
    justify-content: space-between;
    font-family: "Rajdhani";
    margin: auto 7.729vw;
    padding: 3.831vw 2.899vw;
    border-bottom: 1px solid #9f111f;
  }
  .sec5-hh-mo .user-hh-mo {
    display: none;
    padding: 7.246vw;
    background: black;
  }
  .sec5-hh-mo .btnslog-mo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sec5-hh-mo #buscador-hh {
    margin-top: 0;
    margin-left: 0;
    border: 0.1vw solid var(--color-4);
    border-radius: 10vw;
    width: 63.768vw;
    height: 10.87vw;
    margin-bottom: 0;
    background: white;
  }
  .sec5-hh-mo .form-control-hh {
    margin-left: 0.6vw;
    width: 50vw;
    background: transparent;
    color: black;
    font-size: 2.899vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }
  .sec5-hh-mo .btn-de-busqueda {
    background-color: transparent !important;
    background-image: url("./assets/lupa.png");
    background-size: 4.106vw;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
  .sec5-hh-mo .btn-de-busqueda img {
    opacity: 0;
  }
  #check1:checked ~ .sec5-hh-mo {
    max-height: 90.3vh;
    opacity: 1;
    transition: max-height 1s ease-out, opacity 0.63s;
    overflow-y: scroll;
    height: 85.9vh;
  }
  #check1:checked ~ .sec5-hh-mo li {
    opacity: 1;
    transition: max-height 1s;
  }
  #check1:checked ~ .sec5-hh-mo li a {
    color: white;
  }
  .sec5-hh-mo label {
    display: block;
    margin-bottom: 0 !important;
    cursor: pointer;
  }
  input#checkMulti,
  input#checkMulti1,
  input#checkMulti2,
  input#checkMulti3,
  input#checkMulti4 {
    display: none;
  }
  .sec5-hh-mo li:hover {
    background-color: #7c0c16;
  }
  #check1:checked ~ .sec5-hh-mo a li {
    color: white;
  }
  #check1:checked ~ .sec5-hh-mo .user-hh-mo {
    display: block;
  }
  #check1:checked ~ .sec5-hh-mo .drop-sm-patro-mo {
    display: block;
  }
  .supa,
  .supa1,
  .supa2,
  .supa3,
  .supa4 {
    display: none;
  }
  .supa li,
  .supa1 li,
  .supa2 li,
  .supa3 li,
  .supa4 li {
    padding: 4vw 3vw !important;
    margin: auto 12vw !important;
  }
  #checkMulti:checked ~ .supa,
  #checkMulti1:checked ~ .supa1,
  #checkMulti2:checked ~ .supa2,
  #checkMulti3:checked ~ .supa3,
  #checkMulti4:checked ~ .supa4 {
    display: block;
  }
  #check1:checked ~ .sec5-hh-mo #menu-hh-mo {
    background-color: #b70d1d;
    padding: 7vw 0;
  }
  li.last-mmc {
    border-bottom: none !important;
  }
  .drop-sm-patro-mo {
    background-color: #032a4b;
    display: none;
    padding: 10.87vw;
  }
  .sm-cont-mo {
    display: flex;
    justify-content: space-between;
  }
  #menu-hh-mo ul {
    margin-bottom: 0;
  }
  .btn_smuser.f {
    width: 1.449vw !important;
    height: 3.261vw;
  }
  .btn_smuser.i {
    width: 3.14vw !important;
    height: 3.14vw;
  }
  .btn_smuser.l {
    width: 2.899vw !important;
    height: 2.899vw;
  }
  .btn_smuser.t {
    width: 3.14vw !important;
    height: 2.415vw;
  }
  .btn_smuser.yt {
    width: 3.382vw;
    height: 2.174vw;
  }
  .btn_smuser.tt {
    width: 2.415vw;
    height: 2.995vw;
  }
  .sm-cont-mo a {
    border: 1px solid white;
    width: 8.937vw;
    height: 8.937vw;
    border-radius: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }
  .sm-cont-mo a:hover {
    background-color: white;
  }
  .sm-cont-mo a:hover .btn_smuser {
    filter: brightness(0.5);
  }
  .patro-cont-mo {
    border-top: 1px solid #8293a1;
  }
  .drop-sm-patro-mo h3 {
    font-size: 3.299vw;
    line-height: 3.299vw;
    color: #ffffff;
    font-family: "Rajdhani";
    margin: 0;
  }
  .drop-sm-patro-mo .sm-cont-mo {
    display: flex;
    padding: 6.039vw 0 9.42vw;
    justify-content: space-evenly;
  }
  .patro-cont-mo .patro-col.uno,
  .patro-cont-mo .patro-col.due {
    border-bottom: none;
    justify-content: space-around;
  }
  .patro-cont-mo .patro-col.uno,
  .patro-cont-mo .patro-col.due,
  .patro-cont-mo .patro-col.tre {
    padding: 10.145vw 0 4.469vw;
  }
  .patro-cont-mo .patro-logo.uno {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.due {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.tre {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.quattro {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-col.due {
    border-bottom: none;
    flex-wrap: wrap;
    width: 100%;
    width: auto;
    height: auto;
  }
  .patro-cont-mo .patro-logo.cincue {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.sei {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.sette {
    width: auto;
    height: 5vw;
  }
  .patro-cont-mo .patro-logo.octo {
    width: auto;
    height: 5vw;
  }

  /**slide principal */
  div#SliderHome {
    padding-top: 24vw;
  }
  .banneh-image.pece {
    display: none;
  }
  .banneh-image.mobileh {
    display: block;
  }
  .banneh-image {
    width: 100%;
    height: 170.773vw;
  }
  img.imagen-1 {
    height: 70.271vw;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .video-play-banner p {
    display: none;
  }
  .video-play-banner .ql-video {
    width: 100%;
    height: 170.773vw;
  }
  .principal-slide {
    display: none;
  }
  .principal-slide-mo {
    display: block;
  }
  .principal-slide-mo .vueperslides__parallax-wrapper {
    position: relative;
    overflow: hidden;
    height: 170.773vw;
  }
  .slide-2-cont {
    background: white;
    display: none;
  }
  .sl2-mo {
    display: block;
    padding: 5.072vw 8.213vw;
  }
  .news-content-s {
    display: flex;
    align-items: center;
    padding: 3.865vw 0;
    border-bottom: 1px solid gainsboro; 
  }
  .img-news {
    height: 22.222vw;
    width: 22.222vw;
    object-fit: cover;
    border-radius: 3vw;
  }
  .news-text {
    padding-left: 4.348vw;
    width: 58vw;
  }
  .titulo-nov {
    /*background-color: #032a4b;*/
    background-color: transparent;
    color: #b70d1d !important;
    width: 31vw;
    padding: 0.5vw 0;
    margin-bottom: 0.7vw;
    text-align: left;
  }
  .news-text h5 {
    width: 21.256vw;
    height: 5.556vw;
    background: #b70d1d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.415vw;
    line-height: 2.657vw;
    color: #ffffff;
    font-family: "Rajdhani";
  }
  .news-text p {
    font-size: 3.382vw;
    line-height: 3.865vw;
  }
  /**partido */
  #partidos {
    height: 120.048vw;
    background-image: url("./assets/slide-bg-partidos.png");
  }
  #partidos.webp {
    background-image: url("./assets/webp/slide-bg-partidos.webp");
  } /*#partidos .vueperslides.sl-partidos-pc {
    display: none;
}*/
  #partidos h1.titulo-partidos {
    font-size: 4.831vw;
    line-height: 5.556vw;
    text-align: left;
  }
  #partidos .header-partidos {
    flex-direction: column;
    padding: 3.333vw 5.781vw 0 7.865vw;
  }
  #partidos .calendar-cont {
    background-image: none;
  }
  #partidos .vueperslides__parallax-wrapper {
    height: 84vw;
  }
  #partidos .vueperslides__track-inner {
    width: 100% !important;
    margin: auto;
  }
  #partidos .vueperslide {
    padding: 0 10.628vw;
    width: 100% !important;
    align-items: center;
  }
  #partidos .vueperslides__arrows {
    top: 89vw;
    width: 100%;
  }
  #partidos .slide-calendar,
  #partidos .slide-calendar.versa {
    width: 78.261vw;
    flex-direction: column;
    position: relative;
    top: 0vw;
    height: auto;
  }
  #partidos
    .vueperslide.vueperslide--active.vueperslide--visible
    .prev-last-next-match,
  #partidos .vueperslide.vueperslide--visible .prev-last-next-match {
    color: #ce1526;
    font-family: "Rajdhani";
    font-weight: lighter;
    font-size: 3.865vw;
    line-height: 3.865vw;
    width: 100%;
    text-align: left;
    padding: 3.646vw 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: bold;
    transition: 1s;
  }
  #partidos .vueperslide .prev-last-next-match {
    color: transparent;
    padding: 4.946vw 0 3.646vw;
    font-size: 3.865vw;
    line-height: 3.865vw;
    height: auto;
  }
  #partidos .fecha-cont {
    display: flex;
    height: 10.628vw !important;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-left: 0;
    margin-top: 4.348vw;
  }
  #partidos .calendar-cont a:hover {
    text-decoration: none;
    padding-right: 0;
    padding-left: 0;
  }
  #partidos button.vueperslides__arrow.vueperslides__arrow--prev {
    width: 37.681vw;
    height: 8.488vw;
    background-size: 37.681vw;
    margin-left: 9.219vw;
    border-right: 2px solid grey;
  }
  #partidos button.vueperslides__arrow.vueperslides__arrow--next {
    width: 37.681vw;
    height: 8.488vw;
    background-size: 37.681vw;
    margin-right: 0;
  }
  #partidos button.vueperslides__arrow.vueperslides__arrow--next:hover,
  #partidos button.vueperslides__arrow.vueperslides__arrow--prev:hover {
    transform: scale(1.07);
    margin-top: -4vw;
  }
  #partidos .fecha-cont .mes {
    font-size: 3.865vw;
    line-height: 4.348vw;
    padding: 2.24vw 0 0 1.198vw;
  }
  #partidos .fecha-cont .fecha {
    font-size: 3.865vw;
    line-height: 4.348vw;
    text-align: center;
    margin-bottom: 0;
    padding: 2.24vw 1.198vw 0 6.522vw;
    color: #968220;
  }
  #partidos .fecha-cont .hora {
    font-size: 3.382vw;
    line-height: 3.865vw;
    text-align: center;
    margin-bottom: 0;
    background: #b70d1d;
    margin-top: 0;
    margin-left: 35.749vw;
    padding: 3.382vw;
    width: 23.5vw;
  }
  #partidos .tipo-juego {
    font-size: 3.865vw;
    line-height: 4.348vw;
    margin-bottom: 0;
  }
  #partidos .partido-cont {
    padding: 3.415vw 8.454vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #partidos .estadio-name {
    font-weight: normal;
    font-size: 2.415vw;
    line-height: 2.657vw;
  }
  #partidos .partido-res {
    display: flex;
    justify-content: space-around;
    width: 39.25vw;
    height: 23.014vw;
    flex-direction: column;
    padding-top: 2vw;
  }
  #partidos .local-cont,
  #partidos .visita-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60.628vw;
    justify-content: space-between;
  }
  #partidos .vueperslide.vueperslide--visible .l-button,
  #partidos .vueperslide.vueperslide--active .l-button {
    width: 100%;
  }
  #partidos .logo-equipo {
    height: 8.696vw;
  }
  #partidos .nombre-equipo {
    font-size: 2.657vw;
    line-height: 3.068vw;
    padding-left: 4.831vw;
    width: auto;
  }
  #partidos .resultado {
    font-size: 3.382vw;
    line-height: 3.382vw;
    padding: 2.574vw 3.174vw;
    border-radius: 5.454vw;
    width: 8.454vw;
    height: 8.454vw;
  }
  #partidos .team-cont {
    display: flex;
    height: 5vw;
    align-items: center;
  }
  #partidos .calendar-cont {
    background-image: none;
    position: absolute;
    margin-top: 3vw;
    left: 62vw;
    z-index: 99;
    width: 24vw;
  }
  #partidos .calendar-cont:hover {
    background-position: right;
    background-size: 4.896vw;
    width: 24vw;
  }
  #partidos .visita-cont .nombre-equipo {
    width: 100%;
  }
  #partidos .calendar-cont a {
    font-family: "Rajdhani";
    padding-right: 0;
    transition: 0.5s;
    font-size: 2.415vw;
    line-height: 2.657vw;
    height: auto;
    color: #b70d1d;
    padding-left: 0;
    padding-right: 0;
  }
  #partidos .calendar-cont a:hover {
    color: #47040b !important;
  }
  #partidos .calendar-cont a #partidos .vueperslide .l-button {
    display: block;
    margin: auto;
    opacity: 0;
    width: 100%;
    text-align: left;
    background-position-x: 53.5vw;
  }
  #partidos .jornada-content {
    width: 100%;
  }
  #partidos .channel {
    height: 3.094vw;
  }
  #partidos .l-button {
    font-size: 3.223vw;
    line-height: 3.623vw;
    padding: 5.797vw 21.36vw !important;
  }
  #partidos .l-button:hover {
    font-size: 3.223vw;
    line-height: 3.623vw;
    padding: 5.797vw 15.36vw !important;
  }
  /**TIENDA */
  #storeSection .ssc-titulo {
    text-align: center;
    padding-top: 9.179vw;
  }
  #storeSection .ssc-titulo h2 {
    font-weight: 600;
    font-size: 3.382vw;
    line-height: 8vw;
  }
  #storeSection .ssc-titulo h3 {
    font-size: 6.75vw;
    margin-bottom: 5.2vw;
  }
  #storeSection .scc-slide {
    padding: 7.295vw 0 0;
  }
  #storeSection .scc-slide.pc {
    display: none;
  }
  #storeSection .scc-slide.mo {
    display: block;
  }
  #storeSection .scc-slide.mo .vueperslides__arrows {
    position: absolute;
    top: -19vw;
    width: 40%;
    margin: 0 auto;
    display: none;
    margin: 0 30%;
    justify-content: center;
  }
  #storeSection .vueperslides__track-inner {
    height: 142vw !important;
  }
  #storeSection button.vueperslides__arrow.vueperslides__arrow--prev {
    width: 13.285vw;
    height: 13.285vw;
  }
  #storeSection button.vueperslides__arrow.vueperslides__arrow--next {
    width: 13.285vw;
    height: 13.285vw;
  }
  #storeSection .vueperslides__parallax-wrapper {
    /*height: 143vw!important; */
    padding-bottom: 143vw !important;
    height: 0 !important;
  }
  #storeSection .item-store-cont img {
    height: 46.633vw;
    width: auto;
  }
  #storeSection .ti-pr-cont p {
    font-size: 3.865vw;
    font-family: "Rajdhani";
    margin: 1.6vw 0;
  }
  #storeSection .ti-pr-cont {
    width: 100%;
    height: auto;
    color: white;
    padding: 8.696vw 1.771vw;
    font-size: 1.25vw;
  }
  #storeSection .pr-cont {
    background: #968220;
    width: 75% !important;
    height: 10.386vw;
    font-size: 3.865vw;
  }
  #storeSection .item-store-cont {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 5.302vw;
    padding: 7.246vw;
    width: 65.459vw;
  }
  #storeSection .comprar-btn {
    background: #032a4b;
    color: white;
    width: 79vw !important;
    font-size: 3.623vw;
    height: 18.116vw;
    padding: 0 30vw;
    background-position-y: center;
    background-position-x: 0;
    background-repeat: no-repeat;
    background-size: 0;
  }
  #storeSection .comprar-btn:hover,
  .categories-products-cont .comprar-btn:hover {
    padding: 0 4vw;
    background-size: 13.885vw;
    background-position-x: 62.521vw;
    background-position-y: center;
  }
  #storeSection .btn-ver-tienda {
    font-size: 3.865vw;
    line-height: 3.865vw;
  }
  #storeSection .btn-ver-tienda:hover {
    font-size: 3.865vw;
    line-height: 3.865vw;
    background-size: 15.885vw;
    background-position-x: 54.521vw;
    transition-delay: 0 !important;
    padding-right: 32vw;
  }
  #storeSection .btn-ver-tienda-cont {
    margin: 8.696vw auto;
  }
  #storeSection .vueperslides__track-inner {
    overflow-x: scroll;
    scrollbar-width: none; /*firefox*/
  }
  #storeSection .vueperslides__track-inner::-webkit-scrollbar {
    display: none; /* Ocultar scroll */
  }

  /**ULTIMAS NOTICIAS */
  .patro-u-n {
    position: absolute;
    left: 0vw;
    flex-direction: initial;
    align-items: center;
    margin-top: 14vw;
    width: 100%;
    padding: 0 10vw;
    justify-content: space-between;
    height: 10.386vw;
  }
  .patro-u-n p {
    font-size: 2.657vw;
    line-height: 3.14vw;
    margin-bottom: 0;
  }
  .patro-u-n img {
    width: auto;
    height: 3.382vw;
  }
  .titulo-u-noticias {
    justify-content: center;
    padding-bottom: 7.005vw;
    border-bottom: 1px solid black;
  }
  .titulo-u-noticias h4 {
    font-size: 6.039vw;
    line-height: 6.039vw;
  }
  #ultimasNoticias {
    padding: 10.145vw 8.906vw 0;
    font-family: "Rajdhani";
  }
  .l-cont.pc {
    display: none;
  }
  .l-cont.mo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 4vw;
    padding-top: 4vw;
    border-bottom: 1px solid gainsboro;
  }
  .last-cont {
    margin-top: 10.145vw;
    flex-direction: column;
  }
  .l-div {
    width: 27.575vw;
    height: 37.681vw;
    background-color: transparent;
  }
  .red-rec {
    display: none;
  }
  .text-cont {
    width: 50vw;
    height: 38vw;
  }
  .l-cont:hover .text-cont {
    filter: none;
  }
  .l-m-content {
    border-radius: 5vw;
  }
  #ultimasNoticias .l-m-content {
    height: 40vw !important;
  }
  .l-cont .date {
    font-size: 2.174vw;
    line-height: 3.623vw;
    margin: 0 0 2.657vw;
    background-size: 3.623vw;
    padding: 0.2vw 5.5vw;
  }
  .l-cont .titulo {
    font-size: 3.623vw;
    line-height: 3.623vw;
    text-transform: none;
    font-family: "Rajdhani";
  }
  .text-cont a p.parrafo {
    padding-top: 3vw;
    font-size: 2.33vw;
  }
  .l-cont .titulo:hover {
    filter: drop-shadow(2px 3px 1px #b3b3b3);
  }
  .lastb {
    width: 100%;
    margin-left: 0;
    padding: 10.386vw 0;
  }
  .l-button {
    font-size: 3.623vw;
    line-height: 3.623vw;
    padding: 5.797vw 32.36vw;
    width: 100%;
    background-image: url("./assets/arrow2.png");
    background-size: 0;
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 0;
    transition: 0.5s;
  }
  .l-button:hover {
    background-image: url("./assets/arrow2.png");
    background-size: 13.527vw;
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 58.937vw;
    padding: 5.797vw 11.836vw;
  }
  #partidos .vueperslide .l-button {
    background-position-x: 49vw;
    display: block;
  }
  #partidos .vis .partido-res {
    display: flex;
    flex-direction: column-reverse !important;
  }
  #partidos .versus-h1 {
    font-size: 11vw;
    position: absolute;
    top: 29vw;
    left: 57vw;
    color: #a5b5c3;
    display: none;
  }

  /**ULTIMOS VIDEOS E IMAGENES */
  #ultimosVideos .l-div,
  #ultimasImagenes .l-div {
    width: 22.222vw;
    height: 22.222vw;
    background-color: transparent;
  }
  .titulo-vim {
    padding: 0 0 0 4.589vw;
    position: relative;
    margin-top: 0;
    width: 60%;
    background: none;
    height: auto;
    justify-content: flex-start;
  }
  .l-cont {
    width: 100%;
    height: auto;
    padding-bottom: 4.831vw;
    border-bottom: 1px solid gainsboro;
    padding-top: 4.831vw;
    font-family: "Rajdhani";
    display: flex;
  }
  .l-cont .titulo-vim .date {
    color: #b70d1d;
    font-size: 2.174vw;
    line-height: 3.623vw;
    background-size: 3.623vw;
    padding: 0.2vw 5vw;
  }
  .l-cont .titulo-vim .titulo {
    font-size: 3.623vw;
    line-height: 4.179vw;
    color: #032a4b;
    text-transform: none;
  }
  #ultimosVideos .l-button,
  #ultimasImagenes .l-button,
  #ultimasNoticias .l-button {
    padding: 5.797vw 30.36vw;
  }
  #ultimosVideos .l-button:hover,
  #ultimasImagenes .l-button:hover,
  #ultimasNoticias .l-button:hover {
    padding: 5.797vw 6.36vw;
  }
  /**FOOTER */

  .footer {
    flex-direction: column;
    padding: 20.531vw 11.768vw;
  }
  .footer .foo-titulo {
    font-size: 5.797vw;
    background-size: 13.768vw;
    padding: 0 0 0 16.2vw;
    cursor: default;
  }
  .footer .foo-parrafo {
    font-size: 2.415vw;
    width: 100%;
  }
  .footer .column {
    width: 100%;
  }
  .group-form-suscr {
    display: flex;
    flex-direction: column;
  }
  .footer .sec-suscr .foo-parrafo {
    margin-bottom: 15.7vw;
  }
  #inputEhome {
    height: 18.116vw;
    width: 100%;
    padding: 8vw;
    font-size: 2.415vw !important;
    margin-bottom: 2vw;
  }
  #inputEhome::placeholder {
    font-size: 2.415vw !important;
    line-height: 2.415vw !important;
  }
  #btn-suscr {
    width: 100%;
    height: 18.116vw;
    font-style: normal;
    font-size: 2.415vw;
    line-height: 2.415vw;
    text-align: left;
    padding-left: 8.454vw;
  }
  .footer .col-column.uno,
  .footer .col-column.due {
    display: none;
  }
  .footer .col-mob {
    display: block;
    width: 100%;
    padding-top: 21.256vw;
  }
  .list-cont-foo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 9.077vw;
  }
  .list-col {
    font-size: 2.415vw !important;
    width: 19vw;
  }
  .list-flex {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 12vw;
  }
  .footer .redes-soc-col {
    width: 100%;
    margin-top: 17.874vw;
  }
  .footer .btn-sm-cont {
    padding: 2.631vw 1vw;
    border: 1px solid #ffffff;
    height: 13.768vw;
    border-radius: 13.768vw;
    width: 8.768vw;
    height: 8.768vw;
  }
  .footer .btn-sm-col {
    width: auto;
    height: 3.106vw;
  }
  .patro-logo.uno {
    width: 32.125603864734295vw;
    height: auto;
  }
  .patro-logo.due {
    width: 22.947vw;
    height: auto;
  }
  .patro-logo.tre {
    width: 39.8vw;
    height: auto;
    margin-right: 0vw;
  }
  .patro-logo.quattro {
    width: 19.324vw;
    height: auto;
  }
  .patro-logo.cincue {
    width: 15.7vw;
    height: auto;
  }
  .patro-logo.sei {
    width: 24.396vw;
    height: auto;
  }
  .patro-logo.sette {
    width: 35vw;
    height: auto;
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
  .patro-logo.octo {
    width: 25.604vw;
    height: auto;
  }
  .patro-logo.nueve {
    width: 16.183574879227052vw;
    height: auto;
    margin-bottom: 8vw;
    margin-top: 8vw;
  }
  .patrocinadores {
    padding: 0 10.208vw;
  }
  .patro-col.due {
    border-bottom: 1px solid #8293a1;
    flex-wrap: wrap;
    width: 79.7vw;
    height: 100vw;
    margin: auto;
  }
  .patro-col.tre {
    border-top: 1px solid #8293a1;
    flex-wrap: wrap;
  }
  .patro-col.uno,
  .patro-col.due {
    flex-wrap: wrap;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
  .foot-cimarrones {
    background: #001c33;
    padding: 17.874vw 8.937vw;
  }
  .foot-cimarrones .logo-cima-foot {
    width: auto;
    height: 29.469vw;
  }
  .foot-cimarrones .list-foot a h5 {
    padding: 0 1.25vw;
    border-right: 0;
    font-size: 2.415vw;
    line-height: 2.415vw;
  }
  .foot-cimarrones .list-foot {
    display: flex;
    justify-content: space-around;
    width: 85%;
    height: 27.536vw;
    margin-top: 17.874vw;
    flex-wrap: wrap;
  }
  .foot-cimarrones h5.copyright {
    font-size: 2.415vw;
    margin-top: 17.15vw;
    cursor: default;
  }
  .modal2.gale {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .modal2.gale img {
    width: 84vw;
    height: auto;
  }
  img.rest-of-pics {
    height: 26vw !important;
    width: 29vw !important;
  }
  .modal2.gale .gallery-mosaic {
    width: 84vw;
    overflow-x: scroll;
    justify-content: flex-start;
    height: 25vw;
  }
  .modal2.gale .btn-close {
    font-size: 3vw;
    height: 3.5vw;
    width: 3.5vw;
  }
  .modal2-backdrop {
    align-items: center;
  }
  .modal2 {
    height: 43vh;
  }
  .modal2-body-gal .gallery-item .gallery-frame {
    width: 40vw;
    height: 40vw;
    margin-bottom: 2vw;
  }
  .modal2-header h1 {
    font-size: 3vw;
    line-height: 4vw;
    height: fit-content;
    width: 69vw;
    padding: 2vw;
  }
  .modal2 .ql-video,
  .modal2 iframe {
    height: 28vh;
    width: 100%;
  }
  .btn-close {
    font-size: 4vw;
    width: 6vw;
    height: 6vw;
  }
  /**blog post */
  #blog-post .blog-post-cont {
    flex-direction: column;
    padding: 0;
    background: white;
    padding-top: 24vw;
  }
  #blog-post .post-cont {
    width: 100%;
  }
  #blog-post .header-post-cont {
    height: 57.246vw;
    width: 100%;
  }
  img.header-post-img {
    object-fit: cover;
  }
  .titulo-header-cont {
    height: 57.246vw;
    padding: 2.865vw;
    width: 100%;
    background: linear-gradient(to bottom, transparent, #0f2957);
  }
  .titulo-header-cont.pc {
    display: none;
  }
  .titulo-header-cont.mo {
    display: block;
    height: auto;
    padding: 2.865vw;
    width: 100%;
    background: none;
    position: relative;
    z-index: 10;
    color: black;
  }
  #blog-post .date-post {
    color: #b70d1d;
    font-size: 1.932vw;
    line-height: 2.174vw;
    background-size: 1.932vw;
    padding-left: 3vw;
    background-image: url("./assets/reloj-brown.png");
  }
  #blog-post .titulo-post {
    font-weight: normal;
    font-size: 8.454vw;
    line-height: 110.49%;
    color: #000000;
    width: 100%;
    padding-top: 3.865vw;
  }
  #blog-post .authors-cont {
    margin: 0 2.865vw;
    border: none;
  }
  #blog-post .autor-text {
    font-size: 2.415vw;
    line-height: 2.657vw;
  }
  #blog-post .share-in {
    display: none;
  }
  #blog-post .post-text h2 {
    font-size: 4.348vw;
    width: 100%;
  }
  #blog-post .post-text p,
  #blog-post .post-text li {
    font-size: 2.899vw;
  }
  #blog-post iframe.ql-video {
    height: 48vw;
  }
  #blog-post .sm-cont-n-w p {
    font-size: 2.415vw;
    line-height: 2.657vw;
    margin: 0;
  }
  #blog-post .widgets-cont {
    width: 100%;
    padding: 0 6.522vw 10vw;
  }
  #blog-post .lnc-header {
    padding: 5.072vw 8.213vw;
    border-radius: 3vw 3vw 0 0;
    background-size: 21.981vw 1.932vw;
    display: flex;
    align-items: center;
  }
  #blog-post .last-news-cont:hover .lnc-header {
    background-size: 83.29vw 1.932vw;
  }
  #blog-post .lnc-header .t {
    font-size: 4.589vw;
    line-height: 5.314vw;
  }
  #blog-post .day-sm {
    align-items: center;
  }
  #blog-post .day-ago {
    font-size: 2.415vw;
    line-height: 2.657vw;
  }
  #blog-post .sm-icon-w {
    width: auto;
    height: 2.608vw;
  }
  #blog-post .sm-cont-n-w {
    width: 40%;
  }
  #blog-post .titulo-n-w {
    font-size: 4.589vw;
    line-height: 5.314vw;
    width: 66.667vw;
  }
  #blog-post .ugc-header {
    padding: 5.072vw 8.213vw;
    border-radius: 3vw 3vw 0 0;
    background-size: 21.981vw 1.932vw;
    display: flex;
    align-items: center;
  }
  #blog-post .last-galleries-cont:hover .ugc-header {
    background-size: 83.29vw 1.932vw;
  }
  #blog-post .ugc-header .t {
    font-size: 4.589vw;
    line-height: 5.314vw;
  }
  #blog-post .news-widget {
    padding: 2.106vw;
  }
  #blog-post .last-news-cont,
  #blog-post .last-galleries-cont {
    padding-bottom: 2vw;
  }
  #blog-post .news-img {
    height: 44.686vw;
  }
  #blog-post .ugc-header a {
    font-size: 2.573vw;
  }

  /**Results */
  .last-matches-widget .lresw-header .uno .t {
    margin-bottom: 0;
    font-size: 4.589vw !important;
    line-height: 5.314vw !important;
  }
  .last-matches-widget .lresw-header .uno {
    text-align: left;
    padding: 6.763vw 9.179vw;
    border-radius: 2.7vw 2.7vw 0 0;
  }
  .last-matches-widget select#matchday-lresw {
    width: 100%;
    font-size: 2.415vw;
    line-height: 261.99%;
    height: 13.285vw;
    padding: 0 9.179vw;
    background-position-x: 74vw !important;
    background-size: 2.4vw !important;
  }
  .last-matches-widget h6.ht-ft2 {
    font-size: 2.415vw !important;
    line-height: 2.415vw !important;
  }
  .last-matches-widget .match-result-cont .uno {
    width: 80%;
    justify-content: space-between;
  }
  .last-matches-widget .team {
    width: 9.217vw;
    font-size: 4.174vw;
    line-height: 2.415vw;
  }
  .last-matches-widget img.logo-team {
    height: 11.213vw;
    width: auto;
  }
  .last-matches-widget .resultado-team {
    width: 8.28vw;
    height: 8.28vw;
    border-radius: 8.28vw;
    font-size: 4.657vw;
    margin: 0 0.3vw;
  }
  .last-matches-widget .match-result-cont {
    justify-content: space-evenly;
    padding-bottom: 2.657vw;
    border-bottom: 1px solid gainsboro;
  }
  .last-matches-widget .lresw-resultados-cont {
    padding: 3.865vw 1.615vw;
  }
  #res-contenido .widgets-cont-sb .last-galleries-cont {
    width: 100%;
  }
  #res-contenido .news-img {
    width: 100%;
    height: 44.686vw;
  }
  #res-contenido .sm-cont-n-w {
    display: none;
  }
  #res-contenido .last-galleries-cont {
    padding-bottom: 0vw;
  }
  #res-contenido .add-widget .sponsor-add-dr {
    width: 100%;
    height: auto;
  }

  #res-contenido {
    background-color: white;
    background-image: url(./assets/res-header-mo.png);
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 24vw;
    background-position-y: 24vw;
  }
  #res-contenido .res-contenido-inside {
    display: flex;
    flex-direction: column;
  }
  #res-contenido .header-result-cont {
    width: 100%;
    height: 113.964vw;
    padding: 8.47vw 12.584vw;
    align-items: flex-start;
  }
  #res-contenido .liga-logo-cont {
    height: 8.228vw;
    width: auto;
    margin-bottom: 2.344vw;
  }
  #res-contenido h1.day-result {
    font-size: 4.114vw;
    line-height: 195.5%;
  }
  #res-contenido h2.est-cap-result {
    font-size: 3.214vw;
    line-height: 3.214vw;
  }
  #res-contenido .ht-ft {
    margin-top: 1.927vw;
    font-size: 3.214vw;
    line-height: 3.214vw;
    text-align: right;
    width: 100%;
  }
  #res-contenido .loc-visit-cont:first-child {
    border-bottom: 1px solid #a2acb8;
  }
  #res-contenido .loc-visit-cont:last-child {
    flex-direction: row-reverse;
  }
  #res-contenido .logo-result {
    order: -1;
    height: 10.628vw;
    width: auto;
  }
  #res-contenido .tabs {
    width: 100% !important;
  }
  #res-contenido .match-result {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .visita-invert {
    flex-direction: column-reverse !important;
  }
  .visita-invert-m {
    flex-direction: row;
  }
  #res-contenido .loc-visit-cont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 4.831vw 0;
  }
  #res-contenido .ng-result {
    width: 100%;
    margin-left: 3.865vw;
  }
  #res-contenido .ng-result h2 {
    font-size: 3.382vw;
    line-height: 3.865vw;
  }
  #res-contenido h5.goal-cim {
    font-size: 2.899vw;
    line-height: 2.899vw;
  }
  #res-contenido .ht-result {
    font-size: 2.657vw;
    line-height: 3.14vw;
    padding: 2.657vw 6.28vw;
    border-radius: 6vw;
    margin-top: 1.823vw;
    margin-left: 49vw;
  }
  #res-contenido .sidebar-right .mo .patro-u-n {
    display: none;
  }
  #res-contenido .tab__nav__items .tab__nav__item.active {
    background-color: #032a4b;
    color: white !important;
  }
  #res-contenido .tab__nav__items .tab__nav__item {
    background-color: #a7000b;
    color: white !important;
  }
  .tab__nav__items {
    overflow-x: scroll;
  }
  #res-contenido h1.result-cir {
    font-size: 3.623vw;
    width: 8.454vw;
    height: 8.454vw;
    border-radius: 10vw;
  }
  #res-contenido .tab__nav__items .tab__nav__item {
    padding: 4.106vw 0 4.106vw !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
    height: 13.768vw;
    color: white;
    font-family: "Rajdhani";
    border: none;
  }
  #res-contenido .icon {
    width: 3.382vw;
    margin-right: 3.14vw;
  }
  #res-contenido .bar-name-item h5 {
    font-size: 2.657vw;
    font-weight: 500;
  }
  #res-contenido .bar-name-item.res {
    width: 33.816vw;
  }
  #res-contenido .bar-name-item.est {
    width: 56.917vw;
  }
  #res-contenido .bar-name-item.gal {
    width: 28.021vw;
  }
  #res-contenido .bar-name-item.vid {
    width: 24.356vw;
  }
  #res-contenido .post-text-cont {
    padding: 11.111vw;
  }
  #res-contenido .post-text {
    padding: 0 !important;
    border: none !important;
  }
  #res-contenido .authors-cont {
    display: none;
  }
  #res-contenido .date-post {
    color: #b70d1d !important;
    font-size: 1.932vw !important;
    line-height: 4.265vw;
    background-size: 3.865vw;
    padding-left: 6vw;
  }
  #res-contenido h1.titulo-post-text {
    font-weight: normal;
    font-size: 8.454vw !important;
    margin-top: 6.763vw;
  }
  #res-contenido .post-text .autor-text {
    font-size: 2.415vw;
    line-height: 2.657vw;
    color: #032a4b !important;
    margin: 7.246vw 0;
    display: block;
  }
  #res-contenido .header-result-cont .patro-u-n {
    width: 100%;
    height: 7.094vw;
    border: 0;
    margin-top: 8vw;
    transition: 0.5s;
    display: flex;
  }
  #res-contenido .patro-u-n {
    display: none;
  }
  #res-contenido .patro-u-n p {
    font-size: 2.415vw;
    line-height: 2.657vw;
  }
  #res-contenido .patro-u-n img {
    width: 15.7vw;
    margin-left: 2.625vw;
  }
  #res-contenido .patro-u-n:hover {
    background-color: transparent;
    filter: opacity(1);
  }
  #res-contenido .patro-u-n:hover p {
    color: white;
  }
  #res-contenido .patro-u-n:hover img {
    filter: invert(1);
  }
  #res-contenido .post-text .share-in {
    width: 100%;
    border-radius: 0;
    border: 0;
    border-top: 1px solid #032a4b;
    margin-top: 27.778vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 6.28vw;
  }
  #res-contenido .post-text .share-in p {
    color: #032a4b !important;
  }
  #blog-post .sm-share-in.mobile,
  #res-contenido .sm-share-in.mobile {
    display: flex;
    width: auto !important;
    border-top: 1px solid gainsboro;
    margin: 0 3vw;
    padding: 9vw 0;
    align-items: center;
  }
  #blog-post .sm-share-in.mobile .share-title,
  #res-contenido .sm-share-in.mobile .share-title {
    font-size: 2.415vw;
    font-family: "Rajdhani";
    color: #021d35;
    line-height: 2vw;
    margin-bottom: 0;
  }
  #blog-post .sm-share-in.mobile .cont-sm-s,
  #res-contenido .sm-share-in.mobile .cont-sm-s {
    display: flex;
    width: 40vw;
    justify-content: space-between;
  }
  #blog-post .sm-share {
    width: auto;
    height: 3.382vw;
    /* border: 1px solid;
    border-radius: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;*/
  }
  #blog-post .sm-share-in.mobile a,
  #res-contenido .sm-share-in.mobile a {
    border: 1px solid #021d35;
    border-radius: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    width: 10vw;
    height: 10vw;
  } /*#res-contenido .sm-share-in {
    width: 34.058vw;
    display: flex;
    justify-content: space-between;
  }
  #res-contenido .sm-share-in a {
    line-height: 0;
    border: 1px solid #032a4b;
    width: 9.662vw;
    height: 9.662vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15vw;
    transition: 0.25s;
  }
  #res-contenido .sm-share-in a:hover {
    transform: scale(1.1);
  }*/
  #res-contenido .post-text img {
    height: 3.382vw;
    width: auto;
  }
  #res-contenido li.active {
    background-image: none;
  }
  #res-contenido .news-widget .news-img {
    display: none;
  }
  #res-contenido .news-widget:nth-child(2) .news-img {
    display: block;
  }
  #res-contenido .news-widget {
    margin: 7.005vw 7.971vw;
    border: none;
    border-bottom: 1px solid gainsboro;
  }
  #res-contenido .news-widget:nth-child(2) {
    margin: 7.005vw 0 0 !important;
    border: 1px solid gainsboro;
  }
  #res-contenido .widgets-cont-sb {
    padding: 1.51vw 7.188vw 1.51vw;
  }
  #res-contenido .news-widget:nth-child(2) .day-sm,
  #res-contenido .news-widget:nth-child(2) .titulo-n-w {
    padding: 0 8vw;
  }

  #res-contenido .rs-tab {
    padding: 21.014vw 10.87vw;
  }
  #res-contenido .stats-lineup-cont {
    border: none;
    padding: 0;
  }
  #res-contenido .stlc-header h1 {
    width: 60vw;
    font-size: 8.454vw;
  }
  #res-contenido .rol {
    width: 52.657vw;
    height: 4.348vw;
    display: flex;
  }
  #res-contenido .rol-stat-cont h5 {
    font-size: 3.382vw;
    line-height: 164%;
    margin-top: 8.937vw;
    margin-bottom: 1.937vw;
  }
  #res-contenido h6.res {
    font-size: 3.382vw;
  }

  #res-contenido .lineup {
    display: none;
  }
  #res-contenido .lineup-tab {
    display: flex;
    margin-top: 22.947vw;
  }
  #res-contenido .lineup-tab .tab__nav__items {
    overflow-x: auto;
    border: 1px solid gainsboro;
    border-radius: 5vw 5vw 0 0;
  }
  #res-contenido .lineup-tab .tab__nav__items .tab__nav__item {
    padding: 6.039vw 4.5vw !important;
    min-width: fit-content !important;
    max-width: 50% !important;
    height: 22.222vw;
    width: 50% !important;
    border-bottom: 2vw solid transparent;
    transition: 0.5s;
  }
  #res-contenido .lineup-tab .tab__nav__items .tab__nav__item.active {
    background-color: transparent;
    color: rgb(0, 0, 0) !important;
    border-bottom: 2vw solid #b70d1d;
  }
  #res-contenido .lineup-tab .team-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #res-contenido .lineup-tab .tab__nav__items .tab__nav__item {
    background-color: transparent;
  }
  #res-contenido .logo-team-lup {
    height: 10.386vw;
    width: auto;
  }
  #res-contenido .name-team-lup {
    font-size: 2.299vw;
    line-height: 2.623vw;
    letter-spacing: 0.1vw;
    color: #032a4b;
    font-family: "Rajdhani";
    width: 15vw;
    text-align: left;
  }
  #res-contenido .players-cont {
    justify-content: flex-start;
    padding: 1.144vw 0;
    width: 100%;
  }
  #res-contenido .number-player {
    font-size: 3.865vw;
  }
  #res-contenido img.player-footage {
    height: 12.077vw;
    width: auto;
    margin: 0 3.865vw;
  }
  #res-contenido .player-name {
    font-size: 3.382vw;
    width: 39.036vw;
    margin-bottom: 0;
  }
  #res-contenido .player-name-pos-cont {
    width: 39.036vw;
  }
  #res-contenido .player-pos {
    font-size: 2.657vw;
    font-family: "Rajdhani";
    margin-bottom: 0;
  }

  #res-contenido .rs-tab.galvid {
    padding: 15.459vw 11.111vw !important;
  }
  #res-contenido .result-gallery {
    border: none;
    padding: 0;
  }
  #res-contenido .galleries-cont {
    flex-direction: row;
    justify-content: space-between;
    margin: 8.454vw 0;
  }
  #res-contenido .gallery-link {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 1.932vw;
    padding-bottom: 1.932vw;
    border-bottom: 1px solid gainsboro;
  }
  #res-contenido .d-t-gal-link {
    position: relative;
  }
  #res-contenido .d-t-gal-link {
    background: transparent;
    width: 45.411vw;
    height: auto;
  }
  #res-contenido .date-g-link {
    font-size: 1.691vw;
    line-height: 1.691vw;
    letter-spacing: 0.085em;
    color: #b70d1d;
    font-family: "Rajdhani";
    background-image: url("./assets/reloj.png");
    background-size: 1.691vw;
    background-repeat: no-repeat;
    padding-left: 1.092vw;
    padding: 0 1.404vw 0 2.404vw;
    background-position-x: 0;
    text-transform: uppercase;
  }
  #res-contenido .gallery-image {
    width: 20.531vw;
    height: 13.043vw;
  }
  #res-contenido .title-g-link {
    font-size: 2.899vw;
    line-height: 2.899vw;
    color: #032a4b;
    font-family: "Rajdhani";
    margin-bottom: 0.8vw;
    padding: 0;
  }
  #res-contenido .red-rect {
    display: none;
  }
  #res-contenido .result-gal-title {
    font-size: 7.246vw;
    width: auto;
  }
  #res-contenido .videos-gal-link {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 1.932vw;
    padding-bottom: 1.932vw;
    border-bottom: 1px solid gainsboro;
  }
  #res-contenido .play-button {
    width: 5.328vw;
    margin-top: -1.691vw;
    margin-left: -17.155vw;
  }
  #res-contenido .videos-gal-link .gallery-image {
    height: 13.043vw;
  }
  #res-contenido .post-cont {
    width: 100vw !important;
  }
  #res-contenido .post-text {
    padding: 4.74vw 3.333vw !important;
    border: 1px solid gainsboro !important;
    border-radius: 2vw !important;
    margin-top: 2.188vw !important;
    width: 75vw;
  }
  #res-contenido .post-text .post-cont {
    width: 69vw !important;
  }

  .filosofia {
    padding-top: 23.9375vw;
    background: white;
  }
  .filosofia .filo-header {
    width: 100%;
    height: 59.415vw;
    /*background-image: url("./assets/filo-header-m.png");
    background-size: 100% 52.415vw;*/
    background-repeat: no-repeat;
    padding: 0 15.459vw;
  }
  .filosofia .h-tra {
    display: none;
  }
  .filosofia .filo-header h1 {
    font-size: 14.493vw;
    line-height: 14.493vw;
    padding-top: 15.942vw;
  }
  .filosofia .filo-header h6 {
    font-size: 3.382vw;
    line-height: 3.382vw;
    text-align: center;
  }
  .filosofia .filo-cont {
    margin: 0;
    position: relative;
    margin-top: -6.155vw;
    background: transparent;
  }
  .filosofia .sec-texto {
    background: white;
    width: 100%;
    background-image: none;
  }
  .filosofia .sec.uno .sec-texto {
    border-radius: 6vw 0 0 0;
    padding: 8.213vw 9.42vw;
  }
  .filosofia .filo-cont .sec {
    flex-direction: column;
  }
  .filosofia .filo-titulo {
    font-size: 4.831vw;
    border-bottom: 1.365vw solid #b70d1d;
    padding-bottom: 8.696vw;
    margin-bottom: 8.696vw;
    padding-left: 2vw;
  }
  .filosofia .filo-parrafo {
    font-weight: normal;
    font-size: 2.899vw;
    line-height: 204.5%;
    padding: 0 2vw;
  }
  .filosofia img.filo-img {
    width: 100%;
    height: auto;
  }
  .filosofia .sec.uno img.filo-img {
    border-radius: 0 0 6vw 0;
  }
  .filosofia .sec.due img.filo-img {
    border-radius: 0 6vw 0 0;
  }
  .filosofia .sec.due {
    flex-direction: column-reverse !important;
    height: auto;
  }
  .filosofia .sec.tre,
  .filosofia .sec.quattro {
    height: auto;
  }
  .filosofia img.head-obje {
    display: none;
  }
  .filosofia img.head-obje-m {
    display: block;
    position: absolute;
    width: 51.932vw;
    height: 80.193vw;
    left: 13.285vw;
    margin-top: -49.758vw;
  }
  .filosofia .sec.tre .sec-texto {
    display: none;
  }
  .filosofia .sec.tre .sec-texto.mo {
    display: block;
    padding: 7.396vw;
  }
  .filosofia .sec.tre .sec-texto.mo.uno {
    display: block;
    padding: 32.367vw 7.396vw 7.396vw;
  }
  .filosofia .sec.uno {
    height: 291.625vw;
  }
  .filosofia .sec.quattro .sec-texto {
    background: none;
  }
  .filosofia img.redpoints {
    display: none;
  }

  .estadio-body .bloque-ne {
    padding-top: 24vw;
  }
  .bloque-ne .ne-header {
    width: 100%;
    height: 53vw !important;
  }

  /***burger button */
  .hamburger {
    height: 6vw;
    width: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    filter: drop-shadow(2px 4px 6px black);
  }

  .hamburger:hover {
    cursor: pointer;
  }

  .hamburger__item {
    height: 1vw;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 0.5vw;

    transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95),
      opacity 0.2s linear, margin-left 0.1s linear;
  }

  .hamburger--is-open .hamburger__item.hamburger__item--first {
    transform: translate(0, 1.594vw) rotate(45deg);
  }

  .hamburger--is-open .hamburger__item.hamburger__item--middle {
    opacity: 0;
    margin-left: -5vw;
  }

  .hamburger--is-open .hamburger__item.hamburger__item--last {
    transform: translate(0, -3.494vw) rotate(-45deg);
  }

  /**video */
  img.play-video-icon {
    width: 8vw;
    margin-left: -15vw;
    margin-top: 7vw;
  }
  .video-cont {
    padding-top: 12vw;
  }
  #video-c .l-cont {
    width: 100%;
    height: auto;
    padding-bottom: 4.831vw;
    border-bottom: 1px solid gainsboro;
    padding-top: 4.831vw;
    font-family: "Rajdhani";
    display: flex;
  }
  #video-c .titulo-vim {
    padding: 0 0 0 4.589vw;
    position: relative;
    margin-top: 0;
    width: 60%;
    background: none;
    height: auto;
  }
  .video-gal-cont iframe {
    width: 75vw !important;
    height: 52vw !important;
  }

  #calendario .calendar-cont {
    /*background-size: 100% 51.208vw;
    background-image: url("./assets/header-cal.png");*/
    padding-top: 23.9375vw;
    background-position-y: 23.9375vw;
  }
  #calendario img.cal-t-h {
    width: 72.3vw;
    margin-top: -8.4vw;
  }
  #calendario .calendar-header {
    padding: 17.633vw 15.942vw 13.633vw;
  }
  #calendario .calendar-header h1 {
    font-size: 12.077vw;
    /* -webkit-text-stroke: 1px white;
    color: transparent;*/
  }
  #calendario .calendar-header h6 {
    font-size: 4.348vw;
  }
  #calendario .tab__pagination__prev,
  #calendario .tab__pagination__next {
    display: none;
  }
  #calendario .tab__nav__items .tab__nav__item .team-type-header {
    max-width: 34.3vw;
    width: 34.3vw;
    height: 12.802vw;
    border: 1px solid gainsboro;
  }
  #calendario .tab__nav__items {
    justify-content: center;
    background: white;
  }
  #calendario .tab__nav__items .tab__nav__item .team-type-header h4 {
    font-size: 2.899vw;
    line-height: 2.899vw;
    color: #032a4b;
    font-family: "Rajdhani";
    margin-bottom: 0;
    background-image: none;
  }
  #calendario .tab__nav__items .tab__nav__item .team-type-header:hover {
    color: white !important;
    display: flex;
    padding-right: 0;
    background-size: 0;
    background-position-x: 0;
  }
  #calendario .tab__nav__items .tab__nav__item.active .team-type-header {
    color: white !important;
    background: #b70d1d;
    display: flex;
    padding-right: 0;
    background-size: 0;
    background-position-x: 0;
    background-image: none !important;
  }
  #calendario .tab__nav__items .tab__nav__item.active .team-type-header h4 {
    color: white !important;
  }
  #calendario .tab__nav__items {
    overflow-x: hidden;
  }
  #calendario .calendar-ft-header {
    width: 79.71vw;
  }
  #calendario .calendar-ft-header h1 {
    font-size: 6.28vw;
    padding-bottom: 5vw;
    margin-bottom: 0;
  }
  #calendario .matches-month {
    padding-top: 12.319vw;
    width: 83.71vw;
  }
  #calendario .ma-m-header {
    background-size: 10.386vw;
    background-position-y: 2.2vw;
    padding-left: 16.425vw;
    padding-bottom: 2.5vw;
    margin-bottom: 12.319vw;
  }
  #calendario .ma-m-header h3 {
    font-size: 4.831vw;
  }
  #calendario .match-ma-m {
    display: none;
  }
  #calendario .match-ma-m-mo {
    display: block;
    padding-bottom: 15vw;
    margin-bottom: 15vw;
    border-bottom: 1px solid gainsboro;
  }
  #calendario .match-mo {
    display: flex;
    width: 85%;
    height: 41vw;
    justify-content: space-between;
    padding-bottom: 4.106vw;
    margin: 0 auto;
  }
  #calendario .cim-team {
    flex-direction: column;
    width: 14.01vw;
    justify-content: space-evenly;
  }
  #calendario .logo-cont {
    width: 14.01vw;
    height: auto;
  }
  #calendario .cim-team h6 {
    font-size: 4.106vw;
    line-height: 4.106vw;
    width: auto;
  }
  #calendario .tou-logo {
    width: 21.739vw;
    height: auto;
  }
  #calendario .data-match h4 {
    font-size: 3.382vw;
  }
  #calendario .data-match {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }
  #calendario .data-match h6 {
    font-size: 3.382vw;
    font-family: "Rajdhani";
    text-align: center;
  }
  #calendario .match-result-vs {
    font-size: 3.382vw;
    font-family: "Rajdhani";
    display: flex;
    justify-content: center;
  }
  #calendario .journey-stadium-cont {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43vw;
  }
  #calendario .journey-stadium-cont h6 {
    font-size: 3.382vw;
    padding-left: 2vw;
    margin-left: 2vw;
    border-left: 1px solid gainsboro;
    width: 45vw;
    line-height: 3.9vw;
    text-transform: capitalize;
  }
  #calendario .journey-stadium-cont h4 {
    font-size: 3.382vw;
    text-align: right;
    width: 100%;
  }
  #calendario .match-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vw;
    justify-content: space-between;
  }
  #calendario .calendar-first-team {
    padding: 15.656vw 6.302vw;
  }
  #calendario .l-button {
    height: 19vw;
    width: 100%;
    padding: 7.797vw 26.36vw;
  }
  #calendario .l-button.bol {
    height: 19vw;
    padding: 7.797vw 24.36vw;
  }
  #calendario .l-button:hover,
  #calendario .l-button.bol:hover {
    padding: 7.797vw 15vw;
    background-size: 13.527vw;
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 58.937vw;
  }

  #calendario.cla .tab__nav__items .tab__nav__item {
    width: 100%;
  }
  #calendario.cla .clas-body {
    padding: 0;
  }
  #calendario.cla .tab__nav__item .team-type-header {
    width: 100%;
  }
  #calendario.cla .tab__nav__items .tab__nav__item .team-type-header {
    max-width: 100%;
    width: 100%;
    height: 12.906vw;
    padding-right: 0;
  }
  #calendario.cla .tab__nav__items .tab__nav__item.active .team-type-header,
  #calendario.cla .tab__nav__items .tab__nav__item .team-type-header:hover,
  #calendario.cla
    .tab__nav__items
    .tab__nav__item.active
    .team-type-header:hover {
    padding-right: 0;
  }
  #calendario.cla .tab__pagination {
    background: transparent !important;
    padding: 25.256vw 0 11.594vw;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 10vw;
  }
  #calendario.cla .clasificacion-sub-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    padding-top: 11.75vw;
    align-items: center;
    flex-direction: column;
    height: 48vw;
  }
  #calendario.cla .liga-logo-mx {
    width: 59.662vw;
    height: auto;
  }
  #calendario.cla .temp-sem-liga {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
  }
  #calendario.cla .temp-sem-liga h4 {
    font-size: 3.14vw !important;
  }
  #calendario.cla .temp-sem-liga h5 {
    font-size: 3.14vw !important;
  }
  #calendario.cla .titulo-u-noticias {
    justify-content: flex-start;
    padding: 7.005vw 0;
    border-bottom: 1px solid gainsboro;
    margin: auto;
    width: 81%;
  }
  #calendario.cla .patro-u-n {
    justify-content: space-between;
    border-bottom: 1px solid gainsboro;
    width: 81%;
    margin: 14vw 0 0 9vw;
    padding: 1.6vw;
  }
  #calendario.cla .tabla-liga td {
    height: 11.111vw;
    font-size: 2.415vw;
    width: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #calendario.cla .tabla-liga th {
    font-size: 2.415vw;
    line-height: 2.415vw;
    width: 9vw;
  }
  #calendario.cla .tabla-liga tr {
    height: 11.111vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  #calendario.cla .tabla-liga td.position-tb {
    width: 12.903vw;
  }
  #calendario.cla .tabla-liga td.name-club-tb {
    padding-left: 5.521vw;
    width: 32vw;
    height: inherit;
    padding-right: 3vw;
    display: flex;
    align-items: center;
  }
  #calendario.cla .tabla-liga td.name-club-tb p {
    margin: 0;
  }
  #calendario.cla .tabla-liga tr.titleelements {
    width: 97vw;
  }

  #calendario.pla .calendar-cont {
    /*background-image: url("./assets/play-header.png");
    background-size: 100%;*/
    background-repeat: no-repeat;
  }
  #calendario.pla .calendar-header h1 {
    font-size: 9.662vw;
    font-family: "Rajdhani";
    text-align: center;
  }
  #calendario.pla .pl-header {
    font-size: 6.28vw;
    line-height: 6vw;
    justify-content: left;
    font-family: "Rajdhani";
    padding-bottom: 0;
    margin-bottom: 4vw;
  }
  #calendario.pla .players-pos {
    padding: 5.417vw 0;
  }
  #calendario.pla .cont-players {
    flex-direction: column;
    width: 87.44vw;
  }
  #calendario.pla img.dots-ti {
    height: 3.302vw;
    width: auto;
  }
  #calendario.pla .squad-player {
    width: 100%;
    height: 34.85vw;
    display: flex;
    align-items: flex-end;
    margin: 3vw 0 1.5vw;
    background: #032a4b;
    border-radius: 3vw;
    justify-content: space-between;
  }
  #calendario.pla .squad-player:hover .player-img {
    height: 42.85vw;
  }
  #calendario.pla .pl-header:hover {
    line-height: 7.35vw;
  }
  #calendario.pla .first-squad {
    padding: 14.493vw 0 14.49vw;
    width: 100%;
  }
  #calendario.pla .player-img {
    width: 42.271vw;
    height: 30.85vw;
    position: relative;
    object-fit: cover;
    object-position: top;
  }
  #calendario.pla .data-player {
    position: relative;
    align-items: flex-start;
    border-top: 0;
    height: 32.85vw;
    background: none;
    width: auto;
  }
  #calendario.pla .player-number {
    font-size: 6.039vw;
    padding: 0.99vw;
    border-right: 0;
    width: 11.111vw;
    border-bottom: 1px solid white;
    padding: 6vw 6.99vw;
  }
  #calendario.pla .name-country-cont {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid white;
  }
  #calendario.pla .name-cont {
    border-bottom: none;
    font-size: 3.865vw;
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: auto;
    margin: 0 2vw;
  }
  #calendario.pla .country-cont {
    font-size: 1.932vw;
    padding-top: 1.932vw;
    letter-spacing: 0.8vw;
    height: auto;
  }
  #calendario.pla .tabs__content {
    margin: auto;
  }
  #calendario.pla .squad-player a {
    display: flex;
    align-items: flex-end;
    height: 32.85vw;
    width: 100%;
  }

  .cat-genre {
    width: fit-content;
    height: 7.875vw;
    border-radius: 6vw;
    font-size: 2.677vw;
    line-height: 7.6vw;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 42.56944444444444vw !important;
  }
}

@media (max-width: 905.98px) {
  .message-subscriptor {
    background-color: var(--color-9);
    text-align: center;
    width: 77vw;
    height: 7vw;
    padding: 2vw;
    font-size: 2.1vw;
    margin-top: 0vw;
    margin-left: 11.4vw;
  }

  /*LOGIN*/
  .col-one {
    background-image: url("./assets/Login_Web.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 134vw;
    width: 56vw;
  }
  .col-one p {
    color: var(--color-4);

    margin: auto;
    margin-top: 4vh;
    width: 85%;
    font-size: 1.8vh;
    text-align: center;
  }
  .col-one h2 {
    color: var(--color-4);
    font-size: 3.5vh;
    margin: 0 !important;
    padding-top: 36vh;
    text-align: center;
  }
  .form-container {
    width: 40vw;
    margin: auto;
    padding-top: 5vh;
  }
  .form-container input {
    border: 0px;
    border-bottom: 2px solid var(--color-3);
    width: 100%;
    height: 4vh;
    font-size: 0.9rem;
    color: var(--color-3);
  }
  input[type="submit"] {
    height: 5.5vh;
    color: var(--color-4);
    font-size: 0.9rem;
    border-bottom: 0px;
    cursor: pointer;
  }
}

@media (max-width: 588px) {
  /*LOGIN*/

  .col-two img {
    display: none;
  }
  .login-page {
    height: inherit;
    margin-top: -4vw;
  }
  .col-one {
    background-image: url("./assets/Login_Movil.png");
    background-repeat: no-repeat;
  }
  .col50 {
    width: 100%;
    height: 73vw;
  }
  .container-flex {
    display: inline;
  }
  .col-logo {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  #login .form-container input[type="submit"] {
    border-bottom: 0vw;
  }

  .col-two h4 {
    font-weight: bold;
    margin: 0;
    font-size: 1.2rem;
    text-align: left;
  }
  .row-block p {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 0vh;
  }
  input[type="submit"] {
    height: 7.5vh;
    color: var(--color-4);
    font-size: 0.9rem;
    border-bottom: 0px;
    cursor: pointer;
  }
  p.error {
    color: var(--color-1);
    font-size: 0.9rem;
  }

  ::placeholder {
    color: var(--color-3);
    font-size: 0.9rem;
  }

  /*_______CARRITO VACIO_______*/

  .Cont_Empty {
    text-align: -webkit-center;
  }

  #title_sec_car {
    text-align: left;
    margin-top: 8.695652173913043vw;
    color: var(--color-8);
    font-size: 6.763285024154589vw;
    font-weight: 800;
    width: 90%;
    font-family: Rajdhani;
  }
  div#items_car_list1 {
    display: flex;
    width: 83%;
    height: 28.93719806763285vw;
    background: var(--color-4);
    margin-bottom: 0.01vw;
    text-align: -webkit-center;
    border-top: none;
  }
  .msg_carrito_vacio {
    width: 44vw;
    height: 14vw;
    line-height: 5.555555555555555vw;
    text-align: center;
    font-weight: 600;
    font-size: 4.830917874396135vw;
    color: var(--color-3);
    margin-top: 10vw;
  }

  .div_BackTienda {
    width: 35.990338164251206vw;
    height: 8.212560386473431vw;
    display: flex;
    background: var(--color-2);
    align-items: center;
    margin-top: 3vw;
    margin-bottom: 17.14975845410628vw;
    cursor: pointer;
  }
  .div_BackTienda img {
    width: 0;
    height: 0;
    margin-right: 0;
    margin-left: 0;
  }
  .div_BackTienda:hover img {
    width: 8.212560386473431vw;
    height: 1.4vw;
    margin-right: 1vw;
    margin-left: 1vw;
  }
  .div_BackTienda p {
    margin-top: 3.65vw;
    color: var(--color-4);
    font-size: 2.4154589371980677vw;
  }

  /*_______CARRITO CON ARTICULOS_______*/

  .cl2_shdls {
    width: 30%;
    margin-left: 7vw;
    margin-top: 5.6666666666666667vw;
  }
  .cl1_shdls {
    width: 65%;
    margin-left: 5.03125vw;
  }

  .div_item {
    width: 100%;
    height: fit-content;
    margin-right: 5vw;
    overflow-y: auto;
    margin-left: 0vw;
  }

  div#items_car_list {
    display: block;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: var(--color-4);
    margin-bottom: 0.01vw;
    text-align: -webkit-center;
    padding-bottom: 1vw;
  }

  #title_car_list {
    display: flex;
    background: var(--color-2);
    width: 100%;
    padding-left: 25vw;
    padding-top: 7vw;
  }
  #title_car_list h4 {
    margin-left: 10vw;
    margin-bottom: 0vw;
    font-size: 2.898550724637681vw;
    font-weight: 700;
    width: 20vw;
    color: var(--color-4);
    text-align: center;
    font-family: Rajdhani;
  }
  .Bar_Precio {
    padding-right: 5vw !important;
  }

  .DivDet {
    display: block;
    padding-top: 0vw;
    height: 33vw;
    margin-left: 5.797101449275362vw;
    text-align-last: left;
  }
  .item_car_name {
    color: var(--color-8) !important;
    white-space: nowrap;
  }

  .TallaColor {
    display: none;
  }
  .TallaColor2 {
    display: block;
    white-space: nowrap;
    margin-top: 7.08vw;
  }

  .title_product {
    display: none;
  }

  .DivCupones {
    width: 83vw;
    height: 38vw;
  }
  table.TableCupones {
    width: 66vw;
    height: 12vw;
    margin-left: 0vw;
  }
  .TC_Delet {
    width: 4vw;
    height: 5vw;
  }
  .TC_Title {
    width: 18vw;
    height: 6vw;
    margin-bottom: 0vw;
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    white-space: nowrap;
    font-size: 5vw;
    margin-left: -65vw;
  }
  .TC_Code {
    width: 22vw;
    height: 5vw;
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    white-space: nowrap;
    font-size: 3.3816425120772946vw;
  }
  .TC_Desc {
    width: 14vw;
    height: 2vw;
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    white-space: nowrap;
    font-size: 3.3816425120772946vw;
  }

  .TC_DCode {
    font-size: 3.3816425120772946vw;
    color: var(--color-3);
    font-family: "Rajdhani";
  }
  .TC_DDesc {
    font-size: 3.3816425120772946vw;
    color: var(--color-2);
    font-family: "Rajdhani";
  }
  .TC_DDelet span {
    border: 1px solid var(--color-8);
    border-radius: 50%;
    padding: 0.3vw 0.9vw !important;
    text-align: center;
  }

  .cantCar {
    display: flex;
    width: 13vw;
  }
  .cantCar button {
    display: block;
    font-size: 3.3816425120772946vw !important;
    border: 0;
    background: var(--color-4);
    color: var(--color-3);
    font-family: "Rajdhani";
  }
  .cantCar img {
    display: block;
    width: auto;
    height: 3.3816425120772946vw;
    margin-left: 15vw;
  }

  .Deleted_Icon {
    width: 7vw !important;
    display: none;
  }
  .ie_item_car {
    cursor: pointer;
    transition: all 300ms;
    font-size: 2.7vw;
  }
  .item_car_list {
    display: flex;
    align-items: flex-start;
    width: 83vw;
    height: 38vw;
    font-size: 1.25vw !important;
    padding: 3vw 0vw;
    font-family: Rajdhani;
  }
  .item_car_list p {
    width: 20vw;
    font-size: 3.3816425120772946vw;
    text-align: center;
    color: var(--color-8);
    margin-bottom: 0vw;
  }
  .item_car_list p span {
    margin-top: 1vw;
    border: 1px solid var(--color-4);
    border-radius: 17vw;
    padding: 0.5vw 1vw;
  }
  .Text_Producto {
    font-size: 3.3816425120772946vw !important;
    width: 20vw !important;
    font-family: "Rajdhani";
    float: left;
    white-space: nowrap;
    color: var(--color-1) !important;
  }
  .img_car_item {
    height: 22vw;
    width: 22vw;
    margin-left: 1vw;
    padding: 0.9vw;
    text-align: center;
    border: 1px solid var(--color-8);
    border-radius: 2.6vw;
  }
  .img_car_item img {
    padding: 0vw;
    max-height: 20.048309178743963vw;
  }
  .Text_Cantidad {
    font-size: 2.4154589371980677vw !important;
    font-family: "Rajdhani";
  }
  .Text_Cantidad input {
    font-size: 2.4154589371980677vw !important;
  }
  .Text_Precio {
    padding: 1vw 0vw !important;
    font-size: 3.864734299516908vw !important;
    background: var(--color-4);
    font-family: Rajdhani;
  }

  .WHITELINE {
    width: auto;
    height: 0.02vw;
    background-color: var(--color-4);
    margin-left: 19vw;
    margin-right: 19vw;
  }

  .car_subtotal {
    margin-bottom: 0vw;
  }
  .car_subtotal h4 {
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
  }
  .car_subtotal p {
    color: var(--color-1);
    font-family: Rajdhani;
  }

  .Pedido_Total {
    width: 83.09178743961353vw;
    height: fit-content;
    border-radius: 2.6vw;
    border: 1px solid var(--color-8);
    background: var(--color-4);
    margin-bottom: 4vw;
  }
  .Tupedido_Text {
    height: 10.144927536231885vw;
    width: auto;
    font-size: 2.898550724637681vw;
    font-weight: 700;
    color: var(--color-4);
    background: var(--color-8);
    border-top-right-radius: 2vw;
    border-top-left-radius: 2vw;
    padding-top: 2.5vw;
    font-family: Rajdhani;
  }
  .PedidoF2 {
    display: flex;
    margin-left: 8vw;
    width: fit-content;
  }
  .PedidoF2_Producto {
    width: 14vw;
    font-size: 2.898550724637681vw;
    text-align: left;
  }
  .PedidoF2_Precio {
    width: 53vw;
    font-size: 2.898550724637681vw;
    text-align: right;
  }

  .Div_TuProducto {
    text-align: left;
    /*margin-left: 2vw;*/
  }

  .PedidoF3 {
    height: fit-content;
    overflow-y: auto;
    margin: 0 auto;
    width: 79%;
  }
  .Text_Name {
    font-size: 2.4154589371980677vw;
    margin-bottom: 0vw;
  }
  .Text_Pedido {
    /* width: 20vw;*/
    width: 33vw;
    margin-right: 2vw;
    text-align: left;
    font-size: 2.4154589371980677vw;
    margin-bottom: 0vw;
    font-family: "Rajdhani" !important;
  }
  .Pedido_Cantidad {
    font-size: 2.4154589371980677vw;
  }
  .Precop_Pedido {
    font-size: 2.4154589371980677vw;
    width: fit-content;
    text-align-last: right;
    align-self: center;
    font-family: "Rajdhani" !important;
  }

  .GoldLine {
    width: 66vw;
    height: 0.02vw;
    background: var(--color-8);
    margin-right: 1vw;
    margin-bottom: 1.3vw;
  }

  .Div_Subtotal {
    display: flex;
    width: 67vw;
  }
  .Div_Subtotal h4 {
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    font-size: 2.898550724637681vw;
    margin: 3vw 0vw;
  }
  .Div_Subtotal p {
    color: var(--color-1);
    font-family: Rajdhani;
    width: 55vw;
    text-align: right;
    font-size: 2.4154589371980677vw;
    margin: 3vw 0vw;
  }

  .Div_Envio {
    display: flex;
    width: 67vw;
    height: fit-content;
  }
  .Div_Envio h4 {
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    font-size: 2.898550724637681vw;
    margin: 3vw 0vw;
  }
  .Div_Envio p {
    color: var(--color-1);
    font-family: Rajdhani;
    width: 59vw;
    text-align: right;
    font-size: 2.4154589371980677vw;
    margin: 3vw 0vw;
  }

  .Div_Desc {
    display: flex;
    width: 67vw;
    height: fit-content;
  }
  .Div_Desc h4 {
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    white-space: nowrap;
    font-size: 2.9vw;
    margin: 3vw 0vw;
  }
  .Div_Desc p {
    color: var(--color-1);
    font-family: Rajdhani;
    width: 42vw;
    text-align: right;
    font-size: 2.4154589371980677vw;
    margin: 3vw 0vw;
  }

  .Div_Total {
    margin-bottom: 0.01vw;
    display: flex;
    width: 67vw;
  }
  .Div_Total h4 {
    font-weight: 600;
    color: var(--color-8);
    font-family: Rajdhani;
    font-size: 2.898550724637681vw;
    margin: 3vw 0vw;
  }
  .Div_Total p {
    color: var(--color-1);
    font-family: Rajdhani;
    width: 59vw;
    text-align: right;
    font-size: 2.4154589371980677vw;
    margin: 3vw 0vw;
  }

  .Div_Cupon {
    display: block;
    flex-flow: wrap;
    align-items: center;
    height: 60vw;
    margin-top: 4.830917874396135vw;
    margin-bottom: 4.830917874396135vw;
  }
  .Actualizar_Carrito {
    width: 65.94202898550725vw;
    height: 17.14975845410628vw;
    color: var(--color-8);
    border: 1px solid var(--color-8);
    background: var(--color-4);
    font-size: 2.4154589371980677vw;
    text-transform: uppercase;
    margin-left: 0vw;
    margin-bottom: 3vw;
    white-space: nowrap;
  }
  .Input_Cupon {
    width: 65.94202898550725vw;
    height: 17.14975845410628vw;
    color: var(--color-3);
    border: 1px solid var(--color-3);
    background: var(--color-4);
    font-size: 2.4154589371980677vw;
    
    margin-left: 0vw;
    margin-bottom: 3vw;
    white-space: nowrap;
  }
  .Input_Cupon::placeholder {
    font-size: 2.4154589371980677vw;
  }
  .Aplicar_Cupon {
    width: 65.94202898550725vw;
    height: 17.14975845410628vw;
    color: var(--color-4);
    border: 1px solid var(--color-8);
    background: var(--color-8);
    font-size: 2.4154589371980677vw;
    text-transform: uppercase;
    margin-left: 0vw;
    white-space: nowrap;
  }
  .Aplicar_Cupon:hover img {
    width: 10vw;
    height: auto;
    margin-left: 10vw;
  }

  .Fin_Compra {
    width: 65.94202898550725vw;
    height: 17.14975845410628vw;
    color: var(--color-4);
    background: var(--color-2);
    border: 0;
    font-size: 3.140096618357488vw;
    margin-top: 3vw;
    margin-bottom: 5vw;
  }
  .Fin_Compra img {
    width: 0;
    height: auto;
    margin-left: 0;
  }
  .Fin_Compra:hover img {
    width: 10vw;
    height: auto;
    margin-left: 7vw;
  }

  .Content_Detalles {
    height: fit-content;
    width: 90vw;
    border: 0px solid var(--color-4);
    border-top: none;
    border-radius: 7.246376811594203vw;
    margin: 5vw;
    padding-bottom: 13vw;
  }

  .Div_Titulo {
    background: var(--color-4);
    border-top-left-radius: 7.3vw;
    border-top-right-radius: 7.3vw;
    width: 100%;
    height: 10.38647342995169vw;
    text-align: -webkit-center;
    padding-top: 3.2vw;
    white-space: nowrap;
    margin-left: 0vw;
    margin-bottom: 3vw;
    margin-top: 8vw;
  }

  .Div_Titulo h1 {
    color: var(--color-8);
    text-align: center;
    font-size: 6.763285024154589vw;
    font-weight: 700;
    font-family: "Rajdhani";
    text-transform: uppercase;
    width: 30vw;
    height: 3vw;
  }

  .Div_SubTitulo {
    background: var(--color-4);
    width: 100%;
    height: 10.38647342995169vw;
    text-align: -webkit-center;
    padding-top: 3.2vw;
    margin-left: 8vw;
    margin-bottom: 20vw;
    border-bottom: 0px solid var(--color-3);
  }
  .Div_SubTitulo p {
    color: var(--color-3);
    text-align: left;
    font-size: 4.3478260869565215vw;
    font-weight: 700;
    font-family: "Rajdhani";
    width: 70vw;
    height: 4vw;
    margin-left: -20vw;
  }
  .in_cupon {
    color: var(--color-5);
  }

  .group_frm {
    display: grid;
    margin-right: 1vw;
    margin-bottom: 6.763285024154589vw;
  }

  .form_detalles_envio {
    margin-left: 3vw;
    border-right: 0px solid var(--color-3);
  }

  .cols_shdls {
    display: block;
  }

  .df_group_frm {
    display: block;
  }
  .ShowInputs {
    display: flex !important;
  }

  .Labels_Formulario {
    width: 51vw;
    color: var(--color-8);
    font-size: 2.898550724637681vw;
    font-weight: 900;
  }

  .Inputs_dobles {
    width: 74.63768115942028vw;
    height: 14.492753623188406vw;
    border: 1px solid var(--color-8);
    border-radius: 0vw !important;
    margin-right: 0.9vw;
    padding-left: 3vw;
  }

  .Inputs_dobles::placeholder {
    font-size: 2.4154589371980677vw;
  }

  .Input_Solo {
    width: 74.63768115942028vw;
    height: 14.492753623188406vw;
    border: 1px solid var(--color-8);
    border-radius: 0vw !important;
    padding-left: 3vw;
    font-size: 2.4154589371980677vw;
  }
  select.Input_Solo {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("./assets/flecha-blue.png");
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 66.5vw;
    background-size: 2.6vw;
  }
  .Input_Solo::placeholder {
    font-size: 2.4154589371980677vw;
  }
  .Special_Sice {
    height: 10.38647342995169vw !important;
  }
  .Special_Sice::placeholder {
    height: 8.38647342995169vw !important;
    width: 50vw !important;
  }

  .Input_CP {
    width: 74.63768115942028vw;
    height: 14.492753623188406vw;
    border: 1px solid var(--color-8);
    border-radius: 0vw;
    padding-left: 3vw;
  }
  .Pais {
    font-size: 2.898550724637681vw;
    color: var(--color-8);
  }

  .tupedido {
    background: white;
    border-radius: 4.830917874396135vw;
    width: 75.1207729468599vw;
  }

  .tupedido_title {
    color: var(--color-4);
    background: var(--color-2);
    width: 100%;
    height: 10.144927536231885vw;
    border-top-left-radius: 4.830917874396135vw;
    border-top-right-radius: 4.830917874396135vw;
    text-align: center;
    padding-top: 3.5vw;
    font-size: 2.898550724637681vw;
    font-family: "Rajdhani";
    font-weight: 700;
  }
  .productos_tupedido {
    display: flex;
    padding-left: 10.38647342995169vw;
  }
  .productos_tupedido p {
    color: var(--color-8);
    font-size: 2.898550724637681vw;
    font-weight: 700;
    font-family: "Rajdhani";
    margin-right: 32.5vw;
  }

  .items_tupedido {
    padding-left: 10.38647342995169vw;
  }

  .AllProducts {
    width: 44vw;
    height: 5vw;
    margin-bottom: 8vw;
  }

  .items_tupedido .itm_tpd p {
    margin-right: 1vw;
    color: var(--color-8);
    font-size: 2.4154589371980677vw;
    margin-bottom: 0.1vw;
  }

  .SubtotalOnly {
    width: 10vw;
    height: 8vw;
    padding-top: 3.5vw;
    font-size: 2.4154589371980677vw !important;
    font-weight: 400;
  }

  .subtotal_tupedido {
    display: flex;
    padding-left: 10.38647342995169vw;
  }

  .subtotal_tupedido p {
    color: var(--color-8);
    font-size: 2.898550724637681vw;
    font-weight: 700;
    font-family: "Rajdhani";
    margin-right: 33vw;
    margin-bottom: 0vw;
  }

  .envio_tupedido {
    display: flex;
    padding-left: 10.38647342995169vw;
  }

  .envio_tupedido p {
    color: var(--color-8);
    font-size: 2.898550724637681vw;
    font-weight: 700;
    font-family: "Rajdhani";
    margin-right: 24.8vw;
    margin-bottom: 0vw;
  }

  .Precio_Fijo {
    width: 18.4vw;
    height: 5vw;
  }

  .total_tupedido {
    display: flex;
    padding-left: 10.38647342995169vw;
  }

  .total_tupedido p {
    color: var(--color-8);
    font-size: 2.898550724637681vw;
    font-weight: 700;
    font-family: "Rajdhani";
    margin-right: 37vw;
  }

  .Total_TuPedido {
    font-size: 2.898550724637681vw !important;
    color: var(--color-2) !important;
  }

  .radio_sec {
    width: 57.971014492753625vw;
    height: 8.212560386473431vw;
    display: flex;
    background: rgb(231, 231, 231);
    color: var(--color-8);
    font-size: 2.4154589371980677vw;
    font-family: "Rajdhani";
    padding-top: 3vw;
    padding-left: 11.5vw;
    margin-top: 3.0416666666666665vw;
    margin-bottom: 3.5625vw;
    align-items: self-start;
  }

  .radio_sec input {
    margin-right: 2vw;
  }

  .Text1 {
    width: 57.971014492753625vw;
    height: 13.83574879227053vw;
    color: var(--color-8);
    text-align: left;
    font-size: 2.16vw;
    font-family: "Rajdhani";
  }

  .radio_sec2 {
    width: 75.1207729468599vw;
    height: 8.454106280193237vw;
    display: flex;
    background: rgb(241, 241, 241);
    font-size: 2.4154589371980677vw;
    font-family: "Rajdhani";
    padding-top: 3vw;
    padding-left: 2vw;
    margin-top: 3.0416666666666665vw;
    margin-bottom: 1.5625vw;
    margin-left: 0vw;
    align-items: self-start;
  }

  .Text2 {
    width: 75.1207729468599vw;
    height: 9.937198067632849vw;
    text-align: left;
    color: var(--color-3);
    font-size: 2.198vw;
    font-family: "Rajdhani";
    margin-left: 0vw;
  }

  .radio_sec3 {
    width: 56.971014492753625vw;
    height: 5.797101449275362vw;
    display: flex;
    background: var(--color-4);
    font-size: 0.9375vw;
    font-family: "Rajdhani";
    padding-top: 1vw;
    margin-top: 1.0416666666666665vw;
    margin-bottom: 18vw;
    margin-left: 0vw;
    align-items: baseline;
  }

  .Text_Terminos {
    color: var(--color-3);
    font-size: 2.396vw;
  }

  .Pedir {
    width: 75.1207729468599vw;
    height: 14.492753623188406vw;
    background: var(--color-2);
    color: var(--color-4);
    font-size: 3.140096618357488vw;
    font-family: "Rajdhani";
    border: 0;
    border-radius: 0vw;
    margin-left: 0vw;
  }
  .Pedir img {
    width: 0;
    height: 0;
    margin-left: 0;
  }
  .Pedir:hover img {
    width: 12vw;
    height: 1.5vw;
    margin-left: 18vw;
  }

  .RedLine {
    background: var(--color-3);
    width: 55vw;
    height: 0.01vw;
    margin-left: 9vw;
  }
  .metodos_pago {
    margin-top: 3vw;
    margin-left: 5vw;
  }

  .headerPD {
    height: 28.26086956521739vw !important;
    margin-top: 7.583333333333333vw !important;
  }

  .headerPD h1 {
    text-align: center;
    color: var(--color-7);
    font-weight: 700;
    font-size: 8.695652173913043vw !important;
    padding-top: 3.78125vw !important;
  }

  .headerPD p {
    text-align: center;
    color: var(--color-4);
    font-size: 5.5vw !important;
  }

  .sec1_cpd_titles[data-v-53f5e644] {
    margin-top: 3.4895833333333335vw;
    display: flex;
    margin-left: 3.3816425120772946vw !important;
    color: var(--color-7);
  }

  .sec1_cpd_titles p {
    font-size: 2.898550724637681vw !important;
    font-weight: 700;
    text-align: center;
    text-decoration: underline;
  }

  .cpdt1 {
    width: 30.531250000000002vw !important;
    margin-right: 7.291666666666667vw;
  }

  .cpdt2 {
    width: 4.96875vw !important;
    margin-right: 11.041666666666666vw;
  }

  .cpdt4[data-v-53f5e644] {
    width: 24.239583333333332vw !important;
  }

  .sec1_cpd_data {
    display: flex;
    margin-left: 3.3816425120772946vw !important;
    color: var(--color-1);
  }

  .sec1_cpd_data p {
    font-size: 2.898550724637681vw !important;
    text-align: center;
    font-family: "Rajdhani";
    font-weight: 600;
  }

  .cpdd1 {
    width: 23.416666666666668vw !important;
    margin-right: 12.791667vw !important;
  }

  .cpdd2 {
    width: 13.416667vw !important;
    margin-right: 4.641667vw;
  }

  .cpdd4[data-v-53f5e644] {
    width: 25.46875vw !important;
  }

  .tablaDP[data-v-53f5e644] {
    margin-top: 3.4375000000000004vw;
    width: 82.6086956521739vw !important;
    margin-left: 9.25vw !important;
    border-radius: 7vw !important;
  }

  .headerTPD[data-v-53f5e644] {
    height: 11.11111111111111vw !important;
    background-color: #b70d1d;
    border-radius: 7vw 7vw 0vw 0vw !important;
    white-space: nowrap;
  }

  #primer-equipo {
    display: flex !important;
    align-content: center;
    justify-content: center;
  }
  .headerTPD p[data-v-53f5e644] {
    width: 33.88645833333333vw !important;
    font-size: 3.3816425120772946vw !important;
    font-family: "Rajdhani";
    color: var(--color-4);
    margin-left: 23.802083333333332vw;
    padding-top: 3.5vw !important;
  }

  .sec1_cnt_prd[data-v-53f5e644] {
    width: 21.1453125vw;
    margin-left: 12.560386473429952vw !important;
  }

  .sec1_cnt {
    display: block !important;
  }

  .cntt1 {
    margin-right: 35vw !important;
  }

  .sctitle_r {
    font-size: 2.898550724637681vw;
    font-family: "Rajdhani";
    font-weight: 700;
    color: #b70d1d;
  }

  .item_cntprc[data-v-53f5e644] {
    text-align: center;
    margin-top: 2vw;
  }

  .item_cntprc[data-v-53f5e644] {
    text-align: center;
    margin-left: 18vw;
  }

  .items_cnt p[data-v-53f5e644] {
    width: 24.208333333333334vw !important;
    color: var(--color-3) !important;
    margin-bottom: 0vw;
    font-size: 2.898550724637681vw;
  }

  .sec2_cnt_sub {
    margin-left: 12.560386473429952vw !important;
  }

  .sec3_cnt_mtd {
    margin-left: 12.560386473429952vw !important;
  }

  .DPSubtotal {
    font-size: 2.8vw;
    margin-left: 42vw;
    color: var(--color-3);
  }
  .DPEnvio {
    font-size: 2.8vw;
    margin-left: 43vw;
    color: var(--color-3);
  }

  .sect3_cntt1 {
    width: 12vw;
  }

  .sect3_cntt2 {
    width: 8.708333vw;
    font-size: 2.898550724637681vw;
    margin-left: 41vw;
    color: var(--color-3);
  }

  .sect3_cntt3 {
    width: 48.708333vw !important;
    color: var(--color-2);
    font-size: 2.898550724637681vw;
    text-align: right;
  }

  .sec2_cnt_fac[data-v-53f5e644] {
    margin-left: 12.560386473429952vw !important;
    font-family: "Rajdhani";
    font-family: 1.25vw;
  }

  .fact1,
  .fact2 {
    width: 30.393229vw !important;
    margin-right: 7.4505208333333335vw !important;
  }

  .border_cnt {
    border-bottom: 0.11vw solid var(--color-3);
    margin-bottom: 2.2vw;
    width: 46vw;
    margin-left: 20vw;
  }

  .border_cnt_b {
    border-bottom: 0.11vw solid #b70d1d;
    width: 46.516667vw !important;
    margin-left: 18.989583vw !important;
    margin-bottom: 2.8125vw;
  }

  .last-matches-widget select#matchday-lresw {
    background-position-x: 74vw !important;
    background-size: 2.4vw !important;
  }

  .dflx p {
    color: var(--color-3);
  }
}
</style>
