var userCart="";
import dayjs from "dayjs";
export default {

    
deleteCart(){
    localStorage.removeItem('carrito')
},



generateCode(){
  //generar el codigo de auth
  var ctrs = "abcdefghijkmlnopqrtuvwxyzABCDEFGHJKLMNOPQRTUVWXYZ123456789";
  var code = "";
  for (var k = 0; k < 6; k++) {
    code += ctrs.charAt(Math.floor(Math.random() * ctrs.length));
  }
   let day = dayjs().format() 
  return code +'-'+day
},


getCart() {
    let cart = localStorage.getItem("kth");

    if (cart && cart != "undefined") {
        userCart = cart;
    } else {
        userCart = null;
    }

    return userCart;
  },

  setCart(carrito) {
    localStorage.setItem("kth", carrito);
  },


};

