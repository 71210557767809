'use strict'

import api from '@/api/api';
let urlpath = require('../../global/url')
import userService from '../../services/user.service'
const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      SET_STATUS = 'SET_STATUS',
      GETTING_MSG='GETTING_MSG',
      GET_MSG='GET_MSG',
      URL=urlpath.url();

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        status: '',
        msg:'Ver Todos',
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        status(state){
            return state.status
        },
        msg(state){
            return state.msg
        },
        baseURL(){
            return URL
        }
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [SET_STATUS](state, value){
            state.status = value;
        },
        [GET_MSG](state, value){
            state.msg =value
        },
        [GETTING_MSG](state){
            state.msg = 'Cargando...'
        },
       
    },
    actions:{
        async getAll({ commit }, payload) {
          
            commit(GETTING_INFO);
            try {    

                let token = userService.getToken() 
                let str = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsIm5hbWUiOiJkZWZhdWx0IiwibGFzdG5hbWUiOiJkZWZhdWx0IiwiZnVsbG5hbWUiOiJkZWZhdWx0IiwiZW1haWwiOiItIiwicGhvbmUiOiItIiwicm9sZSI6Ii0iLCJpbWFnZSI6Ii0iLCJjb2RlIjoiSzk3VzU3IiwiaWF0IjoiMjAyMi0wMy0xNlQwOToyMDo1Ni0wNzowMCIsImV4cCI6IjIxMjItMDMtMTZUMDk6MjA6NTYtMDc6MDAifQ.V5i_rucQMmDnTSI6OY2RyavvfM3Ph0Pu9TNH96RMGSc';
                token==null? token= str : token
                let headers =  {'Authorization': token} ;
            
                let response = await api.getAllInfo2(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async getByEmail({ commit }, payload) {
          //console.log(payload)
            commit(GETTING_INFO);
            try {    

                let token = userService.getToken() 
              
                let headers =  {'Authorization': token} ;
            
                let response = await api.getByEmail(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                
          //console.log(response)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async getById({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async createPurchase ({ commit }, payload){
            commit(SET_STATUS, 'SUCCESS')
            
            try {               
                let token = userService.getToken() 
                let response = await api.createPurchase(payload,token);
               // console.log(response.data)
                return response.data;
            } catch (error) {
              //  console.log(error)
                return {status:'error', message:'Error al llamar a la api.'}
            }
        },

        async createPurchase2 ({ commit }, payload){
            commit(SET_STATUS, 'SUCCESS')
            
            try {               
                let token = userService.getToken() 
                let response = await api.createPurchase2(payload,token);
               // console.log(response.data)
                return response.data;
            } catch (error) {
              //  console.log(error)
                return {status:'error', message:'Error al llamar a la api.'}
            }
        },


        async search({ commit }, payload) {
            commit(GETTING_INFO);
            commit(GETTING_MSG);
            let option = payload.option
            let search = payload.search;
            //console.log(payload)
    
            try {               
               // let normalizedOpt = option.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, ""); 
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let data ={
                    normalizedOpt: option,
                    search: search
                }

                let response = await api.searchPedidos(data, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                commit(GET_MSG, option);
                
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
     
   
    },
}