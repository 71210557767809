'use strict'

import api from '@/api/api';
import jwt from 'jwt-simple';

const clave = '62Dvgrogd2Nw4hWDarsVdfA294CZDjb40tyF1fq1HZ3C2W46SasfUQL786FFDp67MfgsF5';
const SET_TOKEN     = 'SET_TOKEN',
      SET_IDENTITY  = 'SET_IDENTITY',
      DOWNLOADING       = 'DOWNLOADING',
      DOWNLOAD_SUCCESS  = 'DOWNLOAD_SUCCESS';
export default {
    namespaced: true,
    state:{
        token: '',
        identity: { },
        dText: 'Descargar .CSV',
        dflag: false,
    },
    getters: {
        getToken(state){
            return state.token;
        },
        getIdentity(state){
            return state.identity;
        },
        downloadFlag(state){
            return state.dflag;
        },
        downloadText(state){
            return state.dText;
        },
        [DOWNLOADING](state, change) {
            state.dflag = false;
            state.dText = change;
        },
        [DOWNLOAD_SUCCESS](state, change) {
            state.dflag = true;
            state.dText = change;
        }
    },
    mutations: {
        [SET_TOKEN](state, payload){
            state.token = payload;
        },
        [SET_IDENTITY](state, payload){
            state.identity = payload;
        }
    },
    actions:{
        async login({commit}, payload) {
            let email = payload.email,
                password = payload.password;

            
            const user = { email, password };
            try {
                let headers = { "Content-Type": "application/x-www-form-urlencoded" };
                let response = await api.login(user, headers);
                
                commit(SET_TOKEN, response.data.token);
                return response;
            } catch (error) {
                //console.log(error);
            }
        },
        async enviarMailContacto({commit}, payload) {
           
            try {
                let headers = { "Content-Type": "application/x-www-form-urlencoded" };
                let response = await api.enviarMailContacto(payload, headers);
        
                return response.data;
            } catch (error) {
                //console.log(error);
            }
        },
        async enviarMailPrensa({commit}, payload) {
           
            try {
                let headers = { "Content-Type": "application/x-www-form-urlencoded" };
                let response = await api.enviarMailPrensa(payload, headers);
        
                return response.data;
            } catch (error) {
                //console.log(error);
            }
        },


        setToken({commit}, token) {
            localStorage.setItem("token", token);
            commit(SET_TOKEN, token);
        },
       
        getToken({commit}) {
            let userToken = "";
            let token = localStorage.getItem("token");
            if (token && token != "undefined") {
              userToken = token;
            } else {
              userToken = null;
            }
            commit(SET_TOKEN, userToken);
            return userToken;
        },
        decode({commit}, token){
            let decoded;
            let user = {};

            if(token != null){
             decoded = jwt.decode(token, clave);
             user = decoded; 
             localStorage.setItem('imgu', user.image);
             commit(SET_IDENTITY, user);
            }            
            return user;
        },
        logout({commit}) {          
            delete localStorage.token; 
            delete localStorage.ath; 
            localStorage.removeItem('token');     
            localStorage.removeItem('ath');           
            commit(SET_IDENTITY, null);
             commit(SET_TOKEN, null);            
        },
        async resendCode({commit}, payload) {
            let email = payload.email,
            name = payload.name, 
            code = payload.code;
        const user = { email, name, code };
        try {
            let headers = { "Content-Type": "application/x-www-form-urlencoded" };
            let response = await api.resendCode(user, headers);

            return response;
        } catch (error) {
            ////console.log(error)
        }
    },
     
   
    }
}