import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'vue-dayjs'


let urlpath = require('./global/url')

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'

import Vue2Editor from "vue2-editor";
import VueMeta from 'vue-meta'
import Paginate from 'vuejs-paginate'
import VueSocialSharing from 'vue-social-sharing'
import VueWebP from 'v-webp';
 
Vue.use(VueWebP);
Vue.component('paginate', Paginate)


Vue.use(Vue2Editor);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(dayjs)
Vue.use(VueSocialSharing);
Vue.use(VueMeta, {
  keyName: 'head',
  refreshOnceOnNavigation: true,
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Tabs from "vue-material-tabs"

Vue.use(Tabs);
Vue.use(VueAxios, axios)

axios.defaults.baseURL =urlpath.url();
import AOS from 'aos';
Vue.config.productionTip = false

new Vue({
  router,
  store, 
  created () {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
