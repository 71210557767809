'use strict'
import api from '@/api/api';
import carService from '../../services/cart.service'

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      GETTING_INFO2      = 'GET_INFO2',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2';

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        loading2: false,
        data2: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        loading2(state) {
            return state.loading2;
        },
        data2(state) {
            return state.data2;
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },

        [GETTING_INFO2](state) {
            state.loading2 = true;
            state.data2 = [];
        },
        [GET_INFO_SUCCESS2](state, change) {
            state.loading2 = false;
            state.data2 = change;
        },
    },
    actions:{
        async getCar2({ commit }, payload) {
            //commit(GETTING_INFO);
            try {               
               
                let _tkncarrito =  carService.getCart();
            
                if(_tkncarrito==null){
                    let response2 = await api.setCart();
                    _tkncarrito = response2.data.result
                    carService.setCart(_tkncarrito)

                }

                
                let response = await api.getCart({"_tkncarrito":_tkncarrito});
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data;
            } catch (error) {
                //console.log(error);
            }
           
        },

        async AddItemToCart2({ commit }, payload) {
      
            
            try {               
               
                let _tkncarrito =  carService.getCart();
            
                if(_tkncarrito==null){
                 
                    let response2 = await api.setCart();
                 
                    _tkncarrito = response2.data.result
                    carService.setCart(_tkncarrito)

                }

                let data ={
                    "_tkncarrito":_tkncarrito,
                    "item":payload
                }
                
                let response = await api.AddItemToCart(data);

                if(response.data.status=='success'){
                   let token = response.data.result
                   carService.setCart(token)

                   let response3 = await api.getCart({"_tkncarrito":token});

                   if(response3.data.status=='success'){
                    commit(GET_INFO_SUCCESS, response3.data.result );
                   }else{

                    return response3.data

                   }  
                   
                   
                   return response.data;

                  

                }else{
                    return response.data
                }




                
               
                
            } catch (error) {
                //console.log(error);
            }
           
        },


        async deleteItem2({ commit }, payload) {

           
            try {               
               
                let _tkncarrito =  carService.getCart();
            
                if(_tkncarrito==null){
                   
                    return {status:'error',message:'Carrito de compras vacio.'}

                }

                let data ={
                    "_tkncarrito":_tkncarrito,
                    "index":payload.index,
                    "codigo":payload.codigo
                }
                
                let response = await api.deleteItemCar(data);

                if(response.data.status=='success'){
                   let token = response.data.result
                   carService.setCart(token)

                   let response3 = await api.getCart({"_tkncarrito":token});

                   if(response3.data.status=='success'){
                    commit(GET_INFO_SUCCESS, response3.data.result );
                   }else{

                    return response3.data

                   }  
                   
                   
                   return response.data;

                  

                }else{
                    return response.data
                }




                
               
                
            } catch (error) {
                //console.log(error);
            }
        },
        
        
        async deleteCupon2({ commit }, payload) {

           
            try {               
               
                let _tkncarrito =  carService.getCart();
            
                if(_tkncarrito==null){
                   
                    return {status:'error',message:'Carrito de compras vacio.'}

                }

                let data ={
                    "_tkncarrito":_tkncarrito,
                    "cupon":payload.cupon,
                }
                
                let response = await api.deleteCupon(data);

                if(response.data.status=='success'){
                   let token = response.data.result
                   carService.setCart(token)

                   let response3 = await api.getCart({"_tkncarrito":token});

                   if(response3.data.status=='success'){
                    commit(GET_INFO_SUCCESS, response3.data.result );
                   }else{

                    return response3.data

                   }  
                   
                   
                   return response.data;

                  

                }else{
                    return response.data
                }




                
               
                
            } catch (error) {
                //console.log(error);
            }
        },


        async addCupon2({commit}, payload){
           
           
        
            try {               
               
                let _tkncarrito =  carService.getCart();
            
                if(_tkncarrito==null){
                 
                    let response2 = await api.setCart();
                 
                    _tkncarrito = response2.data.result
                    carService.setCart(_tkncarrito)

                }

                let data ={
                    "_tkncarrito":_tkncarrito,
                    "cliente":payload.cliente,
                    "cupon":payload.cupon
                }
                
                let response = await api.addCupon(data);
                console.log(response)
                if(response.data.status=='success'){
                   let token = response.data.result
                   carService.setCart(token)

                   let response3 = await api.getCart({"_tkncarrito":token});

                   if(response3.data.status=='success'){
                    commit(GET_INFO_SUCCESS, response3.data.result );
                   }else{

                    return response3.data

                   }  
                   
                   
                   return response.data;

                  

                }else{
                    return response.data
                }
 
                
            } catch (error) {
                //console.log(error);
            }
           
        },

        async updateCart2({ commit }, payload) {

            
            try {               

                let response = await api.updateCart(payload);

                if(response.data.status=='success'){
                   let token = response.data.result
                   carService.setCart(token)

                   let response3 = await api.getCart({"_tkncarrito":token});

                   if(response3.data.status=='success'){
                    commit(GET_INFO_SUCCESS, response3.data.result );
                   }else{

                    return response3.data

                   }  
                   
                   
                   return response.data;

                  

                }else{
                    return response.data
                }




                
               
                
            } catch (error) {
                //console.log(error);
            }
        },


        async validateCart2({ commit }, payload) {

            commit(GETTING_INFO);
            try {               
                let response = await api.validateCart(payload);
                return response.data
            } catch (error) {
                //console.log(error);
                return {status:'error', message:'Ha ocurrido un error.', result:''}
            }
        },
     
     
   
    },
}