import Vue from 'vue'
import VueRouter from 'vue-router'
import loginService from '../services/login.service';
import axios from "axios";
import userService from '../services/user.service'
import carService from '../services/cart.service'


Vue.use(VueRouter)

const routes = [
  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    beforeEnter: (to, from, next) => { 
      if(loginService.tokenexp()){
        delete localStorage.token; //token expirado
        delete localStorage.ath; //token expirado
      }
      next();  
    },
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  /*
  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/autentificacion.vue'), 
    meta :{
      log:true,
      admin : true,
      auth: false,
    }
  },*/
  //------------------------------------------------------------------
  {
    path: '/agregar/usuario/',
    name: 'AgregarUsuario',
    component: () => import('../views/users/AddUser'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' ){
        next()
      }else{
        next('/')
      }
     
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/editar/usuario/:id',
    name: 'EditarUsuario',
    component: () => import('../views/users/EditUser'),
    beforeEnter: async (to, from, next) => { 
     
      let result = await users(to.params.id)
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' ){
        if(result == 'Usuario no encontrado') return next('/');
        if(result.role != 'admin' && result.role != 'Admin' && result.role != 'editor' && result.role != 'comunicacion' && result.role != 'multimedia') return next('/')
      }else{
        next('/')
      }
    
     
    
      next();  
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },

  //------------------------------------------------------------------
  {
    path: '/agregar/imagen/',
    name: 'AgregarImagen',
    component: () => import('../views/gallery/AddGallery'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' || rol == 'editor'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/editar/imagen/:id',
    name: 'EditarImagen',
    component: () => import('../views/gallery/EditGallery'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' || rol == 'editor'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },


  {
    path: '/agregar/video/',
    name: 'AgregarVideos',
    component: () => import('../views/videos/AddVideo'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' || rol == 'multimedia'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/editar/video/:id',
    name: 'EditarVideos',
    component: () => import('../views/videos/EditVideo'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica' || rol == 'multimedia'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
   
    //------------------------------------------------------------------
     /*
    {
      path: '/editar/direccion/:id',
      name: 'EditarDireccion',
      component: () => import('../views/direccion/EditDireccion'),
      meta :{
        log:true,
        admin : false,
        auth: false
      }
    },
    {
      path: '/agregar/direccion/',
      name: 'AgregarDireccion',
      component: () => import('../views/direccion/AddDireccion'),
      meta :{
        log:true,
        admin : false,
        auth: false
      }
    },*/

    //------------------------------------------------------------------
    {
      path: '/agregar/landing/',
      name: 'AgregarLanding',
      component: () => import('../views/landing/AddLanding'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/landing/:id',
      name: 'EditarLanding',
      component: () => import('../views/landing/EditLanding'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
   

    
    //------------------------------------------------------------------
  {
    path: '/agregar/equipo/',
    name: 'AgregarEquipo',
    component: () => import('../views/teams/AddTeam'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/editar/equipo/:id',
    name: 'EditarEquipo',
    component: () => import('../views/teams/EditTeam'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
   

    
    //------------------------------------------------------------------
  {
    path: '/agregar/tipo/',
    name: 'AgregarTipo',
    component: () => import('../views/type/AddType'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
  {
    path: '/editar/tipo/:id',
    name: 'EditarType',
    component: () => import('../views/type/EditType'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'  || rol =='legrafica'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
  },
    /*------------------------------------------------------- */
    {
      path: '/agregar/partido/',
      name: 'AgregarPartido',
      component: () => import('../views/calendar/AddCalendar'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/partido/:id',
      name: 'EditarPartido',
      component: () => import('../views/calendar/EditCalendar'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
     

    //------------------------------------------------------------------
    {
      path: '/agregar/color/',
      name: 'AgregarColor',
      component: () => import('../views/store/AddColor'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/color/:id',
      name: 'EditarColor',
      component: () => import('../views/store/EditColor'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    //------------------------------------------------------------------
    {
      path: '/agregar/talla/',
      name: 'AgregarTalla',
      component: () => import('../views/store/AddSize'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/talla/:id',
      name: 'EditarTalla',
      component: () => import('../views/store/EditSize'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },


    //------------------------------------------------------------------
    {
      path: '/agregar/jugador/',
      name: 'AgregarJugador',
      component: () => import('../views/players/AddPlayer'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/jugador/:id',
      name: 'EditarJugador',
      component: () => import('../views/players/EditPlayer'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'  || rol =='legrafica'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    //------------------------------------------------------------------
    {
      path: '/agregar/entrada/',
      name: 'AgregarEntrada',
      component: () => import('../views/blog/addItem'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica'  || rol =='comunicacion' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/entrada/:id',
      name: 'EditarEntrada',
      component: () => import('../views/blog/editItem'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica'  || rol =='comunicacion' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    
    //------------------------------------------------------------------
    {
      path: '/agregar/producto/',
      name: 'AgregarProducto',
      component: () => import('../views/store/AddProduct'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica'  || rol =='editor' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/producto/:id',
      name: 'EditarProducto',
      component: () => import('../views/store/EditProduct'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica'  || rol =='editor' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },

     //------------------------------------------------------------------
     {
      path: '/agregar/canal/',
      name: 'AgregarCanal',
      component: () => import('../views/channel/AddChannel'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/canal/:id',
      name: 'EditarCanal',
      component: () => import('../views/channel/EditChannel'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica' ){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },

      //------------------------------------------------------------------
      {
        path: '/agregar/liga/',
        name: 'AgregarLiga',
        component: () => import('../views/liga/AddLiga'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/liga/:id',
        name: 'EditarLiga',
        component: () => import('../views/liga/EditLiga'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
       //------------------------------------------------------------------
       {
        path: '/agregar/torneo/',
        name: 'AgregarTorneo',
        component: () => import('../views/tournament/AddTournament'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/torneo/:id',
        name: 'EditarTorneo',
        component: () => import('../views/tournament/EditTournament'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },

      //------------------------------------------------------------------
      {
        path: '/agregar/cupon/',
        name: 'AgregarCupon',
        component: () => import('../views/store/AddCupon'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' || rol == 'editor'){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/cupon/:id',
        name: 'EditarCupon',
        component: () => import('../views/store/EditCupon'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' || rol == 'editor'){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },

       //------------------------------------------------------------------
       {
        path: '/agregar/patrocinador/',
        name: 'AgregarPatrocinador',
        component: () => import('../views/sponsor/AddSponsor'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/patrocinador/:id',
        name: 'EditarPatrocinador',
        component: () => import('../views/sponsor/EditSponsor'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },

      {
        path: '/agregar/patrocinador-footer/',
        name: 'AgregarPatrocinadorFooter',
        component: () => import('../views/sponsor/AddSponsorFooter'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/patrocinador-footer/:id',
        name: 'EditarPatrocinadorFooter',
        component: () => import('../views/sponsor/EditSponsorFooter'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      
      
      //------------------------------------------------------------------
      {
        path: '/editar/banner/:id',
        name: 'EditarBanner',
        component: () => import('../views/banner/EditBanner'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
  //------------------------------------------------------------------
      {
        path: '/agregar/categoria/',
        name: 'AgregarCategoria',
        component: () => import('../views/categories/AddCategory'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/categoria/:id',
        name: 'EditarCategoria',
        component: () => import('../views/categories/EditCategory'),
        beforeEnter: async (to, from, next) => { 
     
          let result = await category(to.params.id)

        

        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica' ){
          if(result == 'Categoría no encontrada') return next('/administrador');
          if(result.name != 'INICIO' && result.id != 1){
            next()
          }else{
            next('/administrador');  
          }
        }else{
          next('/')
        }
          
         
        
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },

       //------------------------------------------------------------------
       {
        path: '/agregar/perfil/',
        name: 'AgregarPerfil',
        component: () => import('../views/profile/AddProfile'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
      {
        path: '/editar/perfil/:id',
        name: 'EditarPerfil',
        component: () => import('../views/profile/EditProfile'),
        beforeEnter: async (to, from, next) => { 
          let rol = identity()
          if(rol== 'admin'   || rol =='legrafica' ){
            next()
          }else{
            next('/')
          }
        },
        meta :{
          log:true,
          admin : true,
          auth: true
        }
      },
 //------------------------------------------------------------------
 
    {
    path: '/editar/pedido/:id',
    name: 'EditarPedido',
    component: () => import('../views/purchase/EditPurchase'),
    beforeEnter: async (to, from, next) => { 
      let rol = identity()
      if(rol== 'admin'   || rol =='legrafica' || rol == 'editor'){
        next()
      }else{
        next('/')
      }
    },
    meta :{
      log:true,
      admin : true,
      auth: true
    }
    },

//------------------------------------------------------------------
    {
      path: '/agregar/stock/:id_product',
      name: 'AgregarStock',
      component: () => import('../views/store/AddStock'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica' || rol == 'editor'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
    {
      path: '/editar/stock/:id_product/:id',
      name: 'EditarStock',
      component: () => import('../views/store/EditStock'),
      beforeEnter: async (to, from, next) => { 
        let rol = identity()
        if(rol== 'admin'   || rol =='legrafica' || rol == 'editor'){
          next()
        }else{
          next('/')
        }
      },
      meta :{
        log:true,
        admin : true,
        auth: true
      }
    },
   
      /*------------------------------------------------------- */

  /** RUTAS PUBLICAS/CLIENTES */
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/client/Signin'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/panel',
    name: 'homecuenta',
    component: () => import('../views/client/CuentaHome'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('../views/Videos'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/galeria',
    name: 'galeria',
    component: () => import('../views/Galeria'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/galeria/:url',
    name: 'galeria_detalle',
    component: () => import('../views/GaleriaDetalle'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/video/:url',
    name: 'video_detalle',
    component: () => import('../views/VideoDetalle'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/blog/expansion',
    name: 'blog-exp',
    component: () => import('../views/Blog'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/blog/fuerzas-basicas',
    name: 'blog-fbas',
    component: () => import('../views/Blog'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/blog/eventos',
    name: 'blog-fbas',
    component: () => import('../views/Blog'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/blog/titulo/:title',
    name: 'blog-title',
    component: () => import('../views/BlogPost'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/blog/titulo',
    name: 'blog-title-1',
    component: () => import('../views/BlogPost'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/jugadores/nombre/:name',
    name: 'player-profile',
    component: () => import('../views/JugadorPerfil'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/jugadores',
    name: 'players-squad',
    component: () => import('../views/PlayersSquad'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/jugadores/premier',
    name: 'players-premier',
    component: () => import('../views/PlayersSquad'),
    meta :{
      log:false,
      admin : false,
      auth: false
      
    }
  },
  {
    path: '/jugadores/tercera',
    name: 'players-tercera',
    component: () => import('../views/PlayersSquad'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/calendario/:id',
    name: 'calendar',
    component: () => import('../views/Resultados'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/filosofia',
    name: 'filosofia',
    component: () => import('../views/Filosofia'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/calendarios',
    name: 'calendario',
    component: () => import('../views/Calendario'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/boletos',
    name: 'boletos',
    component: () => import('../views/Boleto'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/busqueda/:search',
    name: 'busqueda',
    component: () => import('../views/Busqueda'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/calendarios/premier',
    name: 'calendario',
    component: () => import('../views/Calendario'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/clasificaciones',
    name: 'clasificaciones',
    component: () => import('../views/Clasificaciones'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/estadio',
    name: 'estadio',
    component: () => import('../views/Estadio'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/tienda',
    name: 'tienda',
    component: () => import('../views/Tienda'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/categoria',
    name: 'categoria',
    component: () => import('../views/StoreCategory'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/categoria',
    name: 'categoria',
    component: () => import('../views/StoreCategory'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/categoria/:name',
    name: 'categoria-nombre',
    component: () => import('../views/StoreCategory'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/concurso',
    name: 'concurso',
    component: () => import('../views/Concurso'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/politica-privacidad',
    name: 'politica-privacidad',
    component: () => import('../views/PoliticaPrivacidad'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/prensa',
    name: 'prensa',
    component: () => import('../views/PrensaForm'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminos-y-condiciones',
    component: () => import('../views/TerminosYCondiciones'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/errorTemp'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  {
    path: '/calendario/undefined',
    name: 'undefined-match',
    component: () => import('../views/errorTemp'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },


  /** FIN RUTAS PUBLICAS/CLIENTES */


 
  {
    path: '/proximamente',
    name: 'test',
    component: () => import('../views/test'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },



  // Rutas del carrito
  
  {
    path: '/producto/:name',
    name: 'productoDetalle',
    component: () => import('../views/shopping_cart/productDetail'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  
  {
    path: '/carrito',
    name: 'carrito',
    component: () => import('../views/shopping_cart/cart'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  
  {
    path: '/finalizar-compra',
    name: 'finalizarCompra',
    component: () => import('../views/shopping_cart/shipmentDetails'),
    beforeEnter: async (to, from, next) => { 
    //  console.log(to)
     // console.log(from)
     
      let response = await getCarrito()
      if(response.status =='error'){
        localStorage.removeItem('kth')
        next('/')
      }
      let detalle =  response.result.detalle;
      //console.log(response)
      if(detalle ==undefined){
        localStorage.removeItem('kth')
        next('/')
      }

      let len = response.result.detalle.length
      if(len > 0 && from.path == '/carrito'){ //solo se puede entrar a esta vista si hay carrito y es desde carrito.
        next()
      }else{
        //localStorage.removeItem('kth')
        next('/')
      }
      
    },
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },

  {
    path: '/detalle-compra',
    name: 'detalleCompra',
    component: () => import('../views/shopping_cart/purchaseDetail'),
    beforeEnter: async (to, from, next) => { 
      
       /* if( from.path == '/finalizar-compra'){ //solo se podra acceder desde finalizar compra.
          next()
        }else{
          next('/')
        } */
        next()
      },
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },
  //fin rutas carrito



  //RUTAS DE PRUEBA
  {
    path: '/test_api_externa/:id',
    name: 'test_api_externa',
    component: () => import('../views/Test_Api_Externa'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(loginService.tokenexp()){
    delete localStorage.token; //token expirado
    delete localStorage.ath; //token expirado 
    next()
  }



  let check = loginService.checkIdentity();
  let meta_admin = to.matched.some(record=>record.meta.admin);
  let meta_log = to.matched.some(record=>record.meta.log);
  let meta_auth = to.matched.some(record=>record.meta.auth);
 
 
  let admin = loginService.isAdmin();
  let ath = loginService.getAth();


 if(to.name != 'calendar' || to.name != 'calendario'){
   //localStorage.removeItem('torneo')
 }
 
 if(to.path != '/detalle-compra'){
  //localStorage.removeItem('COMPLETED')
}

  if(to.path==='/login' && check){
    delete localStorage.token; 
    delete localStorage.ath; 
    next()
  }

  if(to.path==='/signin' && check){
    delete localStorage.token;
    delete localStorage.ath; 
    next()
  }

  if(meta_log){
    if(check){
      if(meta_admin){
            if(admin){
              if(meta_auth){
                if(ath.auth=='valido'){
                  next();
                }else{
                 // next('/auth') // mientras se arregla lo del envio de emails.
                  next('/login')
                }
              }else{ //...proximamente rutas que no requieran autentificacion
                next()
              }
            }else{
              next('/')
            }
      }else{
        if(admin){ //no requiere admin pero es admin
         next()
        }else{
          next()
        }
 
      }
    }else{
      next('/')
    }
    

  }else{
    if(check){
      if(admin){
       next()
      }else{
        next();
      }
    }else{
      next();
    }

  }
 
});


let users = async function(id){ //validar que no entren a la ruta de editar usuarios especificos
 
  let payload = {
    id: id,
    option: "user",
  };
  try {              
    let token = userService.getToken() 
    let headers =  {'Authorization': token} ;
  
    let response = await axios.get(`/${payload.option}/${payload.id}`, {headers});
   
    
    return response.data.result;
    } catch (error) {
        //console.log(error);
        
    }

} 

let category = async function(id){ //validar que no entren a la ruta de editar usuarios especificos
 
  let payload = {
    id: id,
    option: "category",
  };
  try {              
    let token = userService.getToken() 
    let headers =  {'Authorization': token} ;
  
    let response = await axios.get(`/${payload.option}/${payload.id}`, {headers});
   
    
    return response.data.result;
    } catch (error) {
        //console.log(error);
        
    }

} 

let identity = function(){ //validar que no entren a la ruta de editar usuarios especificos
 
  let user=''
  try {              
    let token = userService.getToken() 
    user = userService.decode(token)
    if(user.role){
      user = user.role
      user = user.toLowerCase();
    }else{
      user ='error'
    }
    
    
   
    } catch (error) {
        //console.log(error);
       user ='error'
    }

  
    return user
    

} 

let getCarrito= async function() {
  
  try {               
     
      let _tkncarrito =  carService.getCart();
  
      if(_tkncarrito==null){
        return {status:'error', message :'no existe carrito'}

      }

   
      let response = await  axios.post('/e7r-fwekF32D_fg43d/get_cart', {"_tkncarrito":_tkncarrito});
    
      return response.data  ;
  } catch (error) {
     
  }
 
}




export default router

