'use strict'

import api from '@/api/api';
import userService from '../../services/user.service'

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      GETTING_INFO2     = 'GET_INFO2',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2',
      GETTING_INFO3     = 'GET_INFO3',
      GET_INFO_SUCCESS3  = 'GET_INFO_SUCCESS3',
      GETTING_INFO4     = 'GET_INFO4',
      GET_INFO_SUCCESS4  = 'GET_INFO_SUCCESS4';

export default {
    namespaced: true,
    state:{
        loading: false,
        loading2: false,
        loading3: false,
        loading4: false,
        data: [],
        data2: [],
        data3: [],
        data4: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        loading2(state) {
            return state.loading2;
        },
        loading3(state) {
            return state.loading3;
        },
        loading4(state) {
            return state.loading4;
        },
        data(state) {
            return state.data;
        },
        data2(state) {
            return state.data2;
        },
        data3(state) {
            return state.data3;
        },
        data4(state) {
            return state.data4;
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [GETTING_INFO2](state) {
            state.loading2 = true;
            state.data2 = [];
        },
        [GET_INFO_SUCCESS2](state, change) {
            state.loading2 = false;
            state.data2 = change;
        },
        [GETTING_INFO3](state) {
            state.loading3 = true;
            state.data3 = [];
        },
        [GET_INFO_SUCCESS3](state, change) {
            state.loading3 = false;
            state.data3 = change;
        },
        [GETTING_INFO4](state) {
            state.loading4 = true;
            state.data4 = [];
        },
        [GET_INFO_SUCCESS4](state, change) {
            state.loading4 = false;
            state.data4 = change;
        },
    },
    actions:{
        async findAll({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findAll(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findById({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByName({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByName(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByName2({ commit }, payload) {
            commit(GETTING_INFO2);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByName(payload, headers);
                commit(GET_INFO_SUCCESS2, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByName3({ commit }, payload) {
            commit(GETTING_INFO3);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByName(payload, headers);
                commit(GET_INFO_SUCCESS3, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async findByName4({ commit }, payload) {
            commit(GETTING_INFO4);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.findByName(payload, headers);
                commit(GET_INFO_SUCCESS4, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        }
   
    },
}