'use strict'

import api from '@/api/api';
import dayjs from 'dayjs';
const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2';

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        ultimaJornada:''
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        ultimaJornada(state){
            return state.ultimaJornada
        }
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
            
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
            
        },
        [GET_INFO_SUCCESS2](state, change) {
           
            state.ultimaJornada = change;

        },
    },
    actions:{

        async getUltimaJornada({ commit }, payload) {
            commit(GETTING_INFO);
            try {           
                let response = await api.getPartidosSJ(payload);
                commit(GET_INFO_SUCCESS, response.data);
               // console.log(response.data)
                let day = dayjs().format('YYYY-MM-DD')
                let match =response.data
                let partidos = match.sort(
                  (a, b) => {return new Date(b.fecha) - new Date(a.fecha);}
                );
                console.log(day)
                let encontrado = 'no'
                for(var i =0; i < partidos.length; i++){
                    if(encontrado =='no'){
                        if(partidos[i].fecha <= day 
                           && partidos[i].idPartido != '125111'){
                           console.log('partido '+partidos[i].idPartido)
                            console.log('cheems '+partidos[i].fecha)
                            console.log('elekipo '+day)
                            commit(GET_INFO_SUCCESS2, partidos[i].idJornada);
                            encontrado='si'
                        }
                    }
                }
                return response.data;
            } catch (error) {
              //  console.log(error);
            }
        },
    },
}