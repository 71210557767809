"use strict";

import api from "@/api/api";
import userService from "../../../services/user.service";
const GETTING_INFO = "GET_INFO",
  GET_INFO_SUCCESS = "GET_INFO_SUCCESS";

export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
  },
  actions: {
    async getAlineaciones({ commit }, payload) {
      /*let payload2 = {
        id: payload.idPartido,
        option: "calendar",
      };
      let partido;
      let jornadaPartido;
      try {
        let token = userService.getToken();
        let headers = { Authorization: token };

        let response = await api.getInfoById(payload2, headers);

        partido = response.data.result;
        jornadaPartido = partido.typegame;
      } catch (error) {
        //console.log(error);
      }
      //122400
      let payload4 = {
        idTorneo: 2,
        idDivision: 20,
        idTemporada: 73,
        idClub: 10721,
      };

      let partidosCima;
      let idPartido;
      try {
        let response = await api.getPartidosClub(payload4);

        // console.log(response.data)
        partidosCima = response.data;
        let encontrado = "no";
        for (var i = 0; i < partidosCima.length; i++) {
          if (encontrado != "si") {
            if (partidosCima[i].jornada == jornadaPartido) {
              encontrado == "si";
              idPartido = partidosCima[i].idPartido;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      console.log(idPartido);

      let payload5 = {
        idPartido: idPartido,
      }; */

      commit(GETTING_INFO);
      try {
        let response = await api.getAlineaciones(payload);
        commit(GET_INFO_SUCCESS, response.data);
        // console.log(response.data)
        return response.data;
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
