'use strict'

import api from '@/api/api';

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS';

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
    },
    actions:{

        async getTablaGeneralClausura({ commit }, payload) {
            commit(GETTING_INFO);
            try {               
               
                let response = await api.getTablaGeneralClausura();
                commit(GET_INFO_SUCCESS, response.data);
              //  console.log(response.data)
                return response.data;
            } catch (error) {
               // console.log(error);
            }
           
        },
        
   
    },
}