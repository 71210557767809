import Vue from 'vue'
import Vuex from 'vuex'

import Admin from './modules/admin'
import Main from './modules/main'
import Categories from './modules/categories'
import Type  from './modules/type'
import Teams from './modules/teams'
import Calendar from './modules/calendar'
import Calendar2 from './modules/calendar2'
import Blog from './modules/blog'
import Blog2 from './modules/blog2'
import Sponsor from './modules/sponsor'
import Users from './modules/users'
import Product from './modules/product'
import Players from './modules/players'
import Tournament from './modules/tournament'
import Color  from './modules/color'
import Size from './modules/size'
import liga from './modules/liga'
import profile from './modules/profile'
import channel from './modules/channel'
import video from './modules/video'
import listChannelsCalendar from './modules/listChannelsCalendar'
import gallery from './modules/gallery'
import images_gallery from './modules/images_gallery'
import banner from './modules/banner'
import banner_images from './modules/banner_images'
import cart from './modules/cart'
import purchase from './modules/purchase'
import videos from './modules/videos'
import gallery2 from './modules/gallery2'
import blog3 from './modules/blog3'
import landing from './modules/landing'
import concurso from './modules/concurso'
import direcciones from './modules/direcciones'
import cabeceras from './modules/cabeceras'


//modulos api externa
import tablaGeneral from './modules/api_externa/tablaGeneral'
import tablaGeneralClausura from './modules/api_externa/tablaGeneralClausura'
import partidos from './modules/api_externa/partidos'
import jornadas from './modules/api_externa/jornadas'
import alineaciones from './modules/api_externa/alineaciones'
import tablaGoleo from './modules/api_externa/tablaGoleo'
import ultimaJornada from './modules/api_externa/ultimaJornada'
import cupon from './modules/cupon'
import sponsor_footer from './modules/sponsor_footer'
import pasarela from './modules/pasarela'
import payment from './modules/payment'
import cart2 from './modules/cart2'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: Admin,
    main:Main,
    categories: Categories,
    type:Type,
    teams: Teams,
    color:Color,
    size: Size,
    calendar: Calendar,
    calendar2: Calendar2,
    blog:Blog,
    blog2:Blog2,
    sponsor:Sponsor,
    users:Users,
    product:Product,
    players: Players,
    tournament:Tournament,
    liga:liga,
    profile:profile,
    channel: channel,
    video: video,
    videos: videos,
    gallery2:gallery2,
    blog3:blog3,
    listChannelsCalendar: listChannelsCalendar,
    gallery: gallery,
    images_gallery: images_gallery,
    banner:banner,
    banner_images:banner_images,
    cart:cart,
    tablaGeneral: tablaGeneral,
    purchase: purchase,
    alineaciones:alineaciones,
    tablaGoleo: tablaGoleo,
    partidos:partidos,
    tablaGeneralClausura:tablaGeneralClausura,
    jornadas:jornadas,
    ultimaJornada: ultimaJornada,
    landing: landing,
    concurso: concurso,
    cupon:cupon,
    direcciones: direcciones,
    sponsor_footer: sponsor_footer,
    pasarela:pasarela,
    payment:payment,
    cart2:cart2,
    cabeceras:cabeceras, 
  }
})
