'use strict'

import api from '@/api/api';
import userService from '../../services/user.service'
let urlpath = require('../../global/url')
const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS', 
      GETTING_INFO2      = 'GET_INFO2',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2', 
      GETTING_INFO3      = 'GET_INFO3',
      GET_INFO_SUCCESS3  = 'GET_INFO_SUCCESS3', 
      GETTING_INFO4      = 'GET_INFO4',
      GET_INFO_SUCCESS4  = 'GET_INFO_SUCCESS4',
      SET_STATUS = 'SET_STATUS',
      GETTING_MSG='GETTING_MSG',
      GET_MSG='GET_MSG',
      URL=urlpath.url();

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        loading2: false,
        data2: [],
        loading3: false,
        data3: [],
        loading4: false,
        data4: [],
        status: '',
        msg:'Ver Todos',
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        loading2(state) {
            return state.loading2;
        },
        data2(state) {
            return state.data2;
        },
        loading3(state) {
            return state.loading3;
        },
        data3(state) {
            return state.data3;
        },
        loading4(state) {
            return state.loading4;
        },
        data4(state) {
            return state.data4;
        },
        msg(state){
            return state.msg
        },
        status(state){
            return state.status
        },
        msg(state){
            return state.msg
        },
        baseURL(){
            return URL
        }
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [GETTING_INFO2](state) {
            state.loading2 = true;
            state.data2 = [];
        },
        [GET_INFO_SUCCESS2](state, change) {
            state.loading2 = false;
            state.data2 = change;
        },
        [GETTING_INFO3](state) {
            state.loading3 = true;
            state.data3 = [];
        },
        [GET_INFO_SUCCESS3](state, change) {
            state.loading3 = false;
            state.data3 = change;
        },
        [GETTING_INFO4](state) {
            state.loading4 = true;
            state.data4 = [];
        },
        [GET_INFO_SUCCESS4](state, change) {
            state.loading4 = false;
            state.data4 = change;
        },
        [SET_STATUS](state, value){
            state.status = value;
        },
        [GET_MSG](state, value){
            state.msg =value
        },
        [GETTING_MSG](state){
            state.msg = 'Cargando...'
        },
    },
    actions:{
        async getAllInfo({ commit }, payload) {
            commit(GETTING_INFO);
            try {               
                let normalizedOpt = payload.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, ""); 
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getAllInfo(normalizedOpt, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
        async getInfoById({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoById(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },

        async getInfoByFila({ commit }, payload) {
            commit(GETTING_INFO);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoByFila(payload, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },

        async getInfoByFila2({ commit }, payload) {
            commit(GETTING_INFO2);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoByFila(payload, headers);
                commit(GET_INFO_SUCCESS2, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },

        async getInfoByFila3({ commit }, payload) {
            commit(GETTING_INFO3);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoByFila(payload, headers);
                commit(GET_INFO_SUCCESS3, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },

        async getInfoByFila4({ commit }, payload) {
            commit(GETTING_INFO4);
            try {              
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let response = await api.getInfoByFila(payload, headers);
                commit(GET_INFO_SUCCESS4, response.data.result);
                //console.log(response.data.result)
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
        },

        async search({ commit }, payload) {
            commit(GETTING_INFO);
            commit(GETTING_MSG);
            let option = payload.option
            let search = payload.search;
            //console.log(payload)
    
            try {               
               // let normalizedOpt = option.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, ""); 
                let token = userService.getToken() 
                let headers =  {'Authorization': token} ;

                let data ={
                    normalizedOpt: option,
                    search: search
                }

                let response = await api.search(data, headers);
                commit(GET_INFO_SUCCESS, response.data.result);
                commit(GET_MSG, option);
                
                return response.data.result;
            } catch (error) {
                //console.log(error);
            }
           
        },
   
    },
}