'use strict'

import api from '@/api/api';

const GETTING_INFO      = 'GET_INFO',
      GET_INFO_SUCCESS  = 'GET_INFO_SUCCESS',
      GETTING_INFO2      = 'GET_INFO2',
      GET_INFO_SUCCESS2  = 'GET_INFO_SUCCESS2';

export default {
    namespaced: true,
    state:{
        loading: false,
        data: [],
        loading2: false,
        data2: [],
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        data(state) {
            return state.data;
        },
        loading2(state) {
            return state.loading2;
        },
        data2(state) {
            return state.data2;
        },
    },
    mutations: {
        [GETTING_INFO](state) {
            state.loading = true;
            state.data = [];
        },
        [GET_INFO_SUCCESS](state, change) {
            state.loading = false;
            state.data = change;
        },
        [GETTING_INFO2](state) {
            state.loading2 = true;
            state.data2 = [];
        },
        [GET_INFO_SUCCESS2](state, change) {
            state.loading2 = false;
            state.data2 = change;
        },
    },
    actions:{
        async getTablaGeneral({ commit }, payload) {
            commit(GETTING_INFO);
            try {               
               
                let response = await api.getTablaGeneral();
                commit(GET_INFO_SUCCESS, response.data);
               // console.log(response.data)
                return response.data;
            } catch (error) {
              //  console.log(error);
            }
           
        },

        async getTablaGeneralDimanica({ commit }, payload) {
            commit(GETTING_INFO2);
            try {               
               
                let response = await api.getTablaGeneralDimanica(payload);
                commit(GET_INFO_SUCCESS2, response.data);
              //  console.log(response.data)
                return response.data;
            } catch (error) {
              //  console.log(error);
            }
           
        },
        
   
    },
}