"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";

const GETTING_INFO = "GET_INFO",
  SET_DATA = "SET_DATA",
  GET_INFO_SUCCESS = "GET_INFO_SUCCESS";

export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [SET_DATA](state, change) {
      state.data = change;
    },
  },
  actions: {
    setData({ commit }, payload) {
      commit(SET_DATA, payload);
    },
    async getCabeceras({ commit }, payload) {
      commit(GETTING_INFO);
      try {
        let normalizedOpt = payload
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f-\s]/g, "");
        let token = userService.getToken();
        let headers = { Authorization: token };

        let response = await api.getAllInfo(normalizedOpt, headers);
        commit(GET_INFO_SUCCESS, response.data.result);
        //console.log(response.data.result)
        return response.data.result;
      } catch (error) {
        //console.log(error);
      }
    },
    async findAll({ commit }, payload) {
      commit(GETTING_INFO);
      try {
        //let normalizedOpt = payload.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f-\s]/g, "");
        let token = userService.getToken();
        let headers = { Authorization: token };

        let response = await api.findAll(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);
        //console.log(response.data.result)
        return response.data.result;
      } catch (error) {
        //console.log(error);
      }
    },
    async getCabecerasById({ commit }, payload) {
      commit(GETTING_INFO);
      try {
        let token = userService.getToken();
        let headers = { Authorization: token };

        let response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);
        //console.log(response.data.result)
        return response.data.result;
      } catch (error) {
        //console.log(error);
      }
    },
  },
};
