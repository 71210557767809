import userService from './user.service';
import dayjs from 'dayjs';

// login service contiene metodos para validaciones de las rutas
export default {
    
 // obtener la identidad
 getIdentity(){
    let identity;
    try{
      let token = userService.getToken();
      identity = userService.decode(token);
       return identity
    }catch(err){
       return identity = null;
    }
  },
  getAth(){
    let auth;
    try{
      let ath = userService.getAth();
      auth = userService.decode(ath);
       return auth
    }catch(err){
       return auth = null;
    }
  },

  // comprobar que la identidad es valida 
  checkIdentity(){
    let identity = this.getIdentity();
    let check;
    if(identity != null){ 
      identity.sub != null || identity.sub != undefined ? check = true:   check = false; //verificar llega diferente de null, pero esta vacio
    }else{
      check = false;
    }
    return check;
  },
  checkAth(){
    let ath= this.getAth();
    let check;
    if(ath != null){ 
      ath.auth != null || ath.auth != undefined ? check = true:   check = false; //verificar llega diferente de null, pero esta vacio
    }else{
      check = false;
    }
    return check;
  },

  
  // ver el tipo de usuario logeado
   isAdmin(){
    let identity = this.getIdentity();
    if(identity.role == 'admin' || identity.role == 'Admin'  || identity.role=='Legrafica' || identity.role=='legrafica'  || identity.role=='editor' || identity.role=='comunicacion' || identity.role=='multimedia' ){  // es administrador
      return true;
    }else{
      return false // es usuario normal
    }
  },
  
  //Eliminar la sesion si el token ya expiro
  tokenexp(){  
    let exp = false;
    let check = this.checkIdentity();
    if(check){
      let identity = this.getIdentity();
      let identity_exp = identity.exp; // fecha de expiración
      let date = dayjs().format(); // fecha actual
      date < identity_exp? exp : exp = true; 
    }else{
      exp = true;
    }
  
    return exp;
  },
  athexp(){  
    let exp = false;
    let check = this.checkAth();
    if(check){
      let ath = this.getAth();
      let ath_exp = ath.exp;
      let date = dayjs().format(); 
      date < ath_exp? exp : exp = true; 
    }else{
      exp = true;
    }
  
    return exp;
  }

};

