import axios from "axios";
let urlpath = require('../global/url')
const jwt = require('jwt-simple');
const clv = '62Dvgrogd2Nw4hWDarsVdfA294CZDjb40tyF1fq1HZ3C2W46SasfUQL786FFDp67MfgsF5';

const ENDPOINT_PATH = urlpath.url();

import dayjs from 'dayjs';

let userToken = "";
let ath ="";
export default {
  login(email, password, gettoken = null) {
    if (gettoken != null) {
      gettoken = "true";
    }
    const user = {
      email,
      password,
      gettoken,
    };

    let headers = { "Content-Type": "application/x-www-form-urlencoded" };
    return axios.post(ENDPOINT_PATH + "login", user, headers);
  },

  // guardar token en LS
  setToken(token) {
    localStorage.setItem("token", token);
  },

   //Obtener el token guardado en el LS
  getToken() {
    let token = localStorage.getItem("token");

    if (token && token != "undefined") {
      userToken = token;
    } else {
      userToken = null;
    }

    return userToken;
  },

  // decodificar token
  decode(token){
    let decoded;
    let user = {};
    if(token != null){
     decoded = jwt.decode(token, clv);
    
     user  = decoded;
    }

    return user;
  },
  createAuth(){
    var payload ={
        auth: 'valido',
        iat:  dayjs().format(), 
        exp: dayjs().add(30, 'days').format()  
    };
    let token = jwt.encode(payload, clv);
    return  token;
 },

 setAth(ath) {
   localStorage.setItem("ath", ath);
 }, 

 getAth() {
   let token = localStorage.getItem("ath");

   if (token && token != "undefined") {
     ath = token;
   } else {
     ath = null;
   }

   return ath;
 },


};

